import React, { useState } from 'react';
import PageTitle from '../layout/PageTitle';
import Footer from '../layout/Footer';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import SettingsListData from "./settings-data.json";

// Icon
import Icon from '@mdi/react';
import { mdiArrowRightThin } from '@mdi/js';

export default function Settings() {
    const [settingsData] = useState(SettingsListData);

    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumbLast="Settings" />

                    <Container>
                        <Row>
                            <Col xl={6}>
                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>Account & Support Settings</Card.Title>
                                        <Row>
                                            {settingsData.supportSettingsLink.map((linkname, i) =>
                                                <Col md={6} key={i}>
                                                    <Card className='border shadow-none'>
                                                        <Card.Body>
                                                            <Link to={linkname.cardLink} className='d-flex justify-content-between align-items-center'>
                                                                <h5 className='fw-normal mb-0 me-3'>{linkname.id}.{linkname.settingLinkName}</h5>
                                                                <Icon path={mdiArrowRightThin} className='icon-size-30 text-dark' />
                                                            </Link>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )}
                                        </Row>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>Masters</Card.Title>
                                        <Row>
                                            {settingsData.mastersLink.map((linkname, i) =>
                                                <Col md={6} key={i}>
                                                    <Card className='border shadow-none'>
                                                        <Card.Body>
                                                            <Link to={linkname.cardLink} className='d-flex justify-content-between align-items-center'>
                                                                <h5 className='fw-normal mb-0 me-3'>{linkname.id}.{linkname.settingLinkName}</h5>
                                                                <Icon path={mdiArrowRightThin} className='icon-size-30 text-dark' />
                                                            </Link>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )}
                                        </Row>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>Privacy & Security Settings</Card.Title>
                                        <Row>
                                            {settingsData.securitySettingsLink.map((linkname, i) =>
                                                <Col md={6} key={i}>
                                                    <Card className='border shadow-none'>
                                                        <Card.Body>
                                                            <Link to={linkname.cardLink} className='d-flex justify-content-between align-items-center'>
                                                                <h5 className='fw-normal mb-0 me-3'>{linkname.id}.{linkname.settingLinkName}</h5>
                                                                <Icon path={mdiArrowRightThin} className='icon-size-30 text-dark' />
                                                            </Link>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )}
                                        </Row>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>Manage Earning Settings</Card.Title>
                                        <Form>
                                            {['checkbox'].map((type) => (
                                                <div key={`default-${type}`} className="mb-3">
                                                    <Form.Check
                                                        type={type}
                                                        id={`manage-earning-${type}`}
                                                        label={`Customise Provider Earning`}
                                                    />
                                                </div>
                                            ))}
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col xl={6}>
                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>Product & Service Helper Masters</Card.Title>
                                        <Row>
                                            {settingsData.helperMastersLink.map((linkname, i) =>
                                                <Col md={6} key={i}>
                                                    <Card className='border shadow-none'>
                                                        <Card.Body>
                                                            <Link to={linkname.cardLink} className='d-flex justify-content-between align-items-center'>
                                                                <h5 className='fw-normal mb-0 me-3'>{linkname.id}.{linkname.settingLinkName}</h5>
                                                                <Icon path={mdiArrowRightThin} className='icon-size-30 text-dark' />
                                                            </Link>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )}
                                        </Row>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>GST Settings</Card.Title>
                                        <Row>
                                            {settingsData.gstSettingsLink.map((linkname, i) =>
                                                <Col md={6} key={i}>
                                                    <Card className='border shadow-none'>
                                                        <Card.Body>
                                                            <Link to={linkname.cardLink} className='d-flex justify-content-between align-items-center'>
                                                                <h5 className='fw-normal mb-0 me-3'>{linkname.id}.{linkname.settingLinkName}</h5>
                                                                <Icon path={mdiArrowRightThin} className='icon-size-30 text-dark' />
                                                            </Link>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )}
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>Modules</Card.Title>
                                        <Form>
                                            <Row sm={3} md={3} lg={4} xl={5} xxl={6} className='row-cols-2'>
                                                {settingsData.modulesCheckbox.map((data, i) =>
                                                    <Col key={i}>
                                                        {['checkbox'].map((type) => (
                                                            <div key={`default-${type}`} className="mb-3">
                                                                <Form.Check
                                                                    type={type}
                                                                    id={`modules-${data.settingCheckId}-${type}`}
                                                                    label={data.settingCheckLabel}
                                                                    defaultChecked={data.settingCheckDefault}
                                                                />
                                                            </div>
                                                        ))}
                                                    </Col>
                                                )}
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={7} xxl={8}>
                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>Invoice</Card.Title>

                                        <Form>
                                            <Row>
                                                <Col md={6} xxl={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Set Default Invoice Type</Form.Label>
                                                        <Form.Select  name='setDefaultInvoiceType' id='setDefaultInvoiceType'>
                                                            <option>Service</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={6} xxl={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Set Default Invoice Payment Mode</Form.Label>
                                                        <Form.Select  name='setDefaultInvoicePaymentMode' id='setDefaultInvoicePaymentMode'>
                                                            <option>Cash</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={6} xxl={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Set Default Provider Of Invoice</Form.Label>
                                                        <Form.Select  name='setDefaultProviderOfInvoice' id='setDefaultProviderOfInvoice'>
                                                            <option>Select Provider</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={6} xxl={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Set Default Customer Of Invoice</Form.Label>
                                                        <Form.Select  name='setDefaultCustomerOfInvoice' id='setDefaultCustomerOfInvoice'>
                                                            <option>NFS Customer, 9825596868</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={6} xxl={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Thank You Message</Form.Label>
                                                        <Form.Select  name='thankYouMessage' id='thankYouMessage'>
                                                            <option>NO</option>
                                                            <option value="1">Yes</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={6} xxl={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Allow Negative Stock Of Invoice</Form.Label>
                                                        <Form.Select  name='allowNegativeStockOfInvoice' id='allowNegativeStockOfInvoice'>
                                                            <option>Yes</option>
                                                            <option value="1">No</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col xl={5} xxl={4}>
                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>Products</Card.Title>
                                        <Form>
                                            <ul className='list-inline mb-0'>
                                                <li className='list-inline-item me-5'>
                                                    {['radio'].map((type) => (
                                                        <div key={`default-${type}`} className="mb-3">
                                                            <Form.Check
                                                                type={type}
                                                                id={`on-floor-check-${type}`}
                                                                name="products-radio-check"
                                                                label={`On Floor`}
                                                                defaultChecked={true}
                                                            />
                                                        </div>
                                                    ))}
                                                </li>
                                                <li className='list-inline-item me-5'>
                                                    {['radio'].map((type) => (
                                                        <div key={`default-${type}`} className="mb-3">
                                                            <Form.Check
                                                                type={type}
                                                                id={`in-store-check-${type}`}
                                                                name="products-radio-check"
                                                                label={`In Store`}
                                                            />
                                                        </div>
                                                    ))}
                                                </li>
                                                <li className='list-inline-item'>
                                                    {['radio'].map((type) => (
                                                        <div key={`default-${type}`} className="mb-3">
                                                            <Form.Check
                                                                type={type}
                                                                id={`both-check-${type}`}
                                                                name="products-radio-check"
                                                                label={`Both`}
                                                            />
                                                        </div>
                                                    ))}
                                                </li>
                                            </ul>

                                            <ul className='list-inline mb-0'>
                                                <li className='list-inline-item me-5'>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`default-${type}`} className="mb-3">
                                                            <Form.Check
                                                                type={type}
                                                                id={`detail-in-product-Unchecked-${type}`}
                                                                label={`Show Weight Detail in Product`}
                                                            />
                                                        </div>
                                                    ))}
                                                </li>
                                                <li className='list-inline-item'>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`default-${type}`} className="mb-3">
                                                            <Form.Check
                                                                type={type}
                                                                id={`consumable-stock-Unchecked-${type}`}
                                                                label={`Consumable Stock`}
                                                            />
                                                        </div>
                                                    ))}
                                                </li>
                                            </ul>
                                        </Form>

                                        <Card.Title>Transfer Order</Card.Title>
                                        <Form>
                                            <ul className='list-inline mb-0'>
                                                <li className='list-inline-item me-5'>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`default-${type}`} className="mb-3">
                                                            <Form.Check
                                                                type={type}
                                                                id={`detail-in-product1-Unchecked-${type}`}
                                                                label={`Show Weight Detail in Product`}
                                                            />
                                                        </div>
                                                    ))}
                                                </li>
                                                <li className='list-inline-item'>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`default-${type}`} className="mb-3">
                                                            <Form.Check
                                                                type={type}
                                                                id={`consumable-stock-Unchecked1-${type}`}
                                                                label={`Consumable Stock`}
                                                            />
                                                        </div>
                                                    ))}
                                                </li>
                                            </ul>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>Accounting</Card.Title>
                                        <Form>
                                            <Row>
                                                <Col md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Ledger Options</Form.Label>
                                                        <Form.Select  name='ledgerOptions' id='ledgerOptions'>
                                                            <option>No</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col sm={6} md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Show Category Option</Form.Label>
                                                        <Form.Select  name='showCategoryOption' id='showCategoryOption'>
                                                            <option>No</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col sm={6} md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Show Payment Type Option</Form.Label>
                                                        <Form.Select  name='showPaymentTypeOption' id='showPaymentTypeOption'>
                                                            <option>No</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
