import React, { useState } from 'react';
import PageTitle from '../layout/PageTitle';
import Footer from '../layout/Footer';
import { Container, Row, Col, Card, Form, Button, InputGroup } from 'react-bootstrap';
import TempData from '../../Utils/temp_data.json';

// icon
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import ListViewTable from '../../Utils/listview';
import AddCustomer from '../../Utils/AddCustomer';

export default function Sales() {
    const [TempDataOption] = useState(TempData);

    // input value
    const [data, setData] = useState({
        invoice_number: "53"
    });


    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    // initialProductRow
    const initialProductRow = {
        checkbox: false,
        serialNumber: '',
        description: '',
        unitPrice: '',
        qty: 0,
        disc: 0,
        dropdown: '',
        taxAmount: 0,
        total: 0
    }

    const [initialProductRows, setInitialProductRows] = useState(Array(5).fill({ ...initialProductRow }));

    const ProductColumnsConfig = [
        { label: 'S/N', field: 'serialNumber', type: 'autoIncrement' },
        { label: 'Description', field: 'description', type: 'text' },
        { label: 'Unit Price', field: 'unitPrice', type: 'number' },
        { label: 'Qty', field: 'qty', type: 'number' },
        { label: 'Disc', field: 'disc', type: 'number' },
        {
            label: 'Tax(%)',
            field: 'dropdown',
            type: 'select',
            defualt: 'None',
            options: [
                { value: 'gst', label: 'GST' },
                { value: 'cgst', label: 'CGST' },
                { value: 'igst', label: 'IGST' }
            ]
        },
        { label: 'Tax Amount', field: 'taxAmount', type: 'number' },
        { label: 'Total', field: 'total', type: 'number' }
    ];

    const handleAddRow = () => {
        const newRow = { ...initialProductRow }
        setInitialProductRows([...initialProductRows, newRow]);
    };

    // const [productsRows, setProductsRows] = useState([]);

    const handleProductChange = (index, field, value) => {
        const updatedRows = initialProductRows.map((row, i) => {
            if (i === index) {
                const updatedRow = { ...row, [field]: value };

                return updatedRow;
            } else {
                return row;
            }
        });
        setInitialProductRows(updatedRows);
    };

    // Add Customer modal
    const [customerModalShow, setCustomerModalShow] = useState(false);
    const handleShowCustomer = () => setCustomerModalShow(true);
    const handleCloseCustomer = () => setCustomerModalShow(false);

    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumbLast="Sales" breadcrumb1="Sales" />

                    <Container>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Quick Invoice</Card.Title>
                                        <Form>
                                            <Row>
                                                <Col sm={3} md={4} xl={1}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Number</Form.Label>
                                                        <Form.Control type="number" name='invoice_number' value={data.invoice_number} onChange={onChange} id='invoice_number' />
                                                    </div>
                                                </Col>
                                                <Col sm={9} md={8} xl={4}>
                                                    <div className="mb-3 d-flex">
                                                        <div className='custom-form-input w-100'>
                                                            <Form.Label>Customer Name</Form.Label>
                                                            <Form.Control type="text" placeholder="Enter customer name" name='customerName' id='customerName' />
                                                        </div>
                                                        <Button variant="light custom-form-input-btn ms-2 mt-2" onClick={handleShowCustomer}>
                                                            <Icon path={mdiPlus} size={1} />
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col xl={7}>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Mobile Number</Form.Label>
                                                                <Form.Control type="number" placeholder="(+001) 1234 5678 90" name='mobileNumber' id='mobileNumber' />
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Tax Name</Form.Label>
                                                                <Form.Control type="text" placeholder="Enter tax name" name='taxName' id='taxName' />
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Sale Date</Form.Label>
                                                                <Form.Control type="date" placeholder="24 November, 2022" name='saleDate' id='saleDate' />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Form>
                                        <ul className='list-inline text-end text-muted fs-14 mb-3'>
                                            <li className='list-inline-item'>
                                                GST No : <span className='text-muted-dark'>07AAGFF2194N1Z1</span>
                                            </li>
                                            <li className='list-inline-item ms-5'>
                                                Advance Payment : <span className='text-muted-dark'>0</span>
                                            </li>
                                            <li className='list-inline-item ms-5'>
                                                Wallet Balance : <span className='text-muted-dark'>0</span>
                                            </li>
                                        </ul>

                                        <ListViewTable
                                            initialRows={initialProductRows}
                                            columnsConfig={ProductColumnsConfig}
                                            onAddRow={handleAddRow}
                                            handleOnChange={handleProductChange}
                                        />

                                        <Form className='mt-3'>
                                            <Row>
                                                <Col sm={6} xl={3}>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Product Vendor</Form.Label>
                                                        <Form.Control type="text" name='productVendor' id='productVendor' />
                                                    </div>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Product Cost Price</Form.Label>
                                                        <Form.Control type="text" name='productCostPrice' id='productCostPrice' />
                                                    </div>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Product Quantity</Form.Label>
                                                        <Form.Control type="text" name='productQuantity' id='productQuantity' />
                                                    </div>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Amount Received</Form.Label>
                                                        <Form.Control type="text" name='amountReceived' id='amountReceived' />
                                                    </div>
                                                </Col>
                                                <Col sm={6} xl={3}>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Payment Type</Form.Label>
                                                        <Form.Select name='paymentType' id='paymentType' >
                                                            {TempDataOption.TempPaymentOption.map((data, i) =>
                                                                <option key={i}>{data.paymentOption}</option>
                                                            )}
                                                        </Form.Select>
                                                    </div>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Total Unit Price</Form.Label>
                                                        <Form.Control type="text" name='totalUnitPrice' id='totalUnitPrice' />
                                                    </div>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Total Discount</Form.Label>
                                                        <Form.Control type="text" name='totalDiscount' id='totalDiscount' />
                                                    </div>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>CGST</Form.Label>
                                                        <Form.Control type="text" name='cGst' id='cGst' />
                                                    </div>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Amount Due</Form.Label>
                                                        <Form.Control type="text" name='amountDue' id='amountDue' />
                                                    </div>
                                                </Col>
                                                <Col sm={6} xl={3}>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Taxable Amount</Form.Label>
                                                        <Form.Control type="text" name='taxableAmount' id='taxableAmount' />
                                                    </div>
                                                    <InputGroup className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Control type='text' name='overallDiscount' id='overallDiscount' />
                                                        <Form.Label>Discount</Form.Label>
                                                        <Form.Select size='sm'>
                                                            {TempDataOption.TempOverallDiscount.map((data, i) =>
                                                                <option key={i}>{data.overallDiscountOption}</option>
                                                            )}
                                                        </Form.Select>
                                                    </InputGroup>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Advance Payment</Form.Label>
                                                        <Form.Control type="text" name='advancePayment' id='advancePayment' />
                                                    </div>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>SGST</Form.Label>
                                                        <Form.Control type="text" name='sGst' id='sGst' />
                                                    </div>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Amount Repaid</Form.Label>
                                                        <Form.Control type="text" name='amountRepaid' id='amountRepaid' />
                                                    </div>
                                                </Col>
                                                <Col sm={6} xl={3}>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Sub Total</Form.Label>
                                                        <Form.Control type="text" name='subTotal' id='subTotal' />
                                                    </div>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Total Item Discount</Form.Label>
                                                        <Form.Control type="text" name='totalItemDiscount' id='totalItemDiscount' />
                                                    </div>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Advance Payment Amount</Form.Label>
                                                        <Form.Control type="text" name='advancePaymentAmount' id='advancePaymentAmount' />
                                                    </div>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Total Tax Amount</Form.Label>
                                                        <Form.Control type="text" name='totalTaxAmount' id='totalTaxAmount' />
                                                    </div>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Grand Total</Form.Label>
                                                        <Form.Control type="text" name='grandTotal' id='grandTotal' />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='align-items-end'>
                                                <Col xl={5} xxl={6}>
                                                    <div className="mb-3 mb-xl-0 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Narration</Form.Label>
                                                        <Form.Control type="text" name='narration' id='narration' placeholder='Enter Narration' />
                                                    </div>
                                                </Col>
                                                <Col xl={7} xxl={6} className='text-end'>
                                                    <div className="btn-list">
                                                        <Button variant="primary">Generate and Print</Button>{' '}
                                                        <Button variant="success">Generate and New</Button>{' '}
                                                        <Button variant="info">Generate Invoice</Button>{' '}
                                                        <Button variant="warning">Print Option</Button>{' '}
                                                        <Button variant="secondary">Cancel</Button>{' '}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>

            {/* Add Customer modal */}
            <AddCustomer addCustomerShow={customerModalShow} addCustomerClose={handleCloseCustomer} />
        </>
    )
}
