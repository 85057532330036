import React, { useState } from 'react';
import { Col, Row, Form, Button, InputGroup } from 'react-bootstrap';
import ListViewTable from '../../Utils/listview';
import TempData from '../../Utils/temp_data.json';

export default function TransferReturn() {
    const [TempDataOption] = useState(TempData);

    // input value
    const [data, setData] = useState({
        ti_number: "TI#00014", branch: "BD Enterprise", freightCharges: "0", otherCharges: "0", cgst: "0", amountPaid: "0", taxableAmount: "0",
        overallDiscount: "0", advancePayment: "0", amountDue: "0", advancePaymentAmount: "0"
    })
    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    // initialProductRow
    const initialProductRow = {
        checkbox: false,
        serialNumber: '',
        description: '',
        unitPrice: '',
        qty: 0,
        disc: 0,
        dropdown: '',
        taxAmount: 0,
        total: 0
    }

    const [initialProductRows, setInitialProductRows] = useState(Array(10).fill({ ...initialProductRow }));

    const ProductColumnsConfig = [
        { label: 'S/N', field: 'serialNumber', type: 'autoIncrement' },
        { label: 'Description', field: 'description', type: 'text' },
        { label: 'Unit Price', field: 'unitPrice', type: 'number' },
        { label: 'Qty', field: 'qty', type: 'number' },
        { label: 'Disc', field: 'disc', type: 'number' },
        {
            label: 'Tax(%)',
            field: 'dropdown',
            type: 'select',
            defualt: 'None',
            options: [
                { value: 'gst', label: 'GST' },
                { value: 'cgst', label: 'CGST' },
                { value: 'igst', label: 'IGST' }
            ]
        },
        { label: 'Tax Amount', field: 'taxAmount', type: 'number' },
        { label: 'Total', field: 'total', type: 'number' },
        { label: 'Check out to floor', field: 'checkbox', type: 'checkbox' }
    ];

    const handleAddRow = () => {
        const newRow = { ...initialProductRow }
        setInitialProductRows([...initialProductRows, newRow]);
    };

    // const [productsRows, setProductsRows] = useState([]);

    const handleProductChange = (index, field, value) => {
        const updatedRows = initialProductRows.map((row, i) => {
            if (i === index) {
                const updatedRow = { ...row, [field]: value };

                return updatedRow;
            } else {
                return row;
            }
        });
        setInitialProductRows(updatedRows);
    }

    return (
        <>
            <Row>
                <Col sm={6} md={4} lg={3} xl={2}>
                    <div className="mb-3">
                        <Form.Control type="text" name='ti_number' id='ti_number' value={data.ti_number} onChange={onChange} placeholder="Your Name" size="sm" />
                    </div>
                </Col>
                <Col sm={6} md={4} lg={3} xl={2}>
                    <div className="mb-3">
                        <Form.Control type="text" name='branch' id='branch' value={data.branch} onChange={onChange} placeholder="Your Name" size="sm" />
                    </div>
                </Col>
                <Col sm={6} md={4} lg={3} xl={2}>
                    <div className="mb-3">
                        <Form.Control type="text" placeholder="To Branch" size="sm" />
                    </div>
                </Col>
                <Col sm={6} md={4} lg={3} xl={2}>
                    <Form.Select size="sm">
                        <option>Umesh bhai</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                    </Form.Select>
                </Col>
                <Col sm={6} md={4} lg={3} xl={2}>
                    <div className="mb-3">
                        <Form.Control type="text" placeholder="Ref. Number" size="sm" />
                    </div>
                </Col>
                <Col sm={6} md={4} lg={3} xl={2}>
                    <div className="mb-3">
                        <Form.Control type="date" placeholder="dd/mm/yyyy" size="sm" />
                    </div>
                </Col>
            </Row>

            <ul className='list-inline text-end mb-0'>
                <li className='list-inline-item me-5'>
                    {['checkbox'].map((type) => (
                        <div key={`default-${type}`} className="mb-3">
                            <Form.Check
                                type={type}
                                id={`direct-transfer-${type}`}
                                label={`Direct transfer`}
                                defaultChecked={true}
                            />
                        </div>
                    ))}
                </li>
                <li className='list-inline-item'>
                    {['checkbox'].map((type) => (
                        <div key={`default-${type}`} className="mb-3">
                            <Form.Check
                                type={type}
                                id={`check-out-${type}`}
                                label={`Check out to floor`}
                            />
                        </div>
                    ))}
                </li>
            </ul>

            <ListViewTable
                initialRows={initialProductRows}
                columnsConfig={ProductColumnsConfig}
                onAddRow={handleAddRow}
                handleOnChange={handleProductChange}
            />

            <Form>
                <Row>
                    <Col lg={4}>
                        <Row>
                            <Col xs={6} md={3} lg={6}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Cost Price</Form.Label>
                                    <Form.Control type="text" name='costPrice' id='costPrice' />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Qty (From Branch)</Form.Label>
                                    <Form.Control type="text" name='qtyFromBranch' id='qtyFromBranch' />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Qty (To Branch)</Form.Label>
                                    <Form.Control type="text" name='qtyToBranch' id='qtyToBranch' />
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={6}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>LR. No.</Form.Label>
                                    <Form.Control type="text" name='lrNo' id='lrNo' />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Courier Company Name</Form.Label>
                                    <Form.Control type="text" name='courierCompanyName' id='courierCompanyName' />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Packet Number</Form.Label>
                                    <Form.Control type="text" name='packetNumber' id='packetNumber' />
                                </div>
                            </Col>
                            <Col md={6} lg={12}>
                                <Row className='align-items-end'>
                                    <Col xs={8}>
                                        <InputGroup className="mb-3 custom-form-input custom-form-input-sm">
                                            <Form.Control type="text" name='freightCharges' id='freightCharges' value={data.freightCharges} onChange={onChange} />
                                            <Form.Label>Freight Charges</Form.Label>
                                            <Form.Select size='sm'>
                                                {TempDataOption.TempCharges.map((data, i) =>
                                                    <option key={i}>{data.chargesOption}</option>
                                                )}
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col xs={4}>
                                        <div className="mb-3 custom-form-input custom-form-input-sm">
                                            <Form.Label>Tax Type</Form.Label>
                                            <Form.Select disabled>
                                                {TempDataOption.TempTaxType.map((data, i) =>
                                                    <option key={i}>{data.taxTypeOption}</option>
                                                )}
                                            </Form.Select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='align-items-end'>
                                    <Col xs={8}>
                                        <InputGroup className="mb-3 custom-form-input custom-form-input-sm">
                                            <Form.Control type="text" name='otherCharges' id='otherCharges' value={data.otherCharges} onChange={onChange} />
                                            <Form.Label>Other Charges</Form.Label>
                                            <Form.Select size='sm'>
                                                {TempDataOption.TempCharges.map((data, i) =>
                                                    <option key={i}>{data.chargesOption}</option>
                                                )}
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col xs={4}>
                                        <div className="mb-3 custom-form-input custom-form-input-sm">
                                            <Form.Label>Tax Type</Form.Label>
                                            <Form.Select disabled>
                                                {TempDataOption.TempTaxType.map((data, i) =>
                                                    <option key={i}>{data.taxTypeOption}</option>
                                                )}
                                            </Form.Select>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={8}>
                        <Row>
                            <Col sm={4}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Payment Type</Form.Label>
                                    <Form.Select name='paymentType' id='paymentType' >
                                        {TempDataOption.TempPaymentOption.map((data, i) =>
                                            <option key={i}>{data.paymentOption}</option>
                                        )}
                                    </Form.Select>
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Round Off</Form.Label>
                                    <Form.Control type="text" name='roundOff' id='roundOff' />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Amount Repaid</Form.Label>
                                    <Form.Control type="text" name='amountRepaid' id='amountRepaid' />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>CGST</Form.Label>
                                    <Form.Control type="text" name='cgst' id='cgst' value={data.cgst} onChange={onChange} />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Amount Paid</Form.Label>
                                    <Form.Control type="text" name='amountPaid' id='amountPaid' value={data.amountPaid} onChange={onChange} />
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Taxable Amount</Form.Label>
                                    <Form.Control type="text" name='taxableAmount' id='taxableAmount' value={data.taxableAmount} onChange={onChange} />
                                </div>
                                <InputGroup className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Control name='overallDiscount' id='overallDiscount' value={data.overallDiscount} onChange={onChange} />
                                    <Form.Label>Overall Discount</Form.Label>
                                    <Form.Select size='sm'>
                                        {TempDataOption.TempOverallDiscount.map((data, i) =>
                                            <option key={i}>{data.overallDiscountOption}</option>
                                        )}
                                    </Form.Select>
                                </InputGroup>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Advance Payment</Form.Label>
                                    <Form.Control type="text" name='advancePayment' id='advancePayment' value={data.advancePayment} onChange={onChange} />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>SGST</Form.Label>
                                    <Form.Control type="text" name='sgst' id='sgst' />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Amount Due</Form.Label>
                                    <Form.Control type="text" name='amountDue' id='amountDue' value={data.amountDue} onChange={onChange} />
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Invoice Total</Form.Label>
                                    <Form.Control type="text" name='invoiceTotal' id='invoiceTotal' />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Total Discount</Form.Label>
                                    <Form.Control type="text" name='totalDiscount' id='totalDiscount' />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Advance Payment Amount</Form.Label>
                                    <Form.Control type="text" name='advancePaymentAmount' id='advancePaymentAmount' value={data.advancePaymentAmount} onChange={onChange} />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Total Tax</Form.Label>
                                    <Form.Control type="text" name='totalTax' id='totalTax' />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Grand Total</Form.Label>
                                    <Form.Control type="text" name='grandTotal' id='grandTotal' />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='align-items-end'>
                    <Col xl={5} xxl={6}>
                        <div className="mb-3 mb-xl-0 custom-form-input custom-form-input-sm">
                            <Form.Label>Narration</Form.Label>
                            <Form.Control type="text" name='narration' id='narration' />
                        </div>
                    </Col>
                    <Col xl={7} xxl={6} className='text-end'>
                        <div className="btn-list">
                            <Button variant="primary">Transfer(a)</Button>{' '}
                            <Button variant="success">Transfer and Print</Button>{' '}
                            <Button variant="secondary">Cancel</Button>{' '}
                        </div>
                    </Col>
                </Row>
            </Form>
        </>
    )
}
