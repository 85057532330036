import React, { useState, useEffect } from 'react';
import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Form, Button, Table, Nav } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

import AccountReceivableData from "./account-receivable-data.json";

// icon
import Icon from '@mdi/react';
import { mdiMagnify, mdiPrinterOutline } from '@mdi/js';

export default function AccountReceivable() {
    // left navlink active
    useEffect(() => {
        var a = document.getElementsByClassName("left-link-item");
        for (var i = 0; i < a.length; i++) {
            a[i].onclick = function () {
                var b = document.querySelector(".left-link-item a.active");
                if (b) b.classList.remove("active");
                this.children[0].classList.add('active');
            };
        }
    }, []);

    // datatable
    const [AccountListData] = useState(AccountReceivableData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableAccountReceivable')) {
            $('#mainDatatableAccountReceivable').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, []);
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Accounting" breadcrumb2="Reports" breadcrumbLast="Account Receivable" />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className='d-sm-flex justify-content-between align-items-center'>
                                            <Form className='custom-form-width mb-3 mb-sm-0'>
                                                <div className='custom-search-bar'>
                                                    <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                    <Form.Control className='' type="text" placeholder="Search account..." name='accountSearch' id='accountSearch' />
                                                </div>
                                            </Form>
                                            <Button variant="light"><Icon path={mdiPrinterOutline} className="btn-icon me-1" /> Send Reminder SMS</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={4} xxl={3}>
                                <Card className='procust-left-list leftbar-list'>
                                    <SimpleBarReact>
                                        <Card.Body>
                                            <div className='left-list-bar'>
                                                {AccountListData.AccountLeftListData.map((data, i) =>
                                                    <Nav.Item className='left-link-item' key={i}>
                                                        <Nav.Link className={i === 0 ? "active" : ""}>
                                                            <h5 className='product-tab-title'>{data.accountName}</h5>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                )}
                                            </div>
                                        </Card.Body>
                                    </SimpleBarReact>
                                </Card>
                            </Col>
                            <Col lg={8} xxl={9}>
                                <Card className='right-content-details'>
                                    <Card.Body>
                                        <Card.Title className=''>Mirka Patel</Card.Title>
                                        <Row className='justify-content-center'>
                                            <Col sm={6} xl={4} xxl={3}>
                                                <Card className='border shadow-none text-center'>
                                                    <Card.Body>
                                                        <h5 className='mb-0 fs-17 fw-medium'>Total Row : 1</h5>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col sm={6} xl={4} xxl={3}>
                                                <Card className='border shadow-none text-center'>
                                                    <Card.Body>
                                                        <h5 className='mb-0 fs-17 fw-medium'>Total Invoice Amt : 1620</h5>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>

                                        <Card className='border shadow-none'>
                                            <Card.Body>
                                                <Form className='d-sm-flex align-items-center justify-content-end'>
                                                    <Form.Select  size="sm" className='w-auto mb-3'>
                                                        <option>All Time</option>
                                                        <option value="1">All Time</option>
                                                        <option value="2">Two</option>
                                                    </Form.Select>
                                                </Form>
                                                <Table responsive bordered className='table-nowrap' id='mainDatatableAccountReceivable'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Sr.No</th>
                                                            <th>Date</th>
                                                            <th>Reff.No</th>
                                                            <th>Category</th>
                                                            <th>Total</th>
                                                            <th>Due Days</th>
                                                            <th>Payment Mode</th>
                                                            <th>Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>02-06-2022</td>
                                                            <td>21</td>
                                                            <td>Invoice</td>
                                                            <td>1620</td>
                                                            <td>187</td>
                                                            <td>Due</td>
                                                            <td>Invoice</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
