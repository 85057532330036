import React, { createContext, useState, useContext } from 'react';
import { getFromAPI } from '../../Utils/utils';


// Create Context
export const CustomerContext = createContext();

// Custom hook to use the CustomerContext
export const useCustomer = () => useContext(CustomerContext);

// Create a Context Provider component
export const CustomerProvider = ({ children }) => {
    const [customerData, setCustomerData] = useState([]);

    const fetchCustomerData = async () => {
        try {
            const response = await getFromAPI('FtsClients/');
            setCustomerData(response);
        } catch (error) {
            console.error('Error fetching customer data:', error);
        }
    };

    return (
        <CustomerContext.Provider value={{ customerData, fetchCustomerData }}>
            {children}
        </CustomerContext.Provider>
    );
};
