import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import locationData from './location_data.json';
import TempData from './temp_data.json';

export default function AddVendor({ addVendorShow, addVendorClose }) {
    const [TempDataOption] = useState(TempData);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');

    useEffect(() => {
        setCountries(locationData.countries);
    }, []);

    const handleCountryChange = (e) => {
        const selectedCountryName = e.target.value;
        setSelectedCountry(selectedCountryName);
        const country = locationData.countries.find(
            (country) => country.name === selectedCountryName
        );
        if (country) {
            setStates(country.states);
            setCities([]); // Reset cities when changing the country
            setSelectedState('');
        } else {
            setStates([]);
            setCities([]);
        }
    };

    const handleStateChange = (e) => {
        const selectedStateName = e.target.value;
        setSelectedState(selectedStateName);
        const state = states.find((state) => state.name === selectedStateName);
        if (state) {
            setCities(state.cities);
        } else {
            setCities([]);
        }
    };

    return (
        <>
            <Modal centered show={addVendorShow} onHide={addVendorClose} size='lg'>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h4 className='fw-normal mb-4'>Add New Vendor</h4>
                    <Form>
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Vendor Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter name..." name='vendorName' id='vendorName' />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control type="number" placeholder="Enter number..." name='vendorNumber' id='vendorNumber' />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email..." name='vendorEmail' id='vendorEmail' />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>GST</Form.Label>
                                    <Form.Control type="text" placeholder="Enter gst number..." name='vendorGstNumber' id='vendorGstNumber' />
                                </div>
                            </Col>
                            <Col md={6} lg={4}>
                                <div className="mb-3 custom-form-input" controlId="formCountry">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select value={selectedCountry} onChange={handleCountryChange}>
                                        <option value="">Select Country</option>
                                        {countries.map((country, index) => (
                                            <option key={index} value={country.name}>
                                                {country.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} lg={4}>
                                <div className="mb-3 custom-form-input" controlId="formState">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select value={selectedState} onChange={handleStateChange} disabled={!states.length}>
                                        <option value="">Select State</option>
                                        {states.map((state, index) => (
                                            <option key={index} value={state.name}>
                                                {state.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} lg={4}>
                                <div className="mb-3 custom-form-input" controlId="formCity">
                                    <Form.Label>City</Form.Label>
                                    <Form.Select disabled={!cities.length}>
                                        <option value="">Select City</option>
                                        {cities.map((city, index) => (
                                            <option key={index} value={city}>
                                                {city}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} lg={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Select>
                                    {TempDataOption.TempTransactionTaxType.map((data, i) =>
                                        <option key={i}>{data.transactionTaxTypeOption}</option>
                                    )}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} lg={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>PAN</Form.Label>
                                    <Form.Control type="text" className='text-uppercase' placeholder="" name='vendorPan' id='vendorPan' />
                                </div>
                            </Col>
                            <Col md={6} lg={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Opening Balance</Form.Label>
                                    <Form.Control type="number" placeholder="" name='vendorOpeningBalance' id='vendorOpeningBalance' />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Select>
                                    {TempDataOption.TempTaxType.map((data, i) =>
                                        <option key={i}>{data.taxTypeOption}</option>
                                    )}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax Type</Form.Label>
                                    <Form.Select>
                                    {TempDataOption.TempGstTaxType.map((data, i) =>
                                        <option key={i}>{data.gstTaxTypeOption}</option>
                                    )}
                                    </Form.Select>
                                </div>
                            </Col>
                        </Row>
                        <div className="text-end">
                            <Button variant="primary btn-lg me-2" onClick={addVendorClose}>Add</Button>
                            <Button variant="secondary btn-lg" onClick={addVendorClose}>Close</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
