import React, { useState, useEffect } from 'react';
import { Card, Modal, Form, Button, Row, Col, Table } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import { CustomerProvider, useCustomer } from '../vendor/getVendor';
import { toast } from "react-toastify";
import { postToAPI, getFromAPI, deleteFromAPI, showAlert, handleDateFormat, formatISODateWithTimezone } from '../../Utils/utils';

import { ProductProvider, useProduct } from '../product/getProductData';

// icon
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import ReceiveDelivery from './ReceiveDelivery';

// Currently using temporary product data as products module is in progress
// TODO: Need to fetch products based on selected vendor
import ProductTableData from "../product/product-data.json";
import ViewInvoice from '../../Utils/ViewInvoice';

function PurchaseOrder() {

  const { vendor, fetchCustomerData } = useCustomer();
  const { productData, fetchproductData1 } = useProduct();

  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString('en-CA');

  const [POData, setPOData] = useState();

  const initialInputData = {
    po_receipt_id: 0,
    vendor_id: "",
    po_date: handleDateFormat(new Date()),
    subtotal: 0,
    total: 0,
    po_total: 0,
    po_status: 0,
    po_sms: 0,
    isprinted: 0,
    po_return_id: 0,
    po_invoice_id: 0,
    po_delivery_date: null,
    po_delivery_received_user_id: 0,
    short_link: null,
    long_link: null,
    description: null,
    reference_number: "",
    isdeleted: 0,
    created_branch_id: "",
    created_date_time: new Date(),
    last_modified_date_time: new Date()
  }

  const [inputData, setInputData] = useState(initialInputData);

  // TODO: Get branch product data and warehouse product data
  const [ProductListData, setProductListData] = useState({
    product_id: "",
    product_name: "",
    consumable_stock_qty_ordered: "",
    retail_stock_qty_ordered: ""
  });

  const [ProductsData, setProductsData] = useState([{
    product_id: "",
    product_name: "",
    consumable_stock_in_store: "",
    consumable_stock_qty_ordered: "",
    retail_stock_in_store: "",
    retail_stock_qty_ordered: ""
  }]);

  const handleVendorChange = (e) => {
    const vID = parseInt(e.target.value);
    setInputData(prevState => ({ ...prevState, vendor_id: vID }))

    // TODO: fetch or filter products based on vendor change

  }

  const SetProductInTable = () => {

    // TODO: Use proper validation from react bootstrap
    if (!ProductListData.product_id) {
      toast.error('Please Select Product', 'error')
      return;
    }
    if (!ProductListData.retail_stock_qty_ordered) {
      toast.error('Please Enter retail quantity', 'error')
      return;
    }
    // Find the product in the stock data
    // TODO: use product object fetched or filtered by vendor id
    const selectedProduct = ProductTableData.tableDataList.find(
      product => product.id === parseInt(ProductListData.product_id)
    );

    // Create the new product data object including in-store quantities
    const newProductData = selectedProduct
      ? {
        ...ProductListData,
        consumable_stock_in_store: selectedProduct.consumableQuantityData,
        retail_stock_in_store: selectedProduct.retailQuantityData
      }
      : { ...ProductListData }; // If not found, use ProductData without in-store quantities
    setProductsData([...ProductsData, newProductData]);

    // Reset product data after adding it in table
    setProductListData({
      product_id: "",
      product_name: "",
      consumable_stock_qty_ordered: "",
      retail_stock_qty_ordered: ""
    });
  }

  const AddPO = async () => {
    if (!inputData.vendor_id) {
      toast.error('Please Select vendor', 'error')
      return;
    }
    if (!inputData.po_date) {
      toast.error('Please Select purchase order date', 'error')
      return;
    }
    if (ProductsData.length <= 1) {
      toast.error('Please Enter products into list by clicking Add button', 'error')
      return;
    }

    // TODO: fetch and use user id and branch id 
    // Set dummy data for now
    setInputData(prevState => ({ ...prevState, created_branch_id: 1, po_status: 0, created_user_id: 1, last_modified_user_id: 1 }));

    try {
      // Temporarely Fetch the PO number from the API 
      const response = await fetch('https://myerp-backend.foogletech.com/api/create_prefix', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          prefix_type: 'po_prefix_type',
          prefix: 'po_prefix',
          number_format: 'po_number_format',
          next_number: 'next_po_number',
          current_time: new Date().toISOString()
        }) // Send parameters in the body as JSON
      });
      const data = await response.json();

      // Extract the PO number from the response data
      const poNumber = data.data.genereted_prifix_number;
      console.log('Generated PO Number:', poNumber);

      // Update the state with the generated PO number
      setInputData(prevState => ({ ...prevState, reference_number: poNumber }));

      let ISOPODate = formatISODateWithTimezone(inputData.po_date);

      // Create temp const to set data in api instead of inputdata because setInputData is async method so it will take time to update data in inputdata
      let FinalInputData = { ...inputData, reference_number: poNumber, created_branch_id: 1, po_status: 0, created_user_id: 1, last_modified_user_id: 1, po_date: ISOPODate };

      // Insert data into table
      const postResponse = await postToAPI("FtsPurchaseOrder/", FinalInputData);
      if (postResponse.status) {
        FetchPOData();
        toast.success('Purchase order Added Successfully');
        setInputData(initialInputData);
        setProductsData([{
          product_id: "",
          product_name: "",
          consumable_stock_in_store: "",
          consumable_stock_qty_ordered: "",
          retail_stock_in_store: "",
          retail_stock_qty_ordered: ""
        }]);
        UpdateDataTable();
        handleClose();
      } else {
        toast.error('Failed to add Purchase order');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An unexpected error occurred');
    }

  }

  const FetchPOData = () => {
    getFromAPI("FtsPurchaseOrder/").then(response => { setPOData(response) })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  // TODO: implimentation of this function gloabally or in utility
  const getStatusFromStatusID = (id) => {
    let status, color;
    switch (id) {
      case 0:
        status = "Pending";
        color = "danger";
        break;

      case 1:
        status = "";
        color = "";
        break;

      case 2:
        status = "";
        color = "";
        break;
    }

    return [status, color];
  }

  // Default Modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setInputData(initialInputData);
    setProductsData([{
      product_id: "",
      product_name: "",
      consumable_stock_in_store: "",
      consumable_stock_qty_ordered: "",
      retail_stock_in_store: "",
      retail_stock_qty_ordered: ""
    }]);
  }
  const handleShow = () => setShow(true);

  const getVendorNameById = (VendorID) => {
    let ven = vendor.find(v => v.id === VendorID);
    return ven ? ven.company_name : 'Unknown Vendor';
  };

  const HandlePODelete = (POID) => {
    try {
      showAlert('Do you really want to delete this record?', 'confirm', async (result) => {
        if (result) {
          try {
            const response = await deleteFromAPI(`FtsPurchaseOrder/${POID}/`);
            if (response.status) {
              toast.success('Purchase Order deleted successfully', 'success');
              FetchPOData();
              UpdateDataTable();
            }
          } catch (error) {
            console.error('Error deleting purchase order:', error);
            toast.error('purchase order Not Deleted', 'error');
          }
        }
      }, { confirmButtonText: 'Delete', cancelButtonText: 'Cancel', title: 'Are you sure?', confirmButtonColor: '#dc3545' });
    } catch (error) {
      console.error('Error deleting purchase order:', error);
      toast.error('Error deleting purchase order', 'error');
    }
  }

  const UpdateDataTable = () => {
    const PODT = $('#mainDatatablePurchaseOrder').DataTable();
    getFromAPI("FtsPurchaseOrder/")
      .then(response => {
        setPOData(response);
        let DTData = response.map(row => [
          row.reference_number,
          getVendorNameById(row.vendor_id),
          handleDateFormat(row.po_date),
          row.po_total,
          row.po_status,
          row.id
        ]);
        PODT.clear().rows.add(DTData).draw();
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  // datatable
  DataTable.Buttons.jszip(JSZip);
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  useEffect(() => {
    if (POData && vendor.length > 0 && !$.fn.dataTable.isDataTable('#mainDatatablePurchaseOrder')) {
      $('#mainDatatablePurchaseOrder').DataTable({
        dom: "flrtip",
        data: POData.map(row => [
          row.reference_number,
          getVendorNameById(row.vendor_id),
          handleDateFormat(row.po_date),
          row.po_total,
          row.po_status,
          row.id
        ]),
        columns: [
          { title: "PO#" },
          { title: "Vendor Name" },
          { title: "Date" },
          { title: "Total" },
          {
            title: "Status",
            render: function (data, type, row) {
              const [status, color] = getStatusFromStatusID(row[4]);
              return (`<span class="badge bg-soft-${color}" >${status}</span>`)
            }
          },
          {
            title: "Action",
            createdCell: function (td) {
              // Add a class to the 'Action' column cells
              td.classList.add('py-1');
            },
            render: function (data, type, row) {
              return `
                <button class="btn btn-light btn-sm po-vieworder" data-po_id="${row[5]}">View order</button>
                <button class="btn btn-light btn-sm" onClick="">Receive Delivery</button>
                <button class="btn btn-light btn-sm po-delete" data-po_id="${row[5]}">Delete</button>
              `;
            }
          }
        ],
        autoWidth: false,
        language: {
          search: '',
          searchPlaceholder: "Search...",
          paginate: {
            previous: '«',
            next: '»'
          },
        }
      });

      // Event delegation to handle delete action
      document.querySelector('#mainDatatablePurchaseOrder tbody').addEventListener('click', function (event) {
        if (event.target && event.target.classList.contains('po-delete')) {
          const po_id = event.target.getAttribute('data-po_id');
          HandlePODelete(po_id);
        }
        if (event.target && event.target.classList.contains('po-vieworder')) {
          const po_id = event.target.getAttribute('data-po_id');
          handleShowInvoice(po_id);
        }
      });
    }
  }, [POData, vendor]);

  useEffect(() => {
    fetchCustomerData();
    fetchproductData1();
    FetchPOData();
  }, []);

  const fromInfoData = [
    { label: 'ID', value: 'PO#0002' },
    { label: 'Date', value: '26-10-2024' },
    { label: 'Name', value: 'Kermit D. Devine' },
    { label: 'Address', value: '3019 Ersel Street Venus, TX 76084' },
    { label: 'Mobile Number', value: '+91 914-366-1459' },
    { label: 'Email', value: 'KermitDDevine@armyspy.com' },
    { label: 'GSTIN', value: '29ABCDE1234F1Z5' }
  ];
  const toInfoData = [
    { label: 'Name', value: 'My Business' },
    { label: 'Address', value: '417 Edgewood Road Memphis, TN 38115' },
    { label: 'Phone Number', value: '+91 914-366-1459' },
    { label: 'GSTIN', value: '123456789000' }
  ];

  const columns = [
    { header: '#', tableText: 'srno' },
    { header: 'Product', tableText: 'productName' },
    { header: 'Quantity (Consumable)', tableText: 'qtyConsumable' },
    { header: 'Quantity (Retail)', tableText: 'qtyRetail' }
  ];

  const data = [
    { srno: 1, productName: 'Product 1', qtyConsumable: '5', qtyRetail: '25' },
    { srno: 2, productName: 'Product 2', qtyConsumable: '10', qtyRetail: '50' }
  ];
  
  const totalSummaryData = null;
  const invoiceNotesData = [
    { invoiceNotesText: "Hello Text" }
  ]

  // Add Invoice modal
  const [invoiceModalShow, setInvoiceModalShow] = useState(false);
  const handleShowInvoice = (id) => {
    setInvoiceModalShow(true);
  }
  const handleCloseInvoice = () => setInvoiceModalShow(false);

  return (
    <>
      <div>
        <div className="d-sm-flex justify-content-between align-items-center mb-3">
          <Card.Title className='mb-sm-0'>Purchase Order</Card.Title>
          <div>
            <Button variant="light custom-form-input-btn" onClick={handleShow}>
              <Icon path={mdiPlus} className="btn-icon me-1" /> Add Purchase Order
            </Button>

            <Modal centered show={show} size="xl" onHide={handleClose}>
              <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
              <Modal.Body>
                <h5 className='mb-3'>Add Purchase Order</h5>

                <h5 className='fs-16 mb-3'>Bill To</h5>
                <Form>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3 custom-form-input">
                        <Form.Label>Vendor Name</Form.Label>
                        <Form.Select  name='vendorName' id='vendorName' onChange={handleVendorChange} value={inputData.vendor_id} >
                          <option>Select Vendor</option>
                          {vendor && vendor.map((v) => (
                            <option key={v.id} value={v.id}>
                              {v.company_name}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3 custom-form-input">
                        <Form.Label>Purchase Order Date</Form.Label>
                        <Form.Control type="date" value={inputData.po_date} name='purchaseOrderDate' id='purchaseOrderDate' onChange={(e) => setInputData(prevState => ({ ...prevState, po_date: handleDateFormat(e.target.value) }))} />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3 custom-form-input">
                        <Form.Label>Product Name</Form.Label>
                        <Form.Select name='productName' id='productName' value={ProductListData.product_id} onChange={e => setProductListData({ ...ProductListData, product_id: e.target.value, product_name: e.target.options[e.target.selectedIndex].text })}>
                          <option>Select Product</option>
                          {productData && productData.map((product) => (
                            <option key={product.id} value={product.id}>
                              {product.description}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3 custom-form-input">
                        <Form.Label>Consumable</Form.Label>
                        <Form.Control type="number" placeholder='Enter consumable...' name='consumable' id='consumable' value={ProductListData.consumable_stock_qty_ordered} onChange={e => setProductListData({ ...ProductListData, consumable_stock_qty_ordered: e.target.value })} />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3 custom-form-input">
                        <Form.Label>Retail</Form.Label>
                        <Form.Control type="number" placeholder='Enter quantity...' name='retail' id='retail' value={ProductListData.retail_stock_qty_ordered} onChange={e => setProductListData({ ...ProductListData, retail_stock_qty_ordered: e.target.value })} />
                      </div>
                    </Col>
                  </Row>
                  <div className='text-end mb-3'>
                    <Button variant="primary" onClick={SetProductInTable} > <Icon path={mdiPlus} className="btn-icon me-1" /> Add</Button>
                  </div>
                </Form>

                <Table responsive bordered>
                  <thead className='table-light'>
                    <tr>
                      <th>Product Name</th>
                      <th>Consumable Stock (In Store)</th>
                      <th>Retail Stock (In Store)</th>
                      <th>Quantity Ordered (Consumable)</th>
                      <th>Quantity Ordered (Retail)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ProductsData && (ProductsData.map((product, index) => product.product_id && (
                      <tr key={index}>
                        <td>{product.product_name}</td>
                        <td>{product.consumable_stock_in_store}</td>
                        <td>{product.retail_stock_in_store}</td>
                        <td>{product.consumable_stock_qty_ordered}</td>
                        <td>{product.retail_stock_qty_ordered}</td>
                      </tr>
                    )))}
                  </tbody>
                </Table>
                <div className='text-end btn-list'>
                  <Button variant="primary" onClick={AddPO}>Add Purchase Order</Button>
                  <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>

        <Table className='table-nowrap' responsive bordered id='mainDatatablePurchaseOrder'>
          <thead className='table-light'>
          </thead>
        </Table>
      </div>

      {/* =========================================================================================== */}
      {/* Currently commenting these code as need to submit PO and PI for testing to check basic crud */}

      {/* <ReceiveDelivery /> */}

      {/* View Invoice Modal */}
      {/* <ViewInvoice invoiceTitle="Manage Purchase Order" invoiceShow={invoiceModalShow} handleCloseInvoice={handleCloseInvoice} columns={columns} data={data} fromInfo={fromInfoData} toInfo={toInfoData} totalSummary={totalSummaryData} invoiceNotes={invoiceNotesData} /> */}
      {/* =========================================================================================== */}
    </>
  )
}

export default function PurchaseOrderWithProvider() {
  return (
    <ProductProvider>
      <CustomerProvider>
        <PurchaseOrder />
      </CustomerProvider>
    </ProductProvider>
  );
}

