import React, { useState } from 'react';
import { Card, Button, Table, Modal, Form, Row, Col } from 'react-bootstrap';

// icon
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';

export default function CoMembers() {
    // Add Co Members Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <Card.Title className='mb-0'>Co-Members Details</Card.Title>
                <Button variant="outline-primary" onClick={handleShow}><Icon path={mdiPlus} className="btn-icon me-1" /> Add Co Members</Button>
                <Modal centered show={show} onHide={handleClose}>
                    <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                    <Modal.Body>
                        <Form>
                            <h5 className='fs-16 mb-4'>Add Co Members</h5>
                            <Row>
                                <Col md={12}>
                                    <div className="mb-3 custom-form-input">
                                        <Form.Label>Customer Name</Form.Label>
                                        <Form.Select  name='coMembersCustomerName' id='coMembersCustomerName'>
                                            <option>Select Customer</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="mb-3 custom-form-input">
                                        <Form.Label>Mobile Number</Form.Label>
                                        <Form.Control type="text" placeholder="(+91) 1234 5678 90" name='coMembersMobileNumber' id='coMembersMobileNumber' />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <ul className='list-inline'>
                                        <li className='list-inline-item me-4'>
                                            {['checkbox'].map((type) => (
                                                <div key={`default-${type}`} className="mb-3">
                                                    <Form.Check
                                                        type={type}
                                                        id={`membership-${type}`}
                                                        label={`Membership`}
                                                        defaultChecked={true}
                                                    />
                                                </div>
                                            ))}
                                        </li>
                                        <li className='list-inline-item me-4'>
                                            {['checkbox'].map((type) => (
                                                <div key={`default-${type}`} className="mb-3">
                                                    <Form.Check
                                                        type={type}
                                                        id={`package-${type}`}
                                                        label={`Package`}
                                                    />
                                                </div>
                                            ))}
                                        </li>
                                        <li className='list-inline-item'>
                                            {['checkbox'].map((type) => (
                                                <div key={`default-${type}`} className="mb-3">
                                                    <Form.Check
                                                        type={type}
                                                        id={`gift-card-${type}`}
                                                        label={`Gift Card`}
                                                    />
                                                </div>
                                            ))}
                                        </li>
                                    </ul>
                                </Col>
                            </Row>

                            <div className='text-end'>
                                <div className="btn-list">
                                    <Button variant="primary" type='submit' onClick={handleClose}>Save</Button>
                                    <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
            <Table responsive bordered className='table-nowrap'>
                <thead className='table-light'>
                    <tr>
                        <th>Co Members Name</th>
                        <th>Co Members Mobile Number</th>
                        <th>Co Members Permissions</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Jennifer V. Pierce</td>
                        <td>+91 1234 5678 90</td>
                        <td>Yes</td>
                        <td>
                            <Button variant="light btn-sm">View Invoice</Button>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </>
    )
}
