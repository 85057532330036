import React, { useEffect } from 'react';
import { Row, Col, Card, Form, Tab, Button, Table, Tabs, Badge } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// icon
import Icon from '@mdi/react';
import { mdiCart, mdiStorefront, mdiReload } from '@mdi/js';

export default function MyStore() {
    // datatable
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('.mainDatatable')) {
            $('.mainDatatable').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, []);
    return (
        <>
            <div className='my-store-content d-none' id='my-store'>
                <Row>
                    <Col sm={6} xxl={3}>
                        <Card className='text-center'>
                            <Card.Body>
                                <div className='avatar avatar-xl bg-soft-primary text-primary mx-auto mb-3'>
                                    <Icon path={mdiCart} className='avatar-icon' />
                                </div>
                                <h2 className='mb-0'>24</h2>
                                <p className='text-muted mb-0'>Consumable Stock (In Store)</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} xxl={3}>
                        <Card className='text-center'>
                            <Card.Body>
                                <div className='avatar avatar-xl bg-soft-success text-success mx-auto mb-3'>
                                    <Icon path={mdiCart} className='avatar-icon' />
                                </div>
                                <h2 className='mb-0'>36</h2>
                                <p className='text-muted mb-0'>Consumable Stock(On Floor)</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} xxl={3}>
                        <Card className='text-center'>
                            <Card.Body>
                                <div className='avatar avatar-xl bg-soft-info text-info mx-auto mb-3'>
                                    <Icon path={mdiStorefront} className='avatar-icon' />
                                </div>
                                <h2 className='mb-0'>20</h2>
                                <p className='text-muted mb-0'>Retail Stock (In Store)</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} xxl={3}>
                        <Card className='text-center'>
                            <Card.Body>
                                <div className='avatar avatar-xl bg-soft-danger text-danger mx-auto mb-3'>
                                    <Icon path={mdiStorefront} className='avatar-icon' />
                                </div>
                                <h2 className='mb-0'>15</h2>
                                <p className='text-muted mb-0'>Retail Stock(On Floor)</p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Tabs defaultActiveKey="openingQuantity" id="uncontrolled-tab-example" className="mb-3">
                                    <Tab eventKey="openingQuantity" title="Opening Quantity">
                                        <div className='mb-3'>
                                            <Form.Select  size="sm" className='w-auto ms-auto'>
                                                <option>All</option>
                                                <option value="1">Today</option>
                                                <option value="2">Monthly</option>
                                                <option value="3">Yearly</option>
                                            </Form.Select>
                                        </div>
                                        <Card className='border shadow-none' >
                                            <Card.Body>
                                                <div className='d-md-flex justify-content-between'>
                                                    <h5 className='fs-16 mb-3'>Opening Quantity</h5>

                                                </div>
                                                <Table responsive bordered className='table-nowrap mainDatatable'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Opening Consumable Quantity</th>
                                                            <th>Opening Retail Quantity</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>0</td>
                                                            <td>0</td>
                                                        </tr>
                                                        <tr>
                                                            <td>0</td>
                                                            <td>2</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>

                                        <Card className='border shadow-none' >
                                            <Card.Body>
                                                <div className='d-md-flex justify-content-between'>
                                                    <h5 className='fs-16 mb-3'>Product Order</h5>

                                                </div>
                                                <Table responsive bordered className='table-nowrap mainDatatable'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Vendor</th>
                                                            <th>Consumable Qty</th>
                                                            <th>Retail Qty</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>28/12/2022</td>
                                                            <td>Dianne M. Rico</td>
                                                            <td>8</td>
                                                            <td>6</td>
                                                            <td>
                                                                <Badge bg="soft-success">Available</Badge>{' '}
                                                            </td>
                                                            <td>
                                                                <Button type='button' variant='light btn-sm'>View More</Button>{' '}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>

                                        <div className='text-center mt-4'>
                                            {/* <Button variant="primary"><Icon spin path={mdiReload} className="btn-icon me-1" /> Load More</Button>{' '} */}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="checkOut" title="Check Out">
                                        <h5 className='fs-16 mb-3'>Check Out</h5>
                                        <Form>
                                            <Row>
                                                <Col xxl={6}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Product Name</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter product name..."
                                                            // value={'01 test product'} 
                                                            name='checkProductName' id='checkProductName' />
                                                    </div>
                                                </Col>
                                                <Col sm={6} xxl={3}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Product Code</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter product code..."
                                                            // value={'31221244'} 
                                                            name='checkProductCode' id='checkProductCode' />
                                                    </div>
                                                </Col>
                                                <Col sm={6} xxl={3}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Current Stock</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter current stock..."
                                                            // value={'0'} 
                                                            name='checkCurrentStock' id='checkCurrentStock' />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Check Out From</Form.Label>
                                                        <Form.Select  name='checkOutFrom' id='checkOutFrom'>
                                                            <option>Consumable(instore)</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Quantity</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter current stock..."
                                                            // value={'0'} 
                                                            name='checkQuantity' id='checkQuantity' />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>To</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter consumable(on floor)..."
                                                            // value={'Consumable (on floor)'} 
                                                            name='checkCurrentStock' id='checkCurrentStock' />
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Comment</Form.Label>
                                                        <Form.Control as="textarea" placeholder='Type your comment...' rows={3} name='checkComment' id='checkComment' />
                                                    </div>

                                                    <div className='btn-list text-end'>
                                                        <Button variant="primary" type="submit">Save</Button>
                                                        <Button variant="secondary" type="submit">Cancel</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Tab>
                                    <Tab eventKey="consumption" title="Consumption">
                                        <h5 className='fs-16 mb-3'>Consumption</h5>
                                        <Form>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Product Name</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter product name..."
                                                            // value={'01 test product'} 
                                                            name='consumptionProductName' id='consumptionProductName' />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Check Out From</Form.Label>
                                                        <Form.Select  name='consumptionOutFrom' id='consumptionOutFrom'>
                                                            <option>42</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Product Code</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter current stock..."
                                                            // value={'31221244'} 
                                                            name='consumptionProductCode' id='consumptionProductCode' />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Current Stock</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter current stock..."
                                                            // value={'0'} 
                                                            name='consumptionCurrentStock' id='consumptionCurrentStock' />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Consumption (weight)</Form.Label>
                                                        <Form.Control type="text" placeholder="0"
                                                            // value={'0'} 
                                                            name='consumptionConsumptionWeight' id='consumptionConsumptionWeight' />
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Comment</Form.Label>
                                                        <Form.Control as="textarea" placeholder='Type your comment...' rows={3} name='consumptionComment' id='consumptionComment' />
                                                    </div>
                                                    <div className='btn-list text-end'>
                                                        <Button variant="primary" type="submit">Save</Button>
                                                        <Button variant="secondary" type="submit">Cancel</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Tab>
                                    <Tab eventKey="convertStock" title="Convert Stock">
                                        <h5 className='fs-16 mb-3'>Convert Stock</h5>
                                        <Form>
                                            <Row>
                                                <Col md={6}>
                                                    <h5 className='fs-16 mb-3'>From</h5>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Product Type</Form.Label>
                                                        <Form.Select  name='convertStockFromProductType' id='convertStockFromProductType'>
                                                            <option>Consumable</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Store Type</Form.Label>
                                                        <Form.Select  name='convertStockFromStoreType' id='convertStockFromStoreType'>
                                                            <option>Instore</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <h5 className='fs-16 mb-3'>To</h5>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Product Type</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter product type..."
                                                            // value={'Retail'} 
                                                            name='convertStockToProductType' id='convertStockToProductType' />
                                                    </div>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Store Type</Form.Label>
                                                        <Form.Select  name='convertStockToStoreType' id='convertStockToStoreType'>
                                                            <option>Instore</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Current Stock (consumable)</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter current stock..."
                                                            name='convertStockCurrentConsumable' id='convertStockCurrentConsumable' />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Current Stock (retail)</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter current stock..." name='convertStockCurrentRetail' id='convertStockCurrentRetail' />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Convert Quantity</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter convert quantity..." name='convertStockConvertQuantity' id='convertStockConvertQuantity' />
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className='btn-list text-end'>
                                                        <Button variant="primary" type="submit">Save</Button>
                                                        <Button variant="secondary" type="submit">Cancel</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Tab>
                                    <Tab eventKey="returnProducts" title="Return Products">
                                        <h5 className='fs-16 mb-3'>Return Products</h5>
                                        <Form>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Return From</Form.Label>
                                                        <Form.Select  name='returnProductsFrom' id='returnProductsFrom'>
                                                            <option>Consumable(onfloor)</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Return Quantity</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Return Quantity..." name='returnProductsQuantity' id='returnProductsQuantity' />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Current Stock</Form.Label>
                                                        <Form.Control type="text" placeholder="0"
                                                            // value={'0'}
                                                            name='returnProductsStock' id='returnProductsStock' />
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className='btn-list text-end'>
                                                        <Button variant="primary" type="submit">Save</Button>
                                                        <Button variant="secondary" type="submit">Cancel</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}
