import React, { useState } from 'react';
import PageTitle from '../layout/PageTitle';
import Footer from '../layout/Footer';
import { Card, Col, Container, Row } from 'react-bootstrap';

// Icon
import Icon from '@mdi/react';
import { mdiArrowRightThin } from '@mdi/js';
import { Link } from 'react-router-dom';

import ReportsLinkData from "./reports-link-data.json";

export default function Reports() {
    const [reportsLink] = useState(ReportsLinkData);
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Accounting" breadcrumbLast="Reports" />

                    <Container>
                        <Row>
                            {reportsLink.map((data, i) =>
                                <Col md={6} xl={4} xxl={3} key={i}>
                                    <Card>
                                        <Card.Body>
                                            <Link to={data.cardLink} className='d-flex justify-content-between align-items-center'>
                                                <h5 className='fw-normal one-line-text mb-0 me-3'>{data.id}.{data.cardName}</h5>
                                                <Icon path={mdiArrowRightThin} className='icon-size-30 text-dark' />
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
