import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, Table, Modal, Form, Badge } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// icon
import Icon from '@mdi/react';
import { mdiPlus, mdiTrashCanOutline, mdiSquareEditOutline } from '@mdi/js';

import TransferOrderTableData from "./transfer-order-table-data.json";
import ViewInvoice from '../../Utils/ViewInvoice';

export default function TransferOrder() {
    // Default Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // datatable
    const [transferOrderData] = useState(TransferOrderTableData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('.maindatatable')) {
            $('.maindatatable').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, []);

    const fromInfoData = [
        { label: 'ID', value: 'PO#0009' },
        { label: 'Dilivery Date', value: '24-10-2024' },
        { label: 'Invoice Date', value: '26-10-2024' },
        { label: 'Vendor Name', value: 'Jems Bond' },
        { label: 'Address', value: '3019 Ersel Street Venus, TX 76084' },
        { label: 'Mobile Number', value: '+91 914-366-1459' },
        { label: 'Email', value: 'KermitDDevine@armyspy.com' },
        { label: 'GSTIN', value: '29ABCDE1234F1Z5' }
      ];
      const toInfoData = [
        { label: 'Name', value: 'My Business' },
        { label: 'Address', value: '417 Edgewood Road Memphis, TN 38115' },
        { label: 'Phone Number', value: '+91 914-366-1459' },
        { label: 'GSTIN', value: '123456789000' }
      ];
    
      const columns = [
        { header: '#', tableText: 'srno' },
        { header: 'Product', tableText: 'productName' },
        { header: 'Quantity (Consumable)', tableText: 'qtyConsumable' },
        { header: 'Quantity (Retail)', tableText: 'qtyRetail' }
      ];
    
      const data = [
        { srno: 1, productName: 'Product 1', qtyConsumable: '5', qtyRetail: '25' },
        { srno: 2, productName: 'Product 2', qtyConsumable: '10', qtyRetail: '50' }
      ];
      
      const totalSummaryData = null;
      const invoiceNotesData = [    
        { invoiceNotesText: "Hello Text" }
      ]
    
      // Add Invoice modal
      const [invoiceModalShow, setInvoiceModalShow] = useState(false);
      const handleShowInvoice = () => setInvoiceModalShow(true);
      const handleCloseInvoice = () => setInvoiceModalShow(false);

    return (
        <>
            <div className="d-sm-flex justify-content-between align-items-center mb-3">
                <Card.Title className='mb-sm-0'>Transfer Order</Card.Title>
                <div>
                    <Button variant="light custom-form-input-btn" onClick={handleShow}>
                        <Icon path={mdiPlus} className="btn-icon me-1" /> Add Transfer Order
                    </Button>

                    <Modal centered show={show} size="xl" onHide={handleClose}>
                        <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                        <Modal.Body>
                            <h5 className='mb-3'>Add Transfer Order</h5>

                            <h5 className='fs-16 mb-3'>Transfer To</h5>
                            <Form>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>Branch</Form.Label>
                                            <Form.Select name='transferToBranch' id='transferToBranch'>
                                                <option>Nothing seleted</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>Transfer Order Date</Form.Label>
                                            <Form.Control type="date" placeholder='' name='transferOrderDate' id='transferOrderDate' />
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>Product Name</Form.Label>
                                            <Form.Select name='productName' id='productName'>
                                                <option>Select Product</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>Consumable</Form.Label>
                                            <Form.Control type="text" placeholder='Enter consumable...' name='consumable' id='consumable' />
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>Consumable Loose Qty</Form.Label>
                                            <Form.Control type="text" placeholder='Enter quantity...' name='consumableLooseQty' id='consumableLooseQty' />
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>Retail</Form.Label>
                                            <Form.Control type="text" placeholder='Enter quantity...' name='retail' id='retail' />
                                        </div>
                                    </Col>
                                </Row>
                                <div className='text-end mb-3'>
                                    <Button variant="primary" onClick={handleClose}> <Icon path={mdiPlus} className="btn-icon me-1" /> Add</Button>
                                </div>
                            </Form>

                            <Table responsive bordered>
                                <thead className='table-light'>
                                    <tr>
                                        <th>Product Name</th>
                                        <th>Consumable Stock (In Store)</th>
                                        <th>Consumable Stock(On Floor)</th>
                                        <th>Retail Stock (In Store)</th>
                                        <th>Quantity Orderd (Consumable)</th>
                                        <th>Quantity Orderd (Loose)</th>
                                        <th>Quantity Orderd (Retail)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Nike Shoes</td>
                                        <td>15</td>
                                        <td>5</td>
                                        <td>20</td>
                                        <td>10</td>
                                        <td>12</td>
                                        <td>8</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <div className='text-end btn-list'>
                                <Button variant="primary" onClick={handleClose}>Add Transfer Order</Button>
                                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>

            <Card className='border shadow-none'>
                <Card.Body>
                    <h6 className='fs-16 mb-3'>Transfer Invoice Received (TO Sent)</h6>

                    <Table responsive bordered className='table-nowrap maindatatable'>
                        <thead className='table-light'>
                            <tr>
                                <th>Date</th>
                                <th>TI#</th>
                                <th>Branch</th>
                                <th>TO#</th>
                                <th>Total</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>28/12/2022</td>
                                <td>TI#0001</td>
                                <td>NFS Adajan</td>
                                <td>TO#0002</td>
                                <td>0</td>
                                <td><Badge bg="soft-success">Request Accepted</Badge></td>
                                <td className='py-1'><Button variant="light btn-sm">View Order</Button></td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <Card className='border shadow-none'>
                <Card.Body>
                    <h6 className='fs-16 mb-3'>Transfer Invoice Sent (TO Receive)</h6>

                    <Table responsive bordered className='table-nowrap maindatatable'>
                        <thead className='table-light'>
                            <tr>
                                <th>Date</th>
                                <th>TI#</th>
                                <th>Branch</th>
                                <th>TO#</th>
                                <th>Total</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transferOrderData.transferOrderList.map((data, i) =>
                                <tr key={i}>
                                    <td>{data.date}</td>
                                    <td>{data.ti}</td>
                                    <td>{data.branch}</td>
                                    <td>{data.to}</td>
                                    <td>{data.totla}</td>
                                    <td><Badge bg={`${data.badgeColor}`}>{data.badgeText}</Badge></td>
                                    <td className='py-1'>
                                        <div className='btn-list d-flex'>
                                            <Button type='button' variant='light btn-sm' onClick={handleShowInvoice}>View Order</Button>{' '}
                                            <Button type='button' variant='light btn-sm'>View Invoice</Button>{' '}
                                            <div className='ms-auto'>
                                                <Button variant="soft-info btn-sm px-3"><Icon path={mdiSquareEditOutline} className="btn-icon" /></Button>{' '}
                                                <Button variant="soft-danger btn-sm px-3"><Icon path={mdiTrashCanOutline} className="btn-icon" /></Button>{' '}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            {/* View Invoice Modal */}
            <ViewInvoice invoiceTitle="View Transfer Order" invoiceShow={invoiceModalShow} handleCloseInvoice={handleCloseInvoice} columns={columns} data={data} fromInfo={fromInfoData} toInfo={toInfoData} totalSummary={totalSummaryData} invoiceNotes={invoiceNotesData} />
        </>
    )
}
