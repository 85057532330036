import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { postToAPI, getFromAPI, formatISODateWithTimezone } from '../../Utils/utils';
import { toast } from 'react-toastify';

// icon
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';

// update

export default function NewAccount({ onAccountInserted }) {
    // State to manage form input values
    const [formData, setFormData] = useState({
        name: '',
        account_number: '',
        tax: '',  // Update to an integer
        category_id: '',  // Update to an integer
        acc_gst_number: '',
        state: '',  // Update to an integer
        description: '',
        opening_balance: '',
        share_of_profit: '',
        account_across_branch: '',  // Update to an integer
        holder_name: '',
        budget: '',
        account_type: 1,  // Update to an integer for Credit (0 for Debit)
        current_balance: 0,
        account_nature: '',  // Update to an integer
        isdefault: 0,
        isactive: 1,
        created_branch_id: 1,
        created_user_id: 1,
        last_modified_user_id: 1,
        created_date_time: formatISODateWithTimezone(new Date()),
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        isdeleted: 0,
    });

    const [formcategorydata, setFormcategorydata] = useState({
        name: '',
        code: '',
        type: '',
        bs_anx : '',
        index_no: 1,
        account_master_id: 1,
        created_branch_id: 1,
        created_user_id: 1,
        last_modfied_user_id: 1,
        created_date_time: formatISODateWithTimezone(new Date()),
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        isdeleted: 0,
        reserve_varchar1: 'test',
        reserve_varchar2: 'test',
        reserve_float1: 0.0,
        reserve_float2: 0.0,
        reserve_int1: 0,
        reserve_int2: 0,
        reserve_double1: 0.0,
        reserve_date1: 1,
    });

    const [formErrors, setFormErrors] = useState({
        name: '',
        account_number: '',
        tax: '',
        category_id: '',
        acc_gst_number: '',
        state: '',
        description: '',
        opening_balance: '',
        share_of_profit: '',
        account_across_branch: '',
        holder_name: '',
        budget: '',
    });
    

    const [categories, setCategories] = useState([]);  // State to store fetched categories
    const [states, setStates] = useState([]);
    const [tax, setTax] = useState([]);
    const [showModal, setShowModal] = useState(false);


    
    // Fetch category data
    const FetchCategoryData = () => {
    getFromAPI('FtsAccountCategory/').then(response => {

        // Directly set categories if the response is an array of categories
        if (Array.isArray(response)) {
            setCategories(response);  // Set the category data in state
        } else {
            console.error('Unexpected response structure:', response);
        }
    }).catch(error => {
        console.error('Error fetching category data:', error);
    });
};

    const FetchTaxData = () => {
        getFromAPI('FtsTaxes/').then(response => {
            if (Array.isArray(response)) {
                setTax(response);  // Set the category data in state
            } else {
                console.error('Unexpected response structure:', response);
            }
        }).catch(error => {
            console.error('Error fetching tax data:', error);
        });
    };

    const FetchStateData = () => {
        getFromAPI('FtsState/').then(response => {
            if (Array.isArray(response)) {
                setStates(response);  // Set the category data in state
            } else {
                console.error('Unexpected response structure:', response);
            }
        }).catch(error => {
            console.error('Error fetching state data:', error);
        });
    };

    useEffect(() => {
        FetchCategoryData(); 
        FetchTaxData(); 
        FetchStateData();
    }, []);

    // Handle input change for all form fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCategoryChange = (e) => {
        const { name, value } = e.target;
        setFormcategorydata({
            ...formcategorydata,
            [name]: value,
        });
    };

    // Open modal
    const handleOpenModal = () => setShowModal(true);

    // Close modal
    const handleCloseModal = () => setShowModal(false);

    const validateForm = () => {
        const errors = {
            name: '',
            account_number: '',
            tax: '',
            category_id: '',
            state: '',
            description: '',
            acc_gst_number: '',
            opening_balance: '',
            share_of_profit: '',
            account_across_branch: '',
            holder_name: '',
            budget: '',
            account_type: '',
        };
    
        if (!formData.name.trim()) {
            errors.name = 'Account Name is required.';
        }
    
        if (!formData.account_number.trim()) {
            errors.account_number = 'Account Number is required.';
        } else if (!/^\d+$/.test(formData.account_number.trim())) {
            errors.account_number = 'Account Number must be numeric.';
        }

        if (!formData.tax.trim()) {
            errors.tax = 'Tax is required.';
        }
    
        if (formData.category_id === 0) {
            errors.category_id = 'Please select a valid Category.';
        }

        if (!formData.acc_gst_number.trim()) {
            errors.acc_gst_number = 'GST Number is required.';
        }

        if (formData.state === 0) {
            errors.state = 'Please select a valid State.';
        }   

        if (!formData.description.trim()) {
            errors.description = 'Description is required.';
        }
    
        if (formData.opening_balance === '' || isNaN(formData.opening_balance)) {
            errors.opening_balance = 'Opening Balance must be a valid number.';
        }

        if (formData.share_of_profit === '' || isNaN(formData.share_of_profit)) {
            errors.share_of_profit = 'Share of Profit must be a valid number.';
        }

        if (formData.account_across_branch === 0) {
            errors.account_across_branch = 'Please select a valid Branch.';
        }

        if (!formData.holder_name.trim()) {
            errors.holder_name = 'Holder Name is required.';
        }
    
        if (formData.budget === '' || isNaN(formData.budget)) {
            errors.budget = 'Budget must be a valid number.';
        }
    
        if (formData.account_type !== 0 && formData.account_type !== 1) {
            errors.account_type = 'Please select a valid Account Type.';
        }
    
        // Check if there are errors
        if (Object.values(errors).some(error => error)) {
            setFormErrors(errors);
            return false;
        }
    
        // Clear errors if validation is successful
        setFormErrors({});
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

          // Perform validation
          if (!validateForm()) {
            return;
        }
    
        try {
            // Prepare updated form data with proper integer conversions
            const updatedFormData = {
                ...formData,
                tax: parseInt(formData.tax, 10) || 0, // Default to 0 if NaN
                category_id: parseInt(formData.category_id, 10) || 0,
                state: parseInt(formData.state, 10) || 0,
                account_across_branch: parseInt(formData.account_across_branch, 10) || 0,
                account_type: parseInt(formData.account_type, 10) || 0,
                account_nature: parseInt(formData.account_nature, 10) || 0,
            };

            
    
            const response = await postToAPI('FtsAccount/', updatedFormData); // Adjust API endpoint
    
            // Check for a successful status
            if (response && response.status === true) { // Check if response exists and status is true
                onAccountInserted(); // Call the parent's refresh handler
                alert('Account added successfully');
                // Clear form after successful submission
                setFormData({
                    name: '',
                    account_number: '',
                    tax: '',
                    category_id: '',
                    acc_gst_number: '',
                    state: '',
                    description: '',
                    opening_balance: '',
                    share_of_profit: '',
                    account_across_branch: '',
                    holder_name: '',
                    budget: '',
                    account_type: '',
                    current_balance: '',
                    account_nature: '',
                    isdefault: '',
                    isactive: '',
                    created_branch_id: '',
                    created_user_id: '',
                    last_modified_user_id: '',
                    created_date_time: '',
                    last_modified_date_time: '',
                    isdeleted: '',
                });
            } else {
                // If the response status is not true, alert the message returned from API
                alert(response.message || 'Error adding account. Please try again.');
            }
        } catch (error) {
            console.error('Error posting account data:', error);
            alert('Error adding account. Please try again.');
        }
    };
    
    const handleCategorySubmit = async (e) => {
        e.preventDefault();
    
        // Simple form validation to ensure fields are not empty
        if (!formcategorydata.name || !formcategorydata.code || !formcategorydata.type) {
            alert('Please fill in all the required fields');
            return;
        }
    
        try {
            // Ensure to handle API call asynchronously
            const response = await postToAPI('FtsAccountCategory/', formcategorydata); // Adjust API endpoint
        
            // Check for a successful status
            if (response && response.status === true) {
                alert('Category added successfully');
                handleCloseModal();
    
                // Clear the form after successful submission
                setFormcategorydata({
                    name: '',
                    code: '',
                    type: '',
                    bs_anx: '',
                    index_no: 1,
                    account_master_id: 1,
                    created_branch_id: 1,
                    created_user_id: 1,
                    last_modfied_user_id: 1,
                    created_date_time: formatISODateWithTimezone(new Date()),
                    last_modified_date_time: formatISODateWithTimezone(new Date()),
                    isdeleted: 0,
                    reserve_varchar1: '',
                    reserve_varchar2: '',
                    reserve_float1: 0.0,
                    reserve_float2: 0.0,
                    reserve_int1: 0,
                    reserve_int2: 0,
                    reserve_double1: 0.0,
                    reserve_date1: 1,
                });
    
                // Call the function to refresh the category dropdown
                FetchCategoryData(); // This should fetch the latest categories from the API
            } else {
                // If the response status is not true, alert the message returned from API
                alert(response.message || 'Error adding category. Please try again.');
            }
        } catch (error) {
            console.error('Error posting category data:', error);
            alert('Error adding category. Please try again.');
        }
    };
    

    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <Card.Title>Add Account</Card.Title>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Account Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter account name..."
                                        name='name'
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                     {formErrors.name && <div className="text-danger">{formErrors.name}</div>}
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Account Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter account number..."
                                        name='account_number'
                                        value={formData.account_number}
                                        onChange={handleChange}
                                    />
                                     {formErrors.account_number && <div className="text-danger">{formErrors.account_number}</div>}
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name='tax'
                                        value={formData.tax}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Tax</option>
                                            {tax.map(tax => (
                                                <option key={tax.id} value={tax.id}>
                                                    {tax.name}
                                                </option>
                                            ))}
                                    </Form.Select>
                                     {formErrors.tax && <div className="text-danger">{formErrors.tax}</div>}
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 d-flex">
                                <div className='custom-form-input w-100'>
                                        <Form.Label>Category</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            name='category_id'
                                            value={formData.category_id}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select Category</option>
                                            {categories.map(category => (
                                                <option key={category.id} value={category.id}>
                                                    {category.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        {formErrors.category_id && <div className="text-danger">{formErrors.category_id}</div>}
                                    </div>

                                    <Button variant="light custom-form-input-btn ms-2 mt-2" onClick={handleOpenModal}>
                                        <Icon path={mdiPlus} size={1} />
                                    </Button>
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>GST Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter GST Number..."
                                        name='acc_gst_number'
                                        value={formData.acc_gst_number}
                                        onChange={handleChange}
                                    />
                                    {formErrors.acc_gst_number && <div className="text-danger">{formErrors.acc_gst_number}</div>}
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name='state'
                                        value={formData.state_id}
                                        onChange={handleChange}
                                    >
                                         <option value="">Select State</option>
                                            {states.map(state => (
                                                <option key={state.id} value={state.id}>
                                                    {state.state_name}
                                                </option>
                                            ))}
                                    </Form.Select>
                                    {formErrors.state_id && <div className="text-danger">{formErrors.state_id}</div>}
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Type description..."
                                        name='description'
                                        value={formData.description}
                                        onChange={handleChange}
                                    />
                                    {formErrors.description && <div className="text-danger">{formErrors.description}</div>}
                                </div>
                            </Col>
                            <Col xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Opening Balance</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter opening balance..."
                                        name='opening_balance'
                                        value={formData.opening_balance}
                                        onChange={handleChange}
                                    />
                                    {formErrors.opening_balance && <div className="text-danger">{formErrors.opening_balance}</div>}
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>(%)Share of Profit</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter share of profit..."
                                        name='share_of_profit'
                                        value={formData.share_of_profit}
                                        onChange={handleChange}
                                    />
                                    {formErrors.share_of_profit && <div className="text-danger">{formErrors.share_of_profit}</div>}
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Account Across Branch</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name='account_across_branch'
                                        value={formData.account_across_branch}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="1">Yes</option>
                                        <option value="2">No</option>
                                    </Form.Select>
                                    {formErrors.account_across_branch && <div className="text-danger">{formErrors.account_across_branch}</div>}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Holder Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter holder name..."
                                        name='holder_name'
                                        value={formData.holder_name}
                                        onChange={handleChange}
                                    />
                                </div>
                                    {formErrors.holder_name && <div className="text-danger">{formErrors.holder_name}</div>}
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Budget</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter budget..."
                                        name='budget'
                                        value={formData.budget}
                                        onChange={handleChange}
                                    />
                                    {formErrors.budget && <div className="text-danger">{formErrors.budget}</div>}
                                </div>
                            </Col>
                        </Row>

                        {/* Credit/Debit Radio Buttons */}
                        <div className="d-flex">
                            <div className="mb-3 me-4">
                                <Form.Check
                                    type="radio"
                                    name="account_type"
                                    id="credit"
                                    label="Credit"
                                    checked={formData.account_type === 1}
                                    onChange={() => setFormData({ ...formData, account_type: 1 })}
                                />
                            </div>
                            <div className="mb-3">
                                <Form.Check
                                    type="radio"
                                    name="account_type"
                                    id="debit"
                                    label="Debit"
                                    checked={formData.account_type === 0}
                                    onChange={() => setFormData({ ...formData, account_type: 0 })}
                                />
                            </div>
                        </div>
                    </Form>
                </Card.Body>
                <div className='custom-card-footer'>
                    <div className="btn-list text-end">
                        <Button variant="primary" type="submit" onClick={handleSubmit}>Add</Button>{' '}
                        <Button variant="secondary" type="button">Cancel</Button>{' '}
                    </div>
                </div>
            </Card>

            {/* Modal for adding account category */}
            <Modal centered show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Account Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Account Category Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter category name..." name="name" value={formcategorydata.name} onChange={handleCategoryChange}  />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Code</Form.Label>
                                    <Form.Control type="text" placeholder="Enter code..." name="code" value={formcategorydata.code} onChange={handleCategoryChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mb-3">
                            <Form.Label>Account Type</Form.Label>
                            <Form.Select
                                        aria-label="Default select example"
                                        name='type'
                                        value={formcategorydata.type}
                                        onChange={handleCategoryChange}
                             >
                                <option value="">Select</option>
                                <option value="1">Trading Account</option>
                                <option value="2">Bank Account</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Check
                                type="radio"
                                label="Expense"
                                name="accountNature"
                                id="expense"
                                checked={formcategorydata.bs_anx === 0}
                                onChange={() => setFormcategorydata({ ...formcategorydata, bs_anx: 0 })}
                            />
                            <Form.Check
                                type="radio"
                                label="Income"
                                name="accountNature"
                                id="income"
                                checked={formcategorydata.bs_anx === 1}
                                onChange={() => setFormcategorydata({ ...formcategorydata, bs_anx: 1 })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCategorySubmit}>Add</Button>
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
