import React, { useState, useEffect } from 'react';
import { Card, Button, Table, Modal, Form, Row, Col } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// table-data
import ViewReceivedTableData from "./view-received-table-data.json";

// icon
import Icon from '@mdi/react';
import { mdiPlus, mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';

export default function Received() {
    // Default Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // datatable
    const [viewReceivedData] = useState(ViewReceivedTableData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableBankReceived')) {
            $('#mainDatatableBankReceived').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, []);
    return (
        <>
            <div className="d-sm-flex justify-content-between align-items-center mb-3">
                <Card.Title className='mb-sm-0'>Received</Card.Title>
                <div>
                    <div className='d-sm-flex align-items-center'>
                        <Button variant="light custom-form-input-btn me-sm-3 mb-sm-0 mb-3" onClick={handleShow}>
                            <Icon path={mdiPlus} className="btn-icon me-1" /> Add Received
                        </Button>
                        <Form className='me-sm-3 mb-sm-0 mb-3'>
                            <Form.Select  size="sm">
                                <option>Year to date(YTD)</option>
                                <option value="1">All Time</option>
                                <option value="2">Two</option>
                            </Form.Select>
                        </Form>
                        <Form>
                            <Form.Select  size="sm">
                                <option>All</option>
                                <option value="1">Select One</option>
                                <option value="2">Two</option>
                            </Form.Select>
                        </Form>
                    </div>
                    <Modal centered show={show} size="xl" onHide={handleClose}>
                        <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                        <Modal.Body>
                            <h5 className='mb-3'>Add Received</h5>

                            <Form>
                                <Row>
                                    <Col xl={4}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>Reference No</Form.Label>
                                            <Form.Control type="text" placeholder='Enter no...' name='referenceNo' id='referenceNo' />
                                        </div>
                                    </Col>
                                    <Col lg={6} xl={4}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>Expense Date</Form.Label>
                                            <Form.Control type="date" placeholder='Enter no...' name='expenseDate' id='expenseDate' />
                                        </div>
                                    </Col>
                                    <Col lg={6} xl={4}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>Account</Form.Label>
                                            <Form.Select  name='account' id='account'>
                                                <option>Cash - 3243948</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>Payee Dialog</Form.Label>
                                            <Form.Control type="text" placeholder='Press enter to get Payee dialog...' name='payeeDialog' id='payeeDialog' />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>Amount</Form.Label>
                                            <Form.Control type="text" placeholder='Press enter to get list of Invoices/Purchase Invoices...' name='amount' id='amount' />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>Cheque No</Form.Label>
                                            <Form.Control type="text" placeholder='Enter cheque no...' name='chequeNo' id='chequeNo' />
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control type="text" placeholder='Enter description...' name='description' id='description' />
                                        </div>
                                    </Col>
                                </Row>
                                <div className='text-end btn-list'>
                                    <Button variant="primary" onClick={handleClose}>Generate and New</Button>{' '}
                                    <Button variant="success" onClick={handleClose}>Generate</Button>{' '}
                                    <Button variant="secondary" onClick={handleClose}>Cancel</Button>{' '}
                                </div>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>

            <Table responsive bordered className='table-nowrap' id='mainDatatableBankReceived'>
                <thead className='table-light'>
                    <tr>
                        <th>Sr.No</th>
                        <th>Reff No</th>
                        <th>Account</th>
                        <th>Date</th>
                        <th>Category</th>
                        <th>Amount</th>
                        <th>Payment Mode</th>
                        <th>Payee Type</th>
                        <th>Payee</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {viewReceivedData.map((data, i) =>
                        <tr key={i}>
                            <td>{data.srNo}</td>
                            <td>{data.reffNo}</td>
                            <td>{data.account}</td>
                            <td>{data.date}</td>
                            <td>{data.category}</td>
                            <td>{data.amount}</td>
                            <td>{data.paymentMode}</td>
                            <td>{data.payeeType}</td>
                            <td>{data.payee}</td>
                            <td className='py-1 text-end'>
                                <Button variant="soft-info btn-sm px-3"><Icon path={mdiSquareEditOutline} className="btn-icon" /></Button>{' '}
                                <Button variant="soft-danger btn-sm px-3"><Icon path={mdiTrashCanOutline} className="btn-icon" /></Button>{' '}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    )
}
