import React, { useState } from 'react';
import { Card, Form, Row, Col, Table } from 'react-bootstrap';

import BalanceSheetLiabilitiesData from "./balance-sheet-liabilities-data.json";
import BalanceSheetAssetsData from "./balance-sheet-assets-data.json";

export default function BalanceSheetData() {
    const [liabilitiesData] = useState(BalanceSheetLiabilitiesData);
    const [AssetsData] = useState(BalanceSheetAssetsData);
    return (
        <>
            <div className="d-sm-flex justify-content-between align-items-center mb-3">
                <Card.Title className='mb-sm-0'>Balance Sheet</Card.Title>
                <Form>
                    <div className='min-sm-w'></div>
                    <Form.Select  size="sm">
                        <option>All Time</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                    </Form.Select>
                </Form>
            </div>

            <Row>
                <Col lg={6}>
                    <div className="d-flex flex-column h-100">
                        <Table responsive className='border td-end table-nowrap'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Liabilities</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                        </Table>
                        <Table responsive className='border td-end table-nowrap mb-0'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Capital Account</th>
                                    <th>8,148,954.12</th>
                                </tr>
                            </thead>
                            <tbody>
                                {liabilitiesData.LiabilitiesCaitalAccount.map((data, i) =>
                                    <tr key={i}>
                                        <td>{data.LiabilitiescapitalAccount}</td>
                                        <td>{data.LiabilitiescapitalAccountAmount}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <Table responsive className='border td-end table-nowrap mb-0'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Provisions</th>
                                    <th>344,002.40</th>
                                </tr>
                            </thead>
                            <tbody>
                                {liabilitiesData.LiabilitiesProvisions.map((data, i) =>
                                    <tr key={i}>
                                        <td>{data.LiabilitiesProvisions}</td>
                                        <td>{data.LiabilitiesProvisionsAmount}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <Table responsive className='border td-end table-nowrap mb-0'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Secured Loans</th>
                                    <th>10,591,511.35</th>
                                </tr>
                            </thead>
                            <tbody>
                                {liabilitiesData.LiabilitiesSecuredLoans.map((data, i) =>
                                    <tr key={i}>
                                        <td>{data.LiabilitiesSecuredLoans}</td>
                                        <td>{data.LiabilitiesSecuredLoansAmount}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <Table responsive className='border td-end table-nowrap'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Unsecured Loans</th>
                                    <th>1,300,500.00</th>
                                </tr>
                            </thead>
                            <tbody>
                                {liabilitiesData.LiabilitiesUnsecuredLoans.map((data, i) =>
                                    <tr key={i}>
                                        <td>{data.LiabilitiesUnsecuredLoans}</td>
                                        <td>{data.LiabilitiesUnsecuredLoansAmount}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>

                        <div className="mt-auto">
                            <Table responsive className='border td-end table-nowrap'>
                                <thead className='table-light'>
                                    <tr>
                                        <th>Net Loss</th>
                                        <th>-4,432,830.73</th>
                                    </tr>
                                </thead>
                                <tfoot className='table-light'>
                                    <tr>
                                        <th>Total Liabilities</th>
                                        <th>21,744,249.72</th>
                                    </tr>
                                </tfoot>
                            </Table>
                        </div>
                    </div>
                </Col>

                <Col lg={6} className='mt-5 mt-lg-0'>
                    <div className="d-flex flex-column h-100">
                        <Table responsive className='border td-end table-nowrap'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Assets</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                        </Table>
                        <Table responsive className='border td-end table-nowrap mb-0'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Bank Account</th>
                                    <th>-6,057,984.19</th>
                                </tr>
                            </thead>
                            <tbody>
                                {AssetsData.Assetsbankaccount.map((data, i) =>
                                    <tr key={i}>
                                        <td>{data.AssetsBankAccount}</td>
                                        <td>{data.AssetsBankAccountAmount}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <Table responsive className='border td-end table-nowrap mb-0'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Cash Account</th>
                                    <th>4,330,452.60</th>
                                </tr>
                            </thead>
                            <tbody>
                                {AssetsData.Assetscashaccount.map((data, i) =>
                                    <tr key={i}>
                                        <td>{data.AssetsCashAccount}</td>
                                        <td>{data.AssetsCashAccountAmount}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <Table responsive className='border td-end table-nowrap mb-0'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Digital Wallet</th>
                                    <th>30,085.00</th>
                                </tr>
                            </thead>
                            <tbody>
                                {AssetsData.Assetsdigitalwallet.map((data, i) =>
                                    <tr key={i}>
                                        <td>{data.AssetsDigitalWallet}</td>
                                        <td>{data.AssetsDigitalWalletAmount}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <Table responsive className='border td-end table-nowrap mb-0'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Fixed Assets</th>
                                    <th>3,825,394.75</th>
                                </tr>
                            </thead>
                            <tbody>
                                {AssetsData.Assetsfixed.map((data, i) =>
                                    <tr key={i}>
                                        <td>{data.AssetsFixed}</td>
                                        <td>{data.AssetsFixedAmount}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <div className="mt-auto">
                            <Table responsive className='border td-end table-nowrap'>
                                <thead className='table-light'>
                                    <tr>
                                        <th>Closing Stock</th>
                                        <th>21,038,259.00</th>
                                    </tr>
                                </thead>
                                <tfoot className='table-light'>
                                    <tr>
                                        <th>Total Assets</th>
                                        <th>37,553,139.28</th>
                                    </tr>
                                </tfoot>
                            </Table>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Table responsive className='border td-end table-nowrap mb-0'>
                        <thead className='table-light'>
                            <tr>
                                <th>Total Difference</th>
                                <th>-4,432,830.73</th>
                            </tr>
                        </thead>
                    </Table>
                </Col>
            </Row>
        </>
    )
}
