import React from 'react';
import { Table } from 'react-bootstrap';

export default function CreateInvoiceTableData() {
    return (
        <>
            <Table responsive bordered className='table-nowrap mb-0'>
                <thead className='table-light'>
                    <tr>
                        <th>Sr.No</th>
                        <th>Item</th>
                        <th>Description</th>
                        <th>Provider</th>
                        <th>Unit Price</th>
                        <th>Quantity</th>
                        <th>Discount</th>
                        <th>Discount Type</th>
                        <th>Tax(%)</th>
                        <th>Tax Type</th>
                        <th>Tax Amount</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>01</td>
                        <td>10</td>
                        <td>Good Product</td>
                        <td>Patel Mall</td>
                        <td>460</td>
                        <td>8</td>
                        <td>20</td>
                        <td>20% Off</td>
                        <td>2.5%</td>
                        <td>2.5%</td>
                        <td>40</td>
                        <td>350</td>
                    </tr>
                </tbody>
            </Table>
        </>
    )
}
