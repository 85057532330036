import React, { useState,useRef } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { getFromAPI,postToAPI } from '../../Utils/utils';
import { toast } from 'react-toastify';
import *as XLSX from 'xlsx';


export default function ImportBranchProduct() {
    const[data,setData] = useState({
        branch : '',
        product : '',
        product_sell_price : '',
        product_mrp : '',
        rating_id : '',
        isproduct_splited : '',
        tax_type : '',
        tax_value : '',
        discount_type : '',
        discount_value : '',
        product_mrp : '',
    });

    const[importedData,setImportedData] = useState([]);
    const [fileInputKey, setFileInputKey] = useState(0); // Track the key for file input

    const handleFileChange  = (e) => {
        const file = e.target.files[0];
            if (file && /\.(xlsx|xls|csv)$/.test(file.name)) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const arrayBuffer = event.target.result;
                try {
                    const workbook = XLSX.read(arrayBuffer, { type: 'array' });
                    const firstSheetName = workbook.Sheets[workbook.SheetNames[0]];
                    const importedData = XLSX.utils.sheet_to_json(firstSheetName);

                    const headerMapping = {
                        'Branch Id': 'branch',
                        'Product Id': 'product',
                        'Product Sell Price':'product_sell_price',
                        'Product MRP': 'product_mrp',
                        'Rating Id': 'rating_id',
                        'Is Product Splitted': 'isproduct_splited',
                        'Tax Type': 'tax_type',
                        'Tax Value': 'tax_value',
                        'Discount Type': 'discount_type',
                        'Discount value': 'discount_value'
                    };
    
                    // Map and trim data
                    const trimmedImportedData = importedData.map(entry => {
                        const trimmedEntry = {};
                        Object.keys(entry).forEach(key => {
                            if (headerMapping[key]) {
                                trimmedEntry[headerMapping[key]] = entry[key];
                            }
                        });
                        return trimmedEntry;
                    });
    
                    setImportedData(trimmedImportedData);
    
                } catch (error) {
                    console.error('Error reading file:', error);
                    toast.error('Error reading Excel file');
                }
            };
    
            reader.readAsArrayBuffer(file); // Read the file as array buffer
        } else {
            toast.error('Please upload a valid Excel or CSV file.');
        }
    };

    const handleAcceptData = async() => {
         // Check if there is any data to import
        if (importedData.length === 0) {
            toast.error('Please select a valid CSV file before importing.');
            return; // Exit the function early if no data is present
        }

        try {
            const response = await postToAPI('FtsProductBranchDetails/', { data: importedData }, {
                headers: { 'Content-Type': 'application/json' },
            })
            
            if(response.status){
                toast.success('Data Verified and saved successfully','success')
                setData([...data,...importedData]);
                setImportedData([]);
                setFileInputKey(prevKey => prevKey + 1); // Increment key to reset input
            }
            else {
                toast.error('Failed to import products', {});
                setFileInputKey(prevKey => prevKey + 1); // Increment key to reset input
            }
        } catch (error) {
            console.error('Failed to import products', {});
            setFileInputKey(prevKey => prevKey + 1); // Increment key to reset input
        }
       };
    
       const handleDeclineData = () => {
        setImportedData([]);
        setFileInputKey(prevKey => prevKey + 1); // Increment key to reset input
     };

    
    // Function to generate and download sample CSV
    const downloadSampleCSV = () => {
        const sampleData = [
            {
                'Branch Id': 1,
                'Product Id': 1,
                'Product Sell Price':800,
                'Product MRP': 1000,
                'Rating Id': 1,
                'Is Product Splitted': 0,
                'Tax Type': 1,
                'Tax Value': 1,
                'Discount Type': 1,
                'Discount value': 15
            }
        ];

        const worksheet = XLSX.utils.json_to_sheet(sampleData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sample Data");
        
        // Generate buffer
        XLSX.writeFile(workbook, 'sample_branch_product_data.csv');
    };

    return (
        <>
            <Card className='right-content-details'>
                <Card.Body className='pb-0'>
                    <Card.Title>Import Branch Data Of Products</Card.Title>
                    <Form.Group controlId="formFileImportBranchProducts" className="mb-3">
                        <Form.Label>Select File</Form.Label>
                        <Form.Control 
                           type="file" 
                           name='importBranchProducts' 
                           onChange={handleFileChange} 
                           key={fileInputKey} // Use key to reset the file input
                           />
                    </Form.Group>
                    <Row className='justify-content-center'>
                        <Col xl={8} className="text-center">
                            <h5 className='mb-3'>Download a sample csv file that you can populate with your own data</h5>
                            <p className='text-muted lh-lg fs-14'>Use the button below to upload a csv file of your product list. Once you have selected a file to upload, your product list will be imported.
                                Duplicate products(based on product name) are not created by this process, so you can correct errors and upload the same file
                                more than once, if necessary.</p>
                        </Col>
                    </Row>
                </Card.Body>
                <div className='custom-card-footer'>
                    <div className="btn-list text-end">
                        <Button variant="primary" onClick={downloadSampleCSV}>Download Sample</Button>{' '}
                        <Button variant="success" onClick={handleAcceptData}>Import</Button>{' '}
                        <Button variant="secondary" onClick={handleDeclineData}>Cancel</Button>{' '}
                    </div>
                </div>
            </Card>
        </>
    )
}
