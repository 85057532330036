import React from 'react';
import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import ViewInvoices from './ViewInvoices';
import CreateInvoice from './CreateInvoice';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';

// icon
import Icon from '@mdi/react';
import { mdiPlus, mdiReceiptTextOutline } from '@mdi/js';

export default function Invoices() {
    // Change card title
    const handleClick = event => {
        var buttonID = event.currentTarget.id;
        var button = document.getElementById(buttonID);
        var cardtitle = document.getElementById('cardTitle');
        var buttonText = button.textContent;
        cardtitle.innerHTML = buttonText;
      };
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Sales" breadcrumbLast="View Invoices" />

                    <Container>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <div className="d-md-flex justify-content-between align-items-center">
                                            <Card.Title className='mb-md-0' id="cardTitle">View Invoices</Card.Title>
                                            <div className="custom-top-nav-tab d-flex mt-3 mt-md-0">
                                                <ul className="nav nav-pills btn-list me-2" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" onClick={handleClick} data-text="View Invoices" id="pills-view-invoices-tab" data-bs-toggle="pill" data-bs-target="#pills-view-invoices" type="button" role="tab" aria-controls="pills-view-invoices" aria-selected="true">
                                                            <Icon path={mdiReceiptTextOutline} className="btn-icon me-1" />View Invoices
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" onClick={handleClick} data-text="Create Invoice" id="pills-create-invoice-tab" data-bs-toggle="pill" data-bs-target="#pills-create-invoice" type="button" role="tab" aria-controls="pills-create-invoice" aria-selected="false">
                                                            <Icon path={mdiPlus} className="btn-icon me-1" />Create Invoice
                                                        </button>
                                                    </li>
                                                </ul>
                                                <Form>
                                                    <Form.Select  size="sm">
                                                        <option>Year to date(YTD)</option>
                                                        <option value="1">All Time</option>
                                                        <option value="2">Two</option>
                                                    </Form.Select>
                                                </Form>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-view-invoices" role="tabpanel" aria-labelledby="pills-view-invoices-tab" tabIndex="0">
                                        <ViewInvoices />
                                    </div>
                                    <div className="tab-pane fade" id="pills-create-invoice" role="tabpanel" aria-labelledby="pills-create-invoice-tab" tabIndex="0">
                                        <CreateInvoice />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
