import React, { useState } from 'react';
import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Button, Table } from 'react-bootstrap';

// Icon
import BalanceLiabilitiesTableData from "./balance-sheet-liabilities-data.json";
import BalanceAssetsTableData from "./balance-sheet-assets-data.json";

// Icons
import Icon from '@mdi/react';
import { mdiPrinter } from '@mdi/js';

export default function OpeningBalanceSheet() {
    const [balanceLiabilitiesData] = useState(BalanceLiabilitiesTableData);
    const [balanceAssetsData] = useState(BalanceAssetsTableData);
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Accounting" breadcrumb2="Reports" breadcrumbLast="Opening Balance Sheet" />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <Card.Title className='mb-0'>Opening Balance Sheet</Card.Title>
                                            <div>
                                                <Button variant="secondary"><Icon path={mdiPrinter} className="btn-icon me-1" /> Print</Button>
                                            </div>
                                        </div>

                                        <Row>
                                            <Col lg={6}>
                                                <div className="d-flex flex-column h-100">
                                                    <Table responsive className='border td-end table-nowrap'>
                                                        <thead className='table-light'>
                                                            <tr>
                                                                <th>Liabilities</th>
                                                                <th>Amount</th>
                                                            </tr>
                                                        </thead>
                                                    </Table>
                                                    <Table responsive className='border td-end table-nowrap mb-0'>
                                                        <thead className='table-light'>
                                                            <tr>
                                                                <th>Capital Account</th>
                                                                <th>8,148,954.12</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {balanceLiabilitiesData.LiabilitiesCapitalAccountData.map((data, i) =>
                                                                <tr key={i}>
                                                                    <td>{data.LiabilitiesCapitalAccount}</td>
                                                                    <td>{data.LiabilitiesCapitalAccountAmount}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                    <Table responsive className='border td-end table-nowrap mb-0'>
                                                        <thead className='table-light'>
                                                            <tr>
                                                                <th>Provisions</th>
                                                                <th>344,002.40</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {balanceLiabilitiesData.LiabilitiesProvisionsData.map((data, i) =>
                                                                <tr key={i}>
                                                                    <td>{data.LiabilitiesProvisions}</td>
                                                                    <td>{data.LiabilitiesProvisionsAmount}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                    <Table responsive className='border td-end table-nowrap mb-0'>
                                                        <thead className='table-light'>
                                                            <tr>
                                                                <th>Secured Loans</th>
                                                                <th>10,591,511.35</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {balanceLiabilitiesData.LiabilitiesSecuredLoansData.map((data, i) =>
                                                                <tr key={i}>
                                                                    <td>{data.LiabilitiesSecuredLoans}</td>
                                                                    <td>{data.LiabilitiesSecuredLoansAmount}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                    <Table responsive className='border td-end table-nowrap'>
                                                        <thead className='table-light'>
                                                            <tr>
                                                                <th>Unsecured Loans</th>
                                                                <th>10,591,511.35</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {balanceLiabilitiesData.LiabilitiesUnsecuredLoansData.map((data, i) =>
                                                                <tr key={i}>
                                                                    <td>{data.LiabilitiesUnsecuredLoans}</td>
                                                                    <td>{data.LiabilitiesUnsecuredLoansAmount}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>

                                                    <div className="mt-auto">
                                                        <Table responsive className='border td-end table-nowrap'>
                                                            <thead className='table-light'>
                                                                <tr>
                                                                    <th>Total Liabilities</th>
                                                                    <th>33,374,188.73</th>
                                                                </tr>
                                                            </thead>
                                                        </Table>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col lg={6} className='mt-5 mt-lg-0'>
                                                <div className="d-flex flex-column h-100">
                                                    <Table responsive className='border td-end table-nowrap'>
                                                        <thead className='table-light'>
                                                            <tr>
                                                                <th>Assets</th>
                                                                <th>Amount</th>
                                                            </tr>
                                                        </thead>
                                                    </Table>
                                                    <Table responsive className='border td-end table-nowrap mb-0'>
                                                        <thead className='table-light'>
                                                            <tr>
                                                                <th>Bank Account</th>
                                                                <th>-6,057,984.19</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {balanceAssetsData.AssetsBankAccountData.map((data, i) =>
                                                                <tr key={i}>
                                                                    <td>{data.AssetsBankAccount}</td>
                                                                    <td>{data.AssetsBankAccountAmount}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                    <Table responsive className='border td-end table-nowrap mb-0'>
                                                        <thead className='table-light'>
                                                            <tr>
                                                                <th>Cash Account</th>
                                                                <th>4,330,452.60</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {balanceAssetsData.AssetsCashAccountData.map((data, i) =>
                                                                <tr key={i}>
                                                                    <td>{data.AssetsCashAccount}</td>
                                                                    <td>{data.AssetsCashAccountAmount}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                    <Table responsive className='border td-end table-nowrap mb-0'>
                                                        <thead className='table-light'>
                                                            <tr>
                                                                <th>Cash Account</th>
                                                                <th>4,330,452.60</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {balanceAssetsData.AssetsDigitalWalletData.map((data, i) =>
                                                                <tr key={i}>
                                                                    <td>{data.AssetsDigitalWallet}</td>
                                                                    <td>{data.AssetsDigitalWalletAmount}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                    <Table responsive className='border td-end table-nowrap'>
                                                        <thead className='table-light'>
                                                            <tr>
                                                                <th>Fixed Assets</th>
                                                                <th>3,825,394.75</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {balanceAssetsData.AssetsFixedAssetsData.map((data, i) =>
                                                                <tr key={i}>
                                                                    <td>{data.AssetsFixedAssets}</td>
                                                                    <td>{data.AssetsFixedAssetsAmount}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>

                                                    <div className="mt-auto">
                                                        <Table responsive className='border td-end table-nowrap'>
                                                            <thead className='table-light'>
                                                                <tr>
                                                                    <th>Opening Stock</th>
                                                                    <th>21,038,259.00</th>
                                                                </tr>
                                                            </thead>
                                                            <tfoot className='table-light'>
                                                                <tr>
                                                                    <th>Total Assets</th>
                                                                    <th>37,128,296.58</th>
                                                                </tr>
                                                            </tfoot>
                                                        </Table>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Table responsive className='border td-end table-nowrap mb-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Total Difference</th>
                                                            <th>3,754,107.85</th>
                                                        </tr>
                                                    </thead>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
