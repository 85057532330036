import React from 'react';
import { Card, Col, Container, Form, Row, Button } from 'react-bootstrap';

import Icon from '@mdi/react';
import { mdiEyeOffOutline, mdiAccountPlusOutline, mdiGoogle, mdiApple } from '@mdi/js';

// logo
import Logo from '../../images/logo.png';
import { Link } from 'react-router-dom';


export default function SignUp() {
  return (
    <>
      <div className="auth-page py-4 py-sm-5 ">
        <Container>
          <Row className='justify-content-center'>
            <Col md={10} xl={8} xxl={6}>
              <img src={Logo} alt="" className='mx-auto d-block' />

              <Card className='auth-card'>
                <Card.Body>
                  <div className='text-center'>
                    <h2 className='mb-3'>Create an Account</h2>
                    <p className='text-muted mb-5 fs-17'>Enter the field below to get started</p>
                  </div>

                  <Form>
                    <Row>
                      <Col sm={6}>
                        <div className="mb-3 custom-form-input">
                          <Form.Label>Your Name</Form.Label>
                          <Form.Control type="text" placeholder="Enter your name..." name='yourName' id='yourName' />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3 custom-form-input">
                          <Form.Label>Business Name</Form.Label>
                          <Form.Control type="text" placeholder="Enter business name..." name='businessName' id='businessName' />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3 custom-form-input">
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control type="email" placeholder="Enter email address..." name='emailAddress' id='emailAddress' />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3 custom-form-input">
                          <Form.Label>Contact Number</Form.Label>
                          <Form.Control type="number" placeholder="(+91) 1234 5678 90" name='contactNumber' id='contactNumber' />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3 custom-form-input">
                          <Form.Label>Password</Form.Label>
                          <Icon path={mdiEyeOffOutline} className="icon-size-18 input-ps-icon" />
                          <Form.Control type="password" placeholder="Enter password..." name='password' id='password' />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3 custom-form-input">
                          <Form.Label>Confirm Password</Form.Label>
                          <Icon path={mdiEyeOffOutline} className="icon-size-18 input-ps-icon" />
                          <Form.Control type="password" placeholder="Enter confirm password..." name='confirmPassword' id='confirmPassword' />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3 custom-form-input">
                          <Form.Label>Address</Form.Label>
                          <Form.Control type="text" placeholder="Enter address..." name='address' id='address' />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3 custom-form-input">
                          <Form.Label>City</Form.Label>
                          <Form.Control type="text" placeholder="Enter city..." name='city' id='city' />
                        </div>
                      </Col>
                      <Col md={12}>
                        {['checkbox'].map((type) => (
                          <div key={`default-${type}`} className="mb-5">
                            <Form.Check
                              type={type}
                              id={`default-Unchecked-${type}`}
                              label={`By clicking Sign Up, you agree to our Terms, Data Policy and Cookie Policy. You may receive SMS notifications 
                              from us and can opt out at any time.`}
                            />
                          </div>
                        ))}
                      </Col>
                    </Row>
                    <Row>
                      <Col className='text-center'>
                        <Button variant="primary btn-lg btn-w-xxl"><Icon path={mdiAccountPlusOutline} className="btn-icon me-1" /> Sign Up</Button>

                        <div className='or-text my-4'>
                          <p className='text-muted or-text-border'>Or sign in with</p>
                        </div>

                        <div className='btn-list'>
                          <Button variant="outline-dark icon-with-btn btn-lg"><Icon path={mdiGoogle} className="icon-size-17 me-1" /> Google</Button>{' '}
                          <Button variant="outline-dark icon-with-btn btn-lg"><Icon path={mdiApple} className="icon-size-17 me-1" /> Apple</Button>{' '}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>

              <div className='text-center mt-4'>
                <p className='mb-0 text-muted'>Already have an account?  <Link to="/" className='text-primary fw-semibold ms-1'>Log In</Link></p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
