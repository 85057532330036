import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
// simplebar css
import "../node_modules/simplebar-react/dist/simplebar.min.css"
// datatable css
import "../node_modules/datatables.net-dt/css/jquery.dataTables.min.css"
import './index.scss';

// datatable
import '../node_modules/datatables.net-dt/css/jquery.dataTables.min.css';
import '../node_modules/datatables.net-dt/js/dataTables.dataTables.min.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
