import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

export default function BulkAdjustment() {
    // datatable
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatablesSBBulkAdjustment')) {
            $('#mainDatatablesSBBulkAdjustment').DataTable({
                dom: "Bflrtip",
                buttons: [
                    {
                        extend: 'pdf',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf'
                    },
                    {
                        extend: 'print',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M19 8C20.66 8 22 9.34 22 11V17H18V21H6V17H2V11C2 9.34 3.34 8 5 8H6V3H18V8H19M8 5V8H16V5H8M16 19V15H8V19H16M18 15H20V11C20 10.45 19.55 10 19 10H5C4.45 10 4 10.45 4 11V15H6V13H18V15M19 11.5C19 12.05 18.55 12.5 18 12.5C17.45 12.5 17 12.05 17 11.5C17 10.95 17.45 10.5 18 10.5C18.55 10.5 19 10.95 19 11.5Z" style="fill: currentcolor;"></path></svg> Export to Print'
                    },
                    {
                        extend: 'excel',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel'
                    }
                ],
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }

        if (document.getElementsByClassName("dt-buttons").length) {
            document.getElementById("mainDatatablesSBBulkAdjustment_wrapper").classList.add("dtl-right");
        } else {
            document.getElementById("mainDatatablesSBBulkAdjustment_wrapper").classList.remove("dtl-right");
        }
    }, []);
    return (
        <>
            <Table responsive bordered className='table-nowrap' id='mainDatatablesSBBulkAdjustment'>
                <thead className='table-light'>
                    <tr>
                        <th>Sr No.</th>
                        <th>Product Id</th>
                        <th>Category</th>
                        <th>Product Name</th>
                        <th>Opening Retail Qty</th>
                        <th>On floor Retail</th>
                        <th>Purchase Stock</th>
                        <th>Stock Adjusted</th>
                        <th>Stock Transfer</th>
                        <th>Sold Stock</th>
                        <th>Challan</th>
                        <th>Adjustment needed</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>120</td>
                        <td>Shoes</td>
                        <td>Shoes</td>
                        <td>28</td>
                        <td>10</td>
                        <td>10</td>
                        <td>10</td>
                        <td>10</td>
                        <td>10</td>
                        <td>203690</td>
                        <td>N/A</td>
                    </tr>
                </tbody>
            </Table>
        </>
    )
}
