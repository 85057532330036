import React from 'react';
import PageTitle from '../layout/PageTitle';
import Footer from '../layout/Footer';
import PurchaseOrder from './PurchaseOrder';
import PurchaseInvoice from './PurchaseInvoice';
import PurchaseReturn from './PurchaseReturn';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { ToastContainer } from "react-toastify";

export default function ManagePurchase() {
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Inventory" breadcrumbLast="Manage Purchase" />

                    <Container>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <div className='custom-top-nav-tab'>
                                            <ul className="nav nav-pills nav-fill btn-list mb-3" id="pills-tab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active" id="pills-purchase-order-tab" data-bs-toggle="pill" data-bs-target="#pills-purchase-order" type="button" role="tab" aria-controls="pills-purchase-order" aria-selected="true">Purchase Order</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-purchase-invoice-tab" data-bs-toggle="pill" data-bs-target="#pills-purchase-invoice" type="button" role="tab" aria-controls="pills-purchase-invoice" aria-selected="false">Purchase Invoice</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-purchase-return-tab" data-bs-toggle="pill" data-bs-target="#pills-purchase-return" type="button" role="tab" aria-controls="pills-purchase-return" aria-selected="false">Purchase Return</button>
                                                </li>
                                            </ul>

                                            <div className="tab-content" id="pills-tabContent">
                                                <div className="tab-pane fade show active" id="pills-purchase-order" role="tabpanel" aria-labelledby="pills-purchase-order-tab" tabIndex="0">
                                                    <PurchaseOrder />
                                                </div>
                                                <div className="tab-pane fade" id="pills-purchase-invoice" role="tabpanel" aria-labelledby="pills-purchase-invoice-tab" tabIndex="0">
                                                    <PurchaseInvoice />
                                                </div>
                                                <div className="tab-pane fade" id="pills-purchase-return" role="tabpanel" aria-labelledby="pills-purchase-return-tab" tabIndex="0">
                                                    <PurchaseReturn />
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>

            <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />
        </>
    )
}
