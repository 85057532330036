import React, { useState } from 'react'
import PageTitle from '../layout/PageTitle';
import Footer from '../layout/Footer';
import { Container, Row, Col, Card } from 'react-bootstrap';

// Icon
import Icon from '@mdi/react';
import { mdiArrowRightThin } from '@mdi/js';
import { Link } from 'react-router-dom';

import ReportsPagesLinkData from "./reports-pages-link-data.json";

export default function ReportsPages() {
    const [reportsPagesLink] = useState(ReportsPagesLinkData);
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumbLast="Reports" />

                    <Container>
                        {reportsPagesLink.map((data, i) =>
                            <Row key={i}>
                                <Col>
                                    <Card>
                                        <Card.Body className='pb-0'>
                                            <Card.Title>{data.cardTitle}</Card.Title>
                                            <Row>
                                                {data.reportListData.map((listdata, j) =>
                                                    <Col md={6} xxl={4} key={j}>
                                                    <Card className='border shadow-none'>
                                                        <Card.Body>
                                                            <Link to={listdata.listLink} className='d-flex justify-content-between align-items-center'>
                                                                <h5 className='fw-normal mb-0 me-3'>{listdata.listId}.{listdata.listName}</h5>
                                                                <Icon path={mdiArrowRightThin} className='icon-size-30 text-dark' />
                                                            </Link>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                )}
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
