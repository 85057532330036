import React, { useState } from 'react';
import { Card, Modal, Form, Row, Col, Button, Table } from 'react-bootstrap';

import ExpenseCategoryData from "./expense-category-data.json";

// Icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';

export default function ViewExpenseCategory() {
    // Default Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // input value
    const [data, setData] = useState({
        expenseCategoryName: "Expense"
    });
    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    // table data
    const [expenseCategoryTableData] = useState(ExpenseCategoryData);
    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <Card.Title className='mb-0'>View Expense Category</Card.Title>
                        <div className='d-flex'>
                            <div className="btn-list">
                                <Button variant="soft-info p-0" onClick={handleShow}>
                                    <div className='avatar avatar-sm'>
                                        <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                    </div>
                                </Button>{' '}
                                <Button variant="soft-danger p-0">
                                    <div className='avatar avatar-sm'>
                                        <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                    </div>
                                </Button>{' '}
                            </div>
                        </div>
                    </div>
                    <Table responsive className='table-lg border table-nowrap mb-0'>
                        <tbody>
                            {expenseCategoryTableData.expenseCategoryTable.map((data, i) =>
                                <tr key={i}>
                                    <th>{data.tableheading1}</th>
                                    <td>{data.tablesubtext1}</td>
                                    <th>{data.tableheading2}</th>
                                    <td>{data.tablesubtext2}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <Modal centered show={show} size="xl" onHide={handleClose}>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-3'>Edit Expense Category</h5>

                    <Form>
                        <Row>
                            <Col sm={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Expense Category Name</Form.Label>
                                    <Form.Control type="text" placeholder='Enter Expense Category Name...' name='expenseCategoryName' id='expenseCategoryName' value={data.expenseCategoryName} onChange={onChange} />
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Expense Description</Form.Label>
                                    <Form.Control as="textarea" placeholder='Type expense description...' rows={4} name='expenseDescription' id='expenseDescription' />
                                </div>
                            </Col>
                        </Row>
                        <div className='text-end btn-list'>
                            <Button variant="primary" onClick={handleClose}>Update</Button>{' '}
                            <Button variant="secondary" onClick={handleClose}>Cancel</Button>{' '}
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
