import React, { useState, useEffect } from 'react';
import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Button, Table, Form } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// table data
import ArsReportTableData from "./ars-report-table-data.json";

export default function AccountReceivableSummaryReport() {
    const [arsReportData] = useState(ArsReportTableData);

    // datatable
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableACARSReport')) {
            $('#mainDatatableACARSReport').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, []);
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Accounting" breadcrumb2="Reports" breadcrumbLast="Account Receivable Summary Report" />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-md-flex justify-content-between align-items-center mb-3">
                                            <Card.Title className='mb-md-0'>Account Receivable Summary Report</Card.Title>
                                            <div className='d-flex align-items-center'>
                                                <Button variant="light custom-form-input-btn me-3">Send reminder SMS to All</Button>
                                                <Form>
                                                    <Form.Select  size="sm">
                                                        <option>All Time</option>
                                                        <option value="1">Select One</option>
                                                        <option value="2">Select Two</option>
                                                    </Form.Select>
                                                </Form>
                                            </div>
                                        </div>

                                        <Table responsive bordered className='table-nowrap' id='mainDatatableACARSReport'>
                                            <thead className='table-light'>
                                                <tr>
                                                    <th>Sr.No</th>
                                                    <th>Customer</th>
                                                    <th>Total Amount</th>
                                                    <th>Due Invoice</th>
                                                    <th>Phone No</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {arsReportData.map((data, i) =>
                                                    <tr key={i}>
                                                        <td>{data.srNo}</td>
                                                        <td>{data.customer}</td>
                                                        <td>{data.totalAmount}</td>
                                                        <td>{data.dueInvoice}</td>
                                                        <td>{data.phoneNo}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
