import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button, InputGroup, DropdownButton, Dropdown, Modal } from 'react-bootstrap';
import { postToAPI, getFromAPI } from '../../Utils/utils';
import { toast, ToastContainer } from 'react-toastify';
import { fetchTaxData, fetchProductCategoryData, fetchUnitData, fetchBrandData, fetchBranchData, fetchVendorData } from '../../Utils/function_list';
// react-select
import Select from 'react-select';
import { CustomerProvider, useProduct } from './getProductData';

// icon
import Icon from '@mdi/react';
import { mdiPlus, mdiChevronDown } from '@mdi/js';

export default function NewProduct() {
    const { customerData, fetchproductData1 } = useProduct();
    const [Tax, setTax] = useState([]);
    const [productData, setProductData] = useState([]);
    const [Brand, setBrand] = useState([]);
    const [Unit, setUnit] = useState([]);
    const [productCategory, setProductCategory] = useState([]);
    const [Branch, setBranch] = useState([]);
    const [vendor, setVendorData] = useState([]);

    // Default Modal
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setproductCategoryInoutData(initialProductData);
    }
    const handleShow = () => setShow(true);
    const [unitShow, setUnitShow] = useState(false)
    const handleUnitClose = () => {
        setUnitShow(false);
        setUnitInputData(initialUnitData);
    }
    const handleUnitShow = () => setUnitShow(true)

    // Todo: set branch product data
    const [branchProductInputdata, setBranchProductInputdata] = useState();

    // Todo: set warehous product data 
    const [warehouseBranchProductInputdata, setWarehouseBranchProductInputdata] = useState();
    const initialInputData = {
        description: '',
        type: '',
        rate: 0.0,
        group_id: 0,
        product_code: '',
        product_category_id: 0,
        vendor_id: 0,
        weight: 1,
        unit_id: 0,
        product_purchase_price: 0.0,
        product_minimum_sell_price: 0.0,
        maximum_retail_price: 0.0,
        product_mu_percentage: 0,
        long_description: "",
        rating_id: 0,
        hsn_code: '',
        brand_id: 0,
        image: '',
        barcode_type: '',
        barcode_number: '',
        license_number: '',
        isservice: 0,
        minimum_consumption: 0.0,
        ismeasurable: 0,
        has_sub_product: 0,
        isproductimported: 0,
        iscoldstorage_require: 0,
        isonline: 0,
        created_branch_id: 1,
        created_user_id: 1,
        last_modified_user_id: 1,
        isdeleted: 0,
        reserve_varchar1: "",
        reserve_varchar2: "",
        reserve_float1: 0.0,
        reserve_float2: 0.0,
        reserve_int1: 0,
        reserve_int2: 0,
        reserve_double1: 0.0
    };

    const initialUnitData = {
        name: '',
        isdeleted: 0
    };

    const initialProductData = {
        name: '',
        description: '',
        created_branch_id: 1,
        created_user_id: 1,
        last_modfied_user_id: 1,
        isdeleted: 0,
    }

    const handleCancel = () => {
        setInputData(initialInputData);
    }
    const [productCategoryInputData, setproductCategoryInoutData] = useState(initialProductData);
    const [inputData, setInputData] = useState(initialInputData);
    const [unitInputData, setUnitInputData] = useState(initialUnitData);

    useEffect(() => {
        fetchTaxData(setTax);
        fetchBranchData(setBranch);
        fetchBrandData(setBrand);
        fetchProductCategoryData(setProductCategory);
        fetchUnitData(setUnit);
        fetchVendorData(setVendorData)
    }, []);

    const handleProductSubmit = async () => {

        // Add proper validation 
        if (!inputData.description.trim()) {
            toast.error('Please enter valid product name', 'error')
            return;
        }

        if (!inputData.product_code.trim()) {
            toast.error('Please enter valid product code', 'error')
            return;
        }
        if(inputData.product_code.length>10)
        {
            toast.error("Please enter vaid Product Code of length 10 :",'error')
            return;
        }

        // TODO: check who is adding product super admin, admin, branch etc.

        try {
            // Post the data to the API
            const response = await postToAPI("FtsItems/", inputData);
            if (response.status) {
                toast.success('Product Added Successfully', 'success');
                fetchproductData1()
                setInputData(initialInputData);
            } else {
                toast.error('Failed to add Product');
            }
        } catch (error) {
            console.error('Error adding Product:', error);
            console.log("Error Message:", error.message);
            toast.error('An unexpected error occurred');
        }
    };

    const handleUnitInputChange = (e) => {
        const { value } = e.target;
        
        // Use regex to allow only numbers
        if (/^\d*$/.test(value)) {
            setUnitInputData((prevData) => ({
                ...prevData,
                name: value,
            }));
        } else {
            toast.error('Only numbers are allowed', 'error');
        }
    };
    

    const handleAddUnit = async () => {
        if(!unitInputData.name.trim()){
            toast.error('Please Enter Add Unit', 'error');
            return;
        }
        try {
            // Post the data to the API
            const response = await postToAPI("FtsUnit/", unitInputData);

            if (response.status) {
                toast.success('Unit Added Successfully', 'success');
                handleUnitClose();
                fetchUnitData();
            } else {
                toast.error('Unit Already Exists!', 'error');
            }
        } catch (error) {
            toast.error('An unexpected error occurred', 'error');
            console.error('Error adding Unit:', error);
            console.log("Error Message:", error.message);
        }
    };

    const handleAddProductCategory = async () => {

        if (!productCategoryInputData.name.trim()) {
            toast.error('Please enter valid Product Category', 'error');
            return;
        }
        if (!productCategoryInputData.description.trim()) {
            toast.error('Please enter valid Product Description', 'error');
            return;
        }
        try {
            // Post the data to the API
            const response = await postToAPI("FtsProductCategory/", productCategoryInputData);

            if (response.status) {
                toast.success('Product Category Added Successfully', 'success');
                handleClose();
                fetchProductCategoryData(setProductCategory);

            } else {
                toast.error('Product Category Added Already Exists!', 'error');
            }
        } catch (error) {
            toast.error('Product Category Added Already Exists!', 'error');
            console.error('Error adding Unit:', error);
            console.log("Error Message:", error.message);
        }
    };

    const handleCheckboxChange = (e) => {
        setInputData({ ...inputData, isonline: e.target.checked });
    };

    // Define the handleSelect function here
    const handleSelect = (discount) => {
        setInputData((prevState) => ({
            ...prevState,
            reserve_int1: discount, // Update with the selected discount in reserve_int1
        }));
    };



    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <Form>
                        <Card.Title>Add Product</Card.Title>

                        <Row>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Product Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='productName'
                                        id='productName'
                                        placeholder="Enter Product Name..."
                                        value={inputData.description}
                                        onChange={e => setInputData({ ...inputData, description: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Product Type</Form.Label>
                                    <Form.Select
                                        
                                        name='productType'
                                        id='productType'
                                        value={inputData.type}
                                        onChange={e => setInputData({ ...inputData, type: e.target.value })}
                                    >
                                        <option>Retail</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} md={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Product Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter code..."
                                        name='productCode'
                                        id='productCode'
                                        value={inputData.product_code}
                                        onChange={e => setInputData({ ...inputData, product_code: e.target.value })}

                                    />
                                </div>
                            </Col>
                            <Col sm={6} md={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>HSN</Form.Label>
                                    <Form.Control type="text"
                                        placeholder="Enter HSN..."
                                        name='productHsn'
                                        id='productHsn'
                                        value={inputData.hsn_code}
                                        onChange={e => setInputData({ ...inputData, hsn_code: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Brand</Form.Label>
                                    <Form.Select
                                        
                                        name='productBrand'
                                        id='productBrand'
                                        value={inputData.brand_id}
                                        onChange={e => setInputData({ ...inputData, brand_id: parseInt(e.target.value) })}>
                                        <option value="">Select Brand</option>
                                        {Brand.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}

                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 d-flex">
                                    <div className='custom-form-input w-100'>
                                        <Form.Label>Product Category</Form.Label>
                                        <Form.Select
                                            
                                            name='productCategory'
                                            id='productCategory'
                                            value={inputData.product_category_id}
                                            onChange={e => setInputData({ ...inputData, product_category_id: parseInt(e.target.value) })}>
                                            <option value="">Select Product Category</option>
                                            {productCategory.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                    </div>

                                    <Button variant="light custom-form-input-btn ms-2 mt-2" onClick={handleShow}>
                                        <Icon path={mdiPlus} size={1} />
                                    </Button>
                                    <Modal centered show={show} onHide={handleClose}>
                                        <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                                        <Modal.Body>
                                            <div className="mb-3 custom-form-input">

                                                <Form.Label>Add Product Category</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter product category..."
                                                    name='addProductCategory'
                                                    id='addProductCategory'
                                                    value={productCategoryInputData.name}
                                                    onChange={e => setproductCategoryInoutData({ ...productCategoryInputData, name: e.target.value })}
                                                />
                                            </div>
                                            <div className="mb-3 custom-form-input">
                                                <Form.Label>Add Product Description</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter product Description..."
                                                    name='addProductDescription'
                                                    id='addProductDescription'
                                                    value={productCategoryInputData.description}
                                                    onChange={e => setproductCategoryInoutData({ ...productCategoryInputData, description: e.target.value })}
                                                />
                                            </div>
                                            <div className='text-end'>
                                                <Button variant="primary" onClick={handleAddProductCategory}>Add Category</Button>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 d-flex">
                                    <div className='custom-form-input w-100'>
                                        <Form.Label>Unit</Form.Label>
                                        <Form.Select
                                            
                                            name='productUnit'
                                            id='productUnit'
                                            value={inputData.unit_id}
                                            onChange={e => setInputData({ ...inputData, unit_id: parseInt(e.target.value) })}>
                                            <option value="">Select Unit</option>
                                            {Unit.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                    </div>

                                    <Button variant="light custom-form-input-btn ms-2 mt-2" onClick={handleUnitShow}>
                                        <Icon path={mdiPlus} size={1} />
                                    </Button>
                                    <Modal centered show={unitShow} onHide={handleUnitClose}>
                                        <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                                        <Modal.Body>
                                            <div className="mb-3 custom-form-input">
                                                <Form.Label>Add Unit</Form.Label>
                                                <Form.Control type="text"
                                                    placeholder="Enter Unit..."
                                                    name='addUnit' id='addUnit'
                                                    value={unitInputData.name}
                                                    onChange={handleUnitInputChange}
                                                />
                                            </div>
                                            <div className='text-end'>
                                                <Button variant="primary" onClick={handleAddUnit}>Add Unit</Button>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alert Qty (Retail)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter Qty..."
                                        name='alertRetail'
                                        id='alertRetail'
                                    />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alert Qty (Consumable)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter Qty..."
                                        name='alertConsumable'
                                        id='alertConsumable'
                                    />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Selling Price</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter price..."
                                        name='sellingPrice'
                                        id='sellingPrice'
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Card.Title>Opening Quantity</Card.Title>
                        <Row>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Consumable</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter consumable..."
                                        name='productConsumable'
                                        id='productConsumable'
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label> Opening Qty (Retail)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter qty..."
                                        name='openingRetail'
                                        id='openingRetail'
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Card.Title>Other Details</Card.Title>
                        <Row>
                            <Col sm={6} xxl={3}>
                                <div className="mb-3 d-flex">
                                    <div className='custom-form-input w-100'>
                                        <Form.Label>Vendors</Form.Label>
                                        <Form.Select
                                            
                                            name='productVendors'
                                            id='productVendors'
                                            value={inputData.vendor_id}
                                            onChange={e => setInputData({ ...inputData, vendor_id: parseInt(e.target.value) })}
                                        >
                                            <option value="">Select Vendor</option>
                                            {vendor.map(item => (
                                                <option key={item.id} value={item.id}>{item.company_name}</option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                    <Button variant="light custom-form-input-btn ms-2 mt-2">
                                        <Icon path={mdiPlus} size={1} />
                                    </Button>
                                </div>
                            </Col>
                            <Col sm={6} xxl={3}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>MRP</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter MRP..."
                                        name='productMrp'
                                        id='productMrp'
                                        value={inputData.maximum_retail_price}
                                        onChange={e => setInputData({ ...inputData, maximum_retail_price: e.target.value === '' ? 0 : parseFloat(e.target.value) })}

                                    />
                                </div>
                            </Col>
                            <Col sm={6} xxl={3}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Cost</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter cost..."
                                        name='procuctCost' id='procuctCost'
                                        value={inputData.product_purchase_price}
                                        onChange={e => setInputData({ ...inputData, product_purchase_price: e.target.value === '' ? 0 : parseFloat(e.target.value) })}

                                    />
                                </div>
                            </Col>
                            <Col sm={6} xxl={3}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>MU</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter MU..."
                                        name='productMu'
                                        id='productMu'
                                        value={inputData.product_mu_percentage}
                                        onChange={e => setInputData({ ...inputData, product_mu_percentage: e.target.value === '' ? 0 : parseInt(e.target.value) })}
                                    />
                                </div>
                            </Col>
                          <Col md={4}>
                                <InputGroup className="mb-3 custom-form-input">
                                    <Form.Control
                                        placeholder="Enter discount..."
                                        name="reserve_int1"
                                        id="reserve_int1"
                                        value={inputData.reserve_int1} // Bind the value from state (reserve_int1)
                                        readOnly // Making it read-only so it only updates via dropdown
                                    />
                                    <Form.Label>Discount</Form.Label>
                                    <DropdownButton
                                        variant="light"
                                        title={
                                            <>
                                                {inputData.reserve_int1 ? `${inputData.reserve_int1}% Off` : '% Off'} {/* Show selected discount */}
                                                <Icon path={mdiChevronDown} className="drop-arrow ms-1 icon-size-15" />
                                            </>
                                        }
                                        id="input-group-dropdown-2"
                                        align="end"
                                        onSelect={handleSelect} // Trigger when a dropdown item is selected
                                    >
                                        <Dropdown.Item eventKey="10">10% Off</Dropdown.Item>
                                        <Dropdown.Item eventKey="20">20% Off</Dropdown.Item>
                                        <Dropdown.Item eventKey="30">30% Off</Dropdown.Item>
                                        <Dropdown.Item eventKey="40">40% Off</Dropdown.Item>
                                        <Dropdown.Item eventKey="50">50% Off</Dropdown.Item>
                                    </DropdownButton>
                                </InputGroup>
                            </Col>
                            <Col sm={6} md={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Select
                                        
                                        name='productTax'
                                        id='productTax'
                                    >
                                        <option value="">None</option>
                                        {Tax.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}

                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} md={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax type</Form.Label>
                                    <Form.Select
                                        
                                        name='otherDetails'
                                        id='otherDetails'>
                                        <option>Inclusive</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label className='z-1'>Branch</Form.Label>
                                    <Select name='productBranch' id='productBranch' isMulti
                                        options={Branch.map((item) => ({
                                            value: item.id,
                                            label: item.name,
                                        }))} />

                                </div>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Enter description..."
                                        name='productDescription'
                                        id='productDescription'
                                        value={inputData.long_description}
                                        onChange={e => setInputData({ ...inputData, long_description: e.target.value })}

                                    />
                                </div>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check
                                        type="checkbox"
                                        label="Is Online"
                                        name='productOnline'
                                        id='productOnline'
                                        value={inputData.isonline}
                                        onChange={handleCheckboxChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <div className="btn-list text-end">
                                    <Button variant="primary" type="button" onClick={handleProductSubmit}>Add Product</Button>
                                    <Button variant="secondary" type="button" onClick={handleCancel}>Cancel</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>

                </Card.Body>
            </Card>
        </>
    )
}
