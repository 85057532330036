import React, { useState, useEffect } from 'react';
import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Button, Form, Table } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

import BalanceReportTableData from "./balance-report-table-data.json";

// react-select
import Select from 'react-select';

import Icon from '@mdi/react';
import { mdiContentSaveAll } from '@mdi/js';

export default function OpeningBalanceReport() {
    // multi-select
    const [value, setValue] = useState(null)
    const options = [
        { value: "All Branch", label: "All Branch" },
        { value: "NSF Adajan", label: "NSF Adajan" },
        { value: "NFS Parle Point", label: "NFS Parle Point" },
        { value: "NFS Uniform", label: "NFS Uniform" },
        { value: "NFS Warehouse", label: "NFS Warehouse" },
    ]

    // datatable
    const [balanceReportData] = useState(BalanceReportTableData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableACOBReport')) {
            $('#mainDatatableACOBReport').DataTable({
                dom: "Bflrtip",
                buttons: [
                    {
                        extend: 'pdf',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf'
                    },
                    {
                        extend: 'print',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M19 8C20.66 8 22 9.34 22 11V17H18V21H6V17H2V11C2 9.34 3.34 8 5 8H6V3H18V8H19M8 5V8H16V5H8M16 19V15H8V19H16M18 15H20V11C20 10.45 19.55 10 19 10H5C4.45 10 4 10.45 4 11V15H6V13H18V15M19 11.5C19 12.05 18.55 12.5 18 12.5C17.45 12.5 17 12.05 17 11.5C17 10.95 17.45 10.5 18 10.5C18.55 10.5 19 10.95 19 11.5Z" style="fill: currentcolor;"></path></svg> Export to Print'
                    }
                ],
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }

        if (document.getElementsByClassName("dt-buttons").length) {
            document.getElementById("mainDatatableACOBReport_wrapper").classList.add("dtl-right");
        } else {
            document.getElementById("mainDatatableACOBReport_wrapper").classList.remove("dtl-right");
        }
    }, []);

    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Accounting" breadcrumb2="Reports" breadcrumbLast="Opening Balance Report" />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-sm-flex justify-content-between align-items-center mb-3">
                                            <Card.Title className='mb-sm-0'>Opening Balance Report</Card.Title>
                                            <div>
                                                <Button variant="light custom-form-input-btn">Export to excel</Button>
                                            </div>
                                        </div>

                                        <Form>
                                            <Row>
                                                <Col md={7} lg={8}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label className='z-1'>Branch</Form.Label>
                                                        <Select options={options} defaultValue={value} placeholder="Select Branch..." name='productBranch' id='productBranch' onChange={setValue} isMulti></Select>
                                                    </div>
                                                </Col>
                                                <Col md={5} lg={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Select Opening Balance</Form.Label>
                                                        <Form.Select  name='selectOpeningBalance' id='selectOpeningBalance'>
                                                            <option>Account</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>

                                        <Card className='border shadow-none mb-0'>
                                            <Card.Body>
                                                <Card.Title>Balance Report</Card.Title>

                                                <Table responsive bordered className='table-nowrap' id='mainDatatableACOBReport'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>
                                                                <Form>
                                                                    {['checkbox'].map((type) => (
                                                                        <div key={`default-${type}`}>
                                                                            <Form.Check
                                                                                type={type}
                                                                                id={`balance-report-${type}`}
                                                                                label={`Save All`}
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </Form>
                                                            </th>
                                                            <th>Sr No.</th>
                                                            <th>Id</th>
                                                            <th>Name</th>
                                                            <th>Branch Name</th>
                                                            <th>Account Category</th>
                                                            <th>Account Nature</th>
                                                            <th>Opening Balance</th>
                                                            <th>Edit</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {balanceReportData.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>
                                                                    <Form>
                                                                        {['checkbox'].map((type) => (
                                                                            <div key={`default-${type}`}>
                                                                                <Form.Check
                                                                                    type={type}
                                                                                    id={`balance-report-${type}`}
                                                                                />
                                                                            </div>
                                                                        ))}
                                                                    </Form>
                                                                </td>
                                                                <td>{data.srNo}</td>
                                                                <td>{data.balanceReportId}</td>
                                                                <td>{data.name}</td>
                                                                <td>{data.branchName}</td>
                                                                <td>{data.accountCategory}</td>
                                                                <td>{data.accountNature}</td>
                                                                <td>{data.openingBalance}</td>
                                                                <td className='py-1'>
                                                                    <Form className='max-sm-w'>
                                                                        <Form.Control type="text" placeholder="" name='BalanceReportTableInput' id='BalanceReportTableInput' size='xs' />
                                                                    </Form>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                                <div className="text-end mt-3">
                                                    <Button variant="primary"><Icon path={mdiContentSaveAll} className="btn-icon me-1" /> Save All</Button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
