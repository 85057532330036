import React from 'react';
import PageTitle from '../layout/PageTitle';
import Footer from '../layout/Footer';
import Paid from './Paid';
import Received from './Received';
import TransferFunds from './TransferFunds';
import GstExpense from './GstExpense';
import { Card, Col, Container, Row } from 'react-bootstrap';

export default function BankCash() {
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumbLast="Bank/Cash" />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-view-vendor" role="tabpanel" aria-labelledby="pills-view-vendor-tab" tabIndex="0">
                                                <ul className="nav nav-pills nav-fill btn-list mb-3" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="pills-paid-tab" data-bs-toggle="pill" data-bs-target="#pills-paid" type="button" role="tab" aria-controls="pills-paid" aria-selected="true">Paid</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-received-tab" data-bs-toggle="pill" data-bs-target="#pills-received" type="button" role="tab" aria-controls="pills-received" aria-selected="false">Received</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-transfer-funds-tab" data-bs-toggle="pill" data-bs-target="#pills-transfer-funds" type="button" role="tab" aria-controls="pills-transfer-funds" aria-selected="false">Transfer Funds/JV</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-gst-expense-tab" data-bs-toggle="pill" data-bs-target="#pills-gst-expense" type="button" role="tab" aria-controls="pills-gst-expense" aria-selected="false">GST Expense</button>
                                                    </li>
                                                </ul>

                                                <div className="tab-content" id="pills-tabContent">
                                                    <div className="tab-pane fade show active" id="pills-paid" role="tabpanel" aria-labelledby="pills-paid-tab" tabIndex="0">
                                                        <Paid />
                                                    </div>
                                                    <div className="tab-pane fade" id="pills-received" role="tabpanel" aria-labelledby="pills-received-tab" tabIndex="0">
                                                        <Received />
                                                    </div>
                                                    <div className="tab-pane fade" id="pills-transfer-funds" role="tabpanel" aria-labelledby="pills-transfer-funds-tab" tabIndex="0">
                                                        <TransferFunds />
                                                    </div>
                                                    <div className="tab-pane fade" id="pills-gst-expense" role="tabpanel" aria-labelledby="pills-gst-expense-tab" tabIndex="0">
                                                        <GstExpense />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
