import React, { useEffect } from 'react';
import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Button, Form, Table, Badge } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// Icons
import Icon from '@mdi/react';
import { mdiFilterCog, mdiFilterOff } from '@mdi/js';

export default function Calendar() {
    // datatable
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableACCalendar')) {
            $('#mainDatatableACCalendar').DataTable({
                dom: "Bflrtip",
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel'
                    }
                ],
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }

        if (document.getElementsByClassName("dt-buttons").length) {
            document.getElementById("mainDatatableACCalendar_wrapper").classList.add("dtl-right");
        } else {
            document.getElementById("mainDatatableACCalendar_wrapper").classList.remove("dtl-right");
        }
    }, []);
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Accounting" breadcrumb2="Reports" breadcrumbLast="Calendar" />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-sm-flex justify-content-between align-items-center mb-3">
                                            <Card.Title className='mb-sm-0'>Calendar Report</Card.Title>

                                            <div className="btn-list">
                                                <Button variant="primary"><Icon path={mdiFilterCog} className="btn-icon me-1" /> Filter</Button>{' '}
                                                <Button variant="outline-dark"><Icon path={mdiFilterOff} className="btn-icon me-1" /> Clear Filters</Button>{' '}
                                            </div>
                                        </div>

                                        <Form>
                                            <Row>
                                                <Col sm={6} xl={3}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Select Providers</Form.Label>
                                                        <Form.Select  name='selectProviders' id='selectProviders'>
                                                            <option>All</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col sm={6} xl={3}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Select Main Status</Form.Label>
                                                        <Form.Select  name='selectMainStatus' id='selectMainStatus'>
                                                            <option>All</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col sm={6} xl={3}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Select Child Status</Form.Label>
                                                        <Form.Select  name='selectChildStatus' id='selectChildStatus'>
                                                            <option>All</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col sm={6} xl={3}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Select Category</Form.Label>
                                                        <Form.Select  name='selectCategory' id='selectCategory'>
                                                            <option>All</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={6} lg={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Select Services</Form.Label>
                                                        <Form.Select  name='selectServices' id='selectServices'>
                                                            <option>All</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={6} lg={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Select Gender</Form.Label>
                                                        <Form.Select  name='selectGender' id='selectGender'>
                                                            <option>All</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Customer Name</Form.Label>
                                                        <Form.Select  name='customerName' id='customerName'>
                                                            <option>Select Customer Name</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>

                                        <Card className='border shadow-none mb-0'>
                                            <Card.Body>
                                                <Card.Title>Transaction Data Info</Card.Title>

                                                <Table responsive bordered className='table-nowrap' id='mainDatatableACCalendar'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Customer</th>
                                                            <th>Gender</th>
                                                            <th>Date</th>
                                                            <th>Category</th>
                                                            <th>Service</th>
                                                            <th>Provider Name</th>
                                                            <th>Status</th>
                                                            <th>Reason</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Hiram G. Salazar</td>
                                                            <td>Male</td>
                                                            <td>28/12/2022</td>
                                                            <td>Invoice</td>
                                                            <td>Service</td>
                                                            <td>Patel Mall</td>
                                                            <td> <Badge bg="soft-success">Delivered</Badge></td>
                                                            <td>Service</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
