import React, { useState, useEffect } from 'react';
import { Card, Table, Button, Modal } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import { getFromAPI, putToAPI, deleteFromAPI, showAlert } from '../../Utils/utils';
import { toast } from 'react-toastify'; // Ensure react-toastify is installed

export default function EditAccount({ selectedAccount, onAccountUpdated, accounts }) {
    const [accountData, setAccountData] = useState(selectedAccount || {});
    const [showEditModal, setShowEditModal] = useState(false);
    const [accountInputData, setAccountInputData] = useState({
        name: '',
        holder_name: '',
        category_id: 0,
        account_number: '',
        opening_balance: 0,
        current_balance: 0,
        account_type: 0,
        account_nature: 0,
        description: '',
        account_across_branch: 0,
        share_of_profit: '',
        budget: '',
        acc_gst_number: '',
    });

    const [editAccountID, setEditAccountID] = useState(null);

    // Function to fetch updated table data
    const fetchTableData = async () => {
        try {
            const data = await getFromAPI('FtsAccount/');
            onAccountUpdated(data); // Pass the updated data back to the parent component
        } catch (error) {
            console.error('Error fetching updated table data:', error);
        }
    };

    // Fetch account details when selectedAccount changes
    useEffect(() => {
            if (selectedAccount && selectedAccount.id) {
                    setAccountInputData({
                        name: selectedAccount.name || '',
                        holder_name: selectedAccount.holder_name || '',
                        category_id: selectedAccount.category_id || 0,
                        account_number: selectedAccount.account_number || '',
                        opening_balance: selectedAccount.opening_balance || 0,
                        current_balance: selectedAccount.current_balance || 0,
                        account_type: selectedAccount.account_type || 0,
                        account_nature: selectedAccount.account_nature || 0,
                        description: selectedAccount.description || '',
                        account_across_branch: selectedAccount.account_across_branch || 0,
                        share_of_profit: selectedAccount.share_of_profit || '',
                        budget: selectedAccount.budget || '',
                        acc_gst_number: selectedAccount.acc_gst_number || '',
                    });
                    setEditAccountID(selectedAccount.id);  // Set the ID for editing
                }
    }, [selectedAccount]);

    const handleSave = async () => {
        if (!editAccountID) {
            toast.error("Failed to save account. No account ID.");
            return;
        }

        try {
            const response = await putToAPI(`FtsAccount/${editAccountID}/`, accountInputData);
            if (response.status === true) {
                toast.success('Account successfully updated!');
                fetchTableData(); // Refresh the table data after updating
                setShowEditModal(false); // Close the modal after saving changes
            } else {
                toast.error('Failed to update account.');
            }
        } catch (error) {
            console.error("Error updating account:", error);
            toast.error("An error occurred while updating the account.");
        }
    };

    const handleDelete = async () => {
        showAlert('Are you sure you want to delete this Customer?', 'confirm', async (result) => {
            if (result) { // If the user confirms the deletion
                try {
                    const response = await deleteFromAPI(`FtsAccount/${selectedAccount.id}/`);
                    if (response.status === true) {
                        toast.success("Account deleted successfully!");
                        fetchTableData(); // Fetch updated account list after deletion
                        // Automatically select the first account in the list, or set to null if no accounts are left
                        if (accounts && accounts.length > 1) {
                            const firstAccount = accounts.find(account => account.id !== selectedAccount.id);
                            onAccountUpdated(firstAccount); // Update the selected account to the next available account
                        } else {
                            onAccountUpdated(null); // No accounts left
                        }
                    } else {
                        toast.error("Failed to delete account.");
                    }
                } catch (error) {
                    console.error("Error deleting account:", error);
                    toast.error("An error occurred while deleting the account.");
                }
            }
        });
    };

    const handleOpenAccountModal = () => {
        setShowEditModal(true);
    };

    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <Card.Title className='mb-0'>
                            {selectedAccount ? selectedAccount.name : 'Select an account'}
                        </Card.Title>
                        <div className='d-flex'>
                            <div className="btn-list">
                                <Button variant="soft-info p-0" onClick={handleOpenAccountModal}>
                                    <div className='avatar avatar-sm'>
                                        <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                    </div>
                                </Button>
                                <Button variant="soft-danger p-0" onClick={handleDelete}>
                                    <div className='avatar avatar-sm'>
                                        <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>

                    {selectedAccount ? (
                        <Table responsive className='table-lg border table-nowrap mb-0'>
                            <tbody>
                                {Object.entries(accountInputData).map(([key, value]) => (
                                    <tr key={key}>
                                        <th>{key.replace(/_/g, ' ').toUpperCase()}</th>
                                        <td>{value || 'N/A'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div>No account selected</div>
                    )}
                </Card.Body>
            </Card>

            {/* Edit Account Modal */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column">
                        {Object.entries(accountInputData).map(([key, value]) => (
                            <div className='mb-3' key={key}>
                                <label htmlFor={key} className='form-label'>{key.replace(/_/g, ' ').toUpperCase()}</label>
                                <input
                                    type={typeof value === 'number' ? 'number' : 'text'}
                                    className='form-control'
                                    id={key}
                                    value={value || ''}
                                    onChange={e => setAccountInputData({ ...accountInputData, [key]: e.target.value })}
                                />
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSave}>Save Changes</Button>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}
