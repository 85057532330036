import React, { useState, useEffect } from 'react';
import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import ViewTax from './ViewTax';
import { Container, Row, Col, Card, Form, Nav, Button } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";

import GSTConfigurationData from "./gst-configuration-data.json";

// Icons
import Icon from '@mdi/react';
import { mdiFormatListBulletedSquare, mdiPlus, mdiMagnify } from '@mdi/js';

export default function GSTConfiguration() {
    // left navlink active
    useEffect(() => {
        var a = document.getElementsByClassName("left-link-item");
        for (var i = 0; i < a.length; i++) {
            a[i].onclick = function () {
                var b = document.querySelector(".left-link-item a.active");
                if (b) b.classList.remove("active");
                this.children[0].classList.add('active');
            };
        }
    }, []);
    const [gstConfigurationListData] = useState(GSTConfigurationData);

    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Settings" breadcrumbLast="GST Configuration" />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className='d-md-flex justify-content-between align-items-center'>
                                            <Form className='custom-form-width'>
                                                <div className='custom-search-bar'>
                                                    <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                    <Form.Control className='' type="text" placeholder="Search GST..." name='gstSearch' id='gstSearch' />
                                                </div>
                                            </Form>
                                            <div className="custom-top-nav-tab mt-3 mt-md-0">
                                                <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="pills-view-tax-tab" data-bs-toggle="pill" data-bs-target="#pills-view-tax" type="button" role="tab" aria-controls="pills-view-tax" aria-selected="true">
                                                            <Icon path={mdiFormatListBulletedSquare} className="btn-icon me-1" />View Tax
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-new-tax-tab" data-bs-toggle="pill" data-bs-target="#pills-new-tax" type="button" role="tab" aria-controls="pills-new-tax" aria-selected="false">
                                                            <Icon path={mdiPlus} className="btn-icon me-1" />New Tax
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={4} xxl={3}>
                                <Card className='procust-left-list leftbar-list'>
                                    <SimpleBarReact>
                                        <Card.Body>
                                            <div className='left-list-bar'>
                                                {gstConfigurationListData.gstConfigurationList.map((data, i) =>
                                                    <Nav.Item className='left-link-item' key={i}>
                                                        <Nav.Link className={i === 0 ? "active" : ""}>
                                                            <h5 className='product-tab-title'>{data.gstConfigurationLink}</h5>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                )}
                                            </div>
                                        </Card.Body>
                                    </SimpleBarReact>
                                </Card>
                            </Col>
                            <Col lg={8} xxl={9}>

                                <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-view-tax" role="tabpanel" aria-labelledby="pills-view-tax-tab" tabIndex="0">
                                        <ViewTax />
                                    </div>
                                    <div className="tab-pane fade" id="pills-new-tax" role="tabpanel" aria-labelledby="pills-new-tax-tab" tabIndex="0">
                                        <Card className='right-content-details'>
                                            <Card.Body>
                                                <Card.Title>New Tax</Card.Title>
                                                <Form>
                                                    <Row className='align-items-center'>
                                                        <Col xl={8}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Tax Name</Form.Label>
                                                                <Form.Control type="text" placeholder='Enter tax name...' name='taxName' id='taxName' />
                                                            </div>
                                                        </Col>
                                                        <Col xl={4}>
                                                            <ul className='list-inline text-xl-center my-3'>
                                                                <li className='list-inline-item me-4'>
                                                                    {['radio'].map((type) => (
                                                                        <div key={`simple-tax-${type}`}>
                                                                            <Form.Check
                                                                                type={type}
                                                                                name="Tax"
                                                                                id={`simple-tax-check-${type}`}
                                                                                label={`Simple Tax`}
                                                                                defaultChecked={true}
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </li>
                                                                <li className='list-inline-item'>
                                                                    {['radio'].map((type) => (
                                                                        <div key={`combine-tax-${type}`}>
                                                                            <Form.Check
                                                                                type={type}
                                                                                name="Tax"
                                                                                id={`combine-tax-check-${type}`}
                                                                                label={`Combine Tax`}
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </li>
                                                            </ul>
                                                        </Col>
                                                        <Col xl={4}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Tax Rate</Form.Label>
                                                                <Form.Control type="text" placeholder='Enter tax rate...' name='taxRate' id='taxRate' />
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={4}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Tax Rate 1</Form.Label>
                                                                <Form.Select  name='enterTaxRate1' id='enterTaxRate1' >
                                                                    <option>Enter tax rate 1</option>
                                                                    <option value="1">One</option>
                                                                    <option value="2">Two</option>
                                                                </Form.Select>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={4}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Tax Rate 2</Form.Label>
                                                                <Form.Select  name='enterTaxRate1' id='enterTaxRate1' >
                                                                    <option>Enter tax rate 2</option>
                                                                    <option value="1">One</option>
                                                                    <option value="2">Two</option>
                                                                </Form.Select>
                                                            </div>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <ul className='list-inline mb-3'>
                                                                <li className='list-inline-item me-4'>
                                                                    {['radio'].map((type) => (
                                                                        <div key={`inclusive-${type}`}>
                                                                            <Form.Check
                                                                                type={type}
                                                                                name="Tax1"
                                                                                id={`inclusive-check-${type}`}
                                                                                label={`Inclusive`}
                                                                                defaultChecked={true}
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </li>
                                                                <li className='list-inline-item'>
                                                                    {['radio'].map((type) => (
                                                                        <div key={`exclusive-${type}`}>
                                                                            <Form.Check
                                                                                type={type}
                                                                                name="Tax1"
                                                                                id={`exclusive-check-${type}`}
                                                                                label={`Exclusive`}
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </li>
                                                            </ul>

                                                            {['checkbox'].map((type) => (
                                                                <div key={`default-${type}`} className="mb-3">
                                                                    <Form.Check
                                                                        type={type}
                                                                        id={`default-Unchecked-${type}`}
                                                                        label={`Set this tax as default tax. It will used by default for business.`}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Card.Body>
                                            <div className='custom-card-footer'>
                                                <div className='text-end btn-list'>
                                                    <Button variant="primary">Add</Button>{' '}
                                                    <Button variant="secondary">Cancel</Button>{' '}
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
