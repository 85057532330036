import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row, Table, Button } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// icon
import Icon from '@mdi/react';
import { mdiPlus, mdiFileDocumentOutline } from '@mdi/js';

// GstExpense table-data
import NewGstExpenseTableData from "./new-gst-expense-table-data.json";

export default function GstExpense() {
    // datatable
    const [newGstExpenseData] = useState(NewGstExpenseTableData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableBankGstExpense')) {
            $('#mainDatatableBankGstExpense').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, []);

    // Change card title
    const handleClick = event => {
        var buttonID = event.currentTarget.id;
        var button = document.getElementById(buttonID);
        var cardtitle = document.getElementById('cardTitle');
        var buttonText = button.textContent;
        cardtitle.innerHTML = buttonText;
    };
    return (
        <>
            <div className='d-sm-flex justify-content-between align-items-center mb-3'>
                <Card.Title className='mb-0' id="cardTitle">View GST Expense</Card.Title>
                <div className="custom-top-nav-tab d-flex mt-3 mt-sm-0">
                    <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" onClick={handleClick} data-text="View GST Expense" id="pills-view-gst-expense-tab" data-bs-toggle="pill" data-bs-target="#pills-view-gst-expense" type="button" role="tab" aria-controls="pills-view-gst-expense" aria-selected="true">
                                <Icon path={mdiFileDocumentOutline} className="btn-icon me-1" />View GST Expense
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" onClick={handleClick} data-text="New GST Expense" id="pills-new-gst-expense-tab" data-bs-toggle="pill" data-bs-target="#pills-new-gst-expense" type="button" role="tab" aria-controls="pills-new-gst-expense" aria-selected="false">
                                <Icon path={mdiPlus} className="btn-icon me-1" />New GST Expense
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-view-gst-expense" role="tabpanel" aria-labelledby="pills-view-gst-expense-tab" tabIndex="0">
                    <Row className='justify-content-center'>
                        <Col sm={6} lg={4} xxl={3}>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total Row : 0</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6} lg={4} xxl={3}>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total Amt : 0</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6} lg={4} xxl={3}>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total Taxable Amt : 0</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6} lg={4} xxl={3}>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total Tax : 0</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6} lg={4} xxl={3}>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total Gst : 0</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6} lg={4} xxl={3}>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total IGst : 0</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6} lg={4} xxl={3}>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total SGst : 0</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6} lg={4} xxl={3}>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total CGst : 0</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Card.Title className='mt-4'>Without Tax Data :-</Card.Title>
                    <Row className='justify-content-center'>
                        <Col sm={6} lg={4} xxl={3}>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total Taxable Amt : 0</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6} lg={4} xxl={3}>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total Tax : 0</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6} lg={4} xxl={3}>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total Gst : 0</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6} lg={4} xxl={3}>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total IGst : 0</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6} lg={4} xxl={3}>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total SGst : 0</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6} lg={4} xxl={3}>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total CGst : 0</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Table responsive bordered className='table-nowrap mt-4' id='mainDatatableBankGstExpense'>
                        <thead className='table-light'>
                            <tr>
                                <th>Sr.No</th>
                                <th>Doc No.</th>
                                <th>Date</th>
                                <th>Party</th>
                                <th>GST number</th>
                                <th>Account</th>
                                <th>Expense</th>
                                <th>SGST</th>
                                <th>CGST</th>
                                <th>IGST</th>
                                <th>Total Taxable</th>
                                <th>Total Amount</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>01</td>
                                <td>2364</td>
                                <td>28/12/2022</td>
                                <td>Adajan</td>
                                <td>#1234567898745</td>
                                <td>Transpotion</td>
                                <td>Due</td>
                                <td>0</td>
                                <td>0</td>
                                <td>90</td>
                                <td>500</td>
                                <td>590</td>
                                <td className='py-1'>
                                    <Button type='button' variant='light btn-sm'>View Details</Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <div className="tab-pane fade" id="pills-new-gst-expense" role="tabpanel" aria-labelledby="pills-new-gst-expense-tab" tabIndex="0">
                    <Form>
                        <Row>
                            <Col sm={6} xl={4}>
                                <div className="mb-3">
                                    <Form.Control type="text" placeholder="18" name='expenseNumber' id='expenseNumber' />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3">
                                    <Form.Control type="date" placeholder="18" name='expenseDate' id='expenseDate' />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3">
                                    <Form.Select  name='expenseDue' id='expenseDue'>
                                        <option>Due</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3">
                                    <Form.Control type="text" placeholder="Enter amount..." name='expenseAmount' id='expenseAmount' />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3">
                                    <Form.Control type="text" placeholder="Enter expense..." name='expense' id='expense' />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3">
                                    <Form.Control type="text" placeholder="Search name..." name='expenseName' id='expenseName' />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3">
                                    <Form.Select  name='expenseShow' id='expenseShow'>
                                        <option>None</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3">
                                    <Form.Control type="text" placeholder="Bill No." name='expenseBill' id='expenseBill' />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3">
                                    <Form.Control type="text" placeholder="GST No." name='gstNo' id='gstNo' />
                                </div>
                            </Col>
                        </Row>
                    </Form>

                    <Table responsive bordered className='table-nowrap'>
                        <thead className='table-light'>
                            <tr>
                                <th>Sr.No</th>
                                <th>HSN / SAC</th>
                                <th>Details</th>
                                <th>Taxable</th>
                                <th>C%</th>
                                <th>CGST</th>
                                <th>S%</th>
                                <th>SGST</th>
                                <th>I%</th>
                                <th>IGST</th>
                                <th>Cs%</th>
                                <th>CESS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {newGstExpenseData.map((data, i) =>
                                <tr key={i}>
                                    <td>{data.srNo}</td>
                                    <td>{data.hsnSac}</td>
                                    <td>{data.details}</td>
                                    <td>{data.taxable}</td>
                                    <td>{data.c}</td>
                                    <td>{data.cgst}</td>
                                    <td>{data.s}</td>
                                    <td>{data.sgst}</td>
                                    <td>{data.i}</td>
                                    <td>{data.igst}</td>
                                    <td>{data.cs}</td>
                                    <td>{data.cess}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <Button variant="light btn-sm mb-4"><Icon path={mdiPlus} className="btn-icon me-1" />Add New Row</Button>

                    <Form>
                        <Row>
                            <Col sm={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control type="text" placeholder="Enter description..." name='description' id='description' />
                                </div>
                            </Col>
                            <Col sm={6} xl={3}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Taxable</Form.Label>
                                    <Form.Control type="text" placeholder="Enter taxable..." name='taxable' id='taxable' />
                                </div>
                            </Col>
                            <Col sm={6} xl={3}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Sgst</Form.Label>
                                    <Form.Control type="text" placeholder="Enter sgst..." name='sgst' id='sgst' />
                                </div>
                            </Col>
                            <Col sm={6} xl={3}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Cgst</Form.Label>
                                    <Form.Control type="text" placeholder="Enter cgst..." name='cgst' id='cgst' />
                                </div>
                            </Col>
                            <Col sm={6} xl={3}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Igst</Form.Label>
                                    <Form.Control type="text" placeholder="Enter igst..." name='igst' id='igst' />
                                </div>
                            </Col>
                            <Col xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Cess</Form.Label>
                                    <Form.Control type="text" placeholder="Enter cess..." name='cess' id='cess' />
                                </div>
                            </Col>
                            <Col xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>R.off/other_change</Form.Label>
                                    <Form.Control type="text" placeholder="Enter r.off/other_change..." name='rOffOtherChange' id='rOffOtherChange' />
                                </div>
                            </Col>
                            <Col xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Voucher total</Form.Label>
                                    <Form.Control type="text" placeholder="Enter voucher total..." name='voucherTotal' id='voucherTotal' />
                                </div>
                            </Col>
                            <Col sm={12}>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" label="Without Tax Entry" name='productOnline' id='productOnline' />
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <div className="btn-list text-end">
                                    <Button variant="primary">Generate and New</Button>{' '}
                                    <Button variant="success">Generate</Button>{' '}
                                    <Button variant="secondary">Cancel</Button>{' '}
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}
