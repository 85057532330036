import React, { useState, useEffect, useContext } from 'react';
import { Table, Card, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { getFromAPI, deleteFromAPI, showAlert, putToAPI } from '../../Utils/utils';
import { toast, ToastContainer } from "react-toastify";
import { CustomerContext1 } from './vendorContext';
import { useCustomer, CustomerProvider } from './getVendor'; // Import the useCustomer hook

// icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline, mdiPhoneInTalk, mdiMessageProcessing, mdiEmail, mdiNoteMultipleOutline } from '@mdi/js';


export default function Details() {
    const selectedCustomerId = useContext(CustomerContext1);
    const { fetchCustomerData } = useCustomer();
    const [showEditModal, setShowEditModal] = useState(false);
    const [stateName, setStateName] = useState();
    const [State, setState] = useState([]);
    //const [stateCode, setStateCode] = useState();
    const [city, setCity] = useState([]);
    const [Tax, setTax1] = useState([]);
    const [phoneShow, setPhoneShow] = useState(false);
    const [msgShow, setMsgShow] = useState(false);
    const [cityName, setCityName] = useState();
    const [emailShow, setEmailShow] = useState(false);
    const [tableData, setTableData] = useState([]); // Initialize state with an empty array
    const [contactPerson, setContactPerson] = useState();
    const [mobileNo, setMobileNo] = useState();
    const [email, setEmail] = useState();
    const [website, setWebsite] = useState();
    const [address, setAddress] = useState();
    const [Country, setCountry] = useState();
    const [tax, setTax] = useState();
    const [gst, setGst] = useState();
    const [pan, setPan] = useState();
    const [openingBalance, setOpeningBalance] = useState();
    const [Alternate, setAlternate] = useState();
    const [bank, setBank] = useState();
    const [IFSC, setIFSC] = useState();
    const [AccNo, setAccNo] = useState();
    const [Bank, setBank1] = useState([]);
    const [country, setCountry1] = useState([]);
    const handleCloseModal = () => {
        setShowEditModal(false);
    };
    const [inputData,setInputData] = useState({
        name : '',
        company_name : null,
        phone_number1 : '',
        email : '',
        gst_number : null,
        country : 0,
        state : 0,
        city : 0,
        tax : 0,
        pan_card_number : null,
        opening_balance : 0.0, 
        bank_branch_name : null,
        bank_branch_ifsc_code : null,
        bank_account_number : 0,
        website : null,
        phone_number2 : null,
        primary_address : null
    })

    useEffect(() => {
        const fetchCustomerDetails = async () => {
            try {
                const response = await getFromAPI(`FtsVendor/?id=${selectedCustomerId}`);
                if (response && response.length > 0) {
                    const vendorData = response[0];
                    const stateName1 = await getStateNameByCode(vendorData.state);
                    const cityName = await getCityByName(vendorData.city);
                    const callingCode = vendorData.country
                    const countryName = await getCountryName(callingCode);
                   
                    setInputData({
                        name: vendorData.name,
                        company_name: vendorData.company_name || '',
                        phone_number1: vendorData.phone_number1 || '',
                        email : vendorData.email || '',
                        primary_address: vendorData.primary_address || '',
                        gst_number: vendorData.gst_number || '',
                        country: vendorData.country,
                        state: vendorData.state,
                        city: vendorData.city,
                        tax: vendorData.tax,
                        pan_card_number: vendorData.pan_card_number || '',
                        opening_balance: vendorData.opening_balance,
                        bank_branch_name: vendorData.bank_branch_name || '',
                        bank_branch_ifsc_code: vendorData.bank_branch_ifsc_code || '',
                        bank_account_number: vendorData.bank_account_number || '',
                        website : vendorData.website || '',
                        phone_number2 : vendorData.phone_number2 || ''
                    });
                      // Fetch state based on country
                      if (vendorData.country) {
                        const stateResponse = await getFromAPI(`FtsState/?country_code=${vendorData.country}`);
                        setState(stateResponse); // Set state dropdown
                    }
    
                    // Fetch city based on state
                    if (vendorData.state) {
                        const cityResponse = await getFromAPI(`FtsCity/?state_id=${vendorData.state}`);
                        setCity(cityResponse); // Set city dropdown
                    }
                }
            } catch (error) {
                console.error('Error fetching vendor data:', error);
            }
        };

        if (selectedCustomerId) {
            fetchCustomerDetails();
        }
    }, [selectedCustomerId]);


     // Handle input specifically for Mobile Number and Email
     const handleMobileChange = (e) => {
        const { name, value } = e.target;
        // Remove spaces from the input value
        setInputData((prev) => ({
            ...prev,
            [name]: value.replace(/\s+/g, ''), // Remove all spaces
        }));
    };

    const handleCountryChange = async (e) => {
        const selectedCountry = e.target.value;
        setInputData(prevState => ({ ...prevState, country: selectedCountry }));

        try {
            const response = await getFromAPI(`FtsState/?country_code=${selectedCountry}`);
            setState(response); // Save state data
            setCity([]); // Reset city data
        } catch (error) {
            console.error('Error fetching state data:', error);
        }
    };

    const handleStateChange = async (e) => {
        const selectedState = e.target.value;
        setInputData(prevState => ({ ...prevState, state: selectedState }));

        try {
            const response = await getFromAPI(`FtsCity/?state_id=${selectedState}`);
            setCity(response); // Save city data
        } catch (error) {
            console.error('Error fetching city data:', error);
        }
    };

    const handleDeleteVendor = async (itemId) => {
        try {
            const response = await deleteFromAPI(`FtsVendor/${itemId}/`);
            if (response.status) {
                toast.success('Vendor deleted successfully', 'success');
                // Optionally, redirect or update state to reflect deletion
                setTableData([]); // Clear table data or handle as needed
                fetchCustomerData();
            }
        } catch (error) {
            console.error('Error deleting vendor:', error);
            toast.error('Vendor Not Deleted', 'error');
        }

        //fetchCustomerData(); // Update the customer list
    };

    // Handle Delete Customer Entry
    const handleDeleteVendor1 = async (Id) => {
        // TODO: Check vendor has transactions or invoices before deleting
        const POInvoiceData = await getFromAPI(`FtsPurchaseOrderInvoice/?vendor=${Id}`);
        // const POCreditNote = await getFromAPI(`FtsPurchaseOrderCreditNote/?vendor=${Id}`);
        if (POInvoiceData.length === 0) {
            try {
                showAlert('Are you sure you want to delete this Vendor?', 'confirm', (result) => {
                    if (result) {
                        // User confirmed the deletion
                        handleDeleteVendor(Id);
                    } else {
                        // User canceled the deletion
                        // You can handle this case if needed
                    }
                });
            } catch (error) {
                console.error('Error deleting Customer:', error);
                toast.error('Error deleting Customer', 'error');
            }
        } else {
            toast.error('Vendor has transactions or invoices. Cannot delete.', 'error');
        }
    };

    let response = '';
    const fetchCustomerData1 = async () => {
        try {
            // Check if selectedCustomerId is available
            if (!selectedCustomerId) {
                // Clear the form data if no ID is selected
                setContactPerson('');
                setMobileNo('');
                setEmail('');
                setAddress('');
                setCountry('');
                setGst('');
                setTax('');
                setPan('');
                setOpeningBalance('');
                setBank('');
                setIFSC('');
                setAccNo('');
                setWebsite('');
                setAlternate('');
                setCityName('');
                setStateName('');
                return; // Return early, do not proceed with API call
            }
    
            // Proceed with API call if selectedCustomerId is valid
            const response = await getFromAPI(`FtsVendor/?id=${selectedCustomerId}`);
    
            if (response && response.length > 0) {
                // Fetch customer data
                const contact_name = response[0].name;
                setContactPerson(contact_name);
    
                const mobile = response[0].phone_number1;
                setMobileNo(mobile);
    
                const email = response[0].email;
                setEmail(email);
    
                const address = response[0].primary_address;
                setAddress(address);
    
                let country = response[0].country;
                const countryName = await getCountryName(country);
                setCountry(countryName);
    
                const gst = response[0].gst_number;
                setGst(gst);
    
                const Tax = response[0].tax;
                const taxName = await gettaxById(Tax);
                setTax(taxName);
    
                const pan = response[0].pan_card_number;
                setPan(pan);
    
                const opening_balance = response[0].opening_balance;
                setOpeningBalance(opening_balance);
    
                const Bank_Name = response[0].bank_branch_name;
                setBank(Bank_Name);
    
                const IFSC_CODE = response[0].bank_branch_ifsc_code;
                setIFSC(IFSC_CODE);
    
                const accNo = response[0].bank_account_number;
                setAccNo(accNo);
    
                const web = response[0].website;
                setWebsite(web);
    
                const alt = response[0].phone_number2;
                setAlternate(alt);
    
                const stateCode = response[0].state; // Assuming state contains the state_code
                const cityID = response[0].city; // Assuming city contains the id
    
                // Fetch state and city names
                const stateName = await getStateNameByCode(stateCode);
                const cityName = await getCityByName(cityID);
                setCityName(cityName);
                setStateName(stateName);
            } else {
                console.warn('No data found for the selected vendor.');
            }
        } catch (error) {
            console.error('Error fetching vendor data:', error);
        }
    };
    
      const handleEdit = () => {
          setShowEditModal(true);
      };
  
      
    const getStateNameByCode = async (code) => {
        try {
            const stateResponse = await getFromAPI(`FtsState/`);
            
            // Assuming stateResponse is an array of states
            const state = stateResponse.find(state => state.state_id === code); // Replace `id` with the actual key for state ID in your response
            if (state) {
                return state.state_name; // Return the state name
            } else {
                return null; // Return null if not found
            }
        } catch (error) {
            console.error("Error fetching states:", error);
            return null; // Return null in case of an error
        }
    };
    
    const getCityByName= async(code) => {
        try {
            const CityResponse = await getFromAPI(`FtsCity/?id=${code}`);
            
            if (CityResponse) {
                return CityResponse[0].city_name; // Return the state name
            } else {
                return null; // Return null if not found
            }
        } catch (error) {
            console.error("Error fetching states:", error);
            return null; // Return null in case of an error
        }
    };
    
    const getCountryName= async(code) => {
        try {
            const countryResponse = await getFromAPI(`FtsCountries/?calling_code=${code}`);
            if (countryResponse) {
                return countryResponse[0].short_name; // Return the state name
            } else {
                return null; // Return null if not found
            }
        } catch (error) {
            console.error("Error fetching states:", error);
            return null; // Return null in case of an error
        }
    };

    const gettaxById = async(id) => {
     try{
           const taxResponse = await getFromAPI(`FtsTaxes/?id=${id}`);
        if(taxResponse) {
            return taxResponse[0].name; // Return the state name
        }
        else
        {
            return null;
        }
    }catch (error) {
        console.error("Error fetching states:", error);
        return null; // Return null in case of an error
    }
  };
    
    

    const handleSaveChanges = async () => {
        try {
            await putToAPI(`FtsVendor/${selectedCustomerId}/`, inputData);
            toast.success('Vendor details updated successfully', 'success');
            setShowEditModal(false);
            fetchCustomerData();
            fetchCustomerData1();
        } catch (error) {
            console.error('Error updating vendor details:', error);
            toast.error('Failed to update vendor details', 'error');
        }
    };


    useEffect(() => {
        fetchCustomerData1();
        fetchCustomerData();
    }, [selectedCustomerId]);

    useEffect(() => {
        const fetchBankData = async () => {
            try {
                const response = await getFromAPI('FtsBanks/');
                setBank1(response); // Save tax data
            } catch (error) {
                console.error('Error fetching tax data:', error);
            }
        };

        fetchBankData();
    }, []);

    useEffect(() => {
        const fetchTaxData = async () => {
            try {
                const response = await getFromAPI('FtsTaxes/');
                setTax1(response); // Save tax data
            } catch (error) {
                console.error('Error fetching tax data:', error);
            }
        };

        fetchTaxData();
    }, []);

    useEffect(() => {
        const fetchCountryData = async () => {
            try {
                const response = await getFromAPI("FtsCountries/");
                setCountry1(response); // Save country data
            } catch (error) {
                console.error('Error fetching country data:', error);
            }
        };

        fetchCountryData();
    }, []);

    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <Card.Title className='mb-0'>Creative Industries Details</Card.Title>
                <div className='d-flex'>
                    <button
                        className='btn btn-link'
                        style={{ marginRight: '0', padding: '0' }}
                        onClick={handleEdit}
                        disabled={!selectedCustomerId}  // Disable if no data
                    >
                        <div className='avatar avatar-sm bg-soft-info text-info'>
                            <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                        </div>
                    </button>
                    <button
                        className='btn btn-link'
                        onClick={() => handleDeleteVendor1(selectedCustomerId)} // Use a function reference
                        disabled={!selectedCustomerId}  // Disable if no data
                    >
                        <div className='avatar avatar-sm bg-soft-danger text-danger'>
                            <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                        </div>
                    </button>
                </div>
            </div>

            <Table responsive className='table-lg border table-nowrap'>
                <tbody>
                    <tr>
                        <th>Contact Person Name :</th>
                        <td>{contactPerson}</td>
                        <th>Mobile Number :</th>
                        <td>{mobileNo}
                            <Button variant="link text-muted py-0 px-3 ms-4" onClick={() => setPhoneShow(true)}>
                                <Icon path={mdiPhoneInTalk} className='icon-size-16' />
                            </Button>
                            <Button variant="link text-muted py-0 px-3" onClick={() => setMsgShow(true)}>
                                <Icon path={mdiMessageProcessing} className='icon-size-16' />
                            </Button>
                        </td>
                    </tr>
                    <tr>
                        <th>Email :</th>
                        <td>{email}
                            <Button variant="link text-muted py-0 px-3 ms-4" onClick={() => setEmailShow(true)}>
                                <Icon path={mdiEmail} className='icon-size-16' />
                            </Button>
                        </td>
                        <th>Website :</th>
                        <td>{website}</td>
                    </tr>
                    <tr>
                        <th>Address :</th>
                        <td>{address}</td>
                        <th>Country :</th>
                        <td>{Country}</td>
                    </tr>
                    <tr>
                        <th>State :</th>
                        <td>{stateName}</td>
                        <th>City :</th>
                        <td>{cityName}</td>
                    </tr>
                    <tr>
                        <th>Tax :</th>
                        <td>{tax}</td>
                        <th>GSTIN :</th>
                        <td>{gst}</td>
                    </tr>
                    <tr>
                        <th>PAN :</th>
                        <td>{pan}</td>
                        <th>Opening Balance :</th>
                        <td>{openingBalance}</td>
                    </tr>
                    <tr>
                        <th>Vendor Tax :</th>
                        <td>N/A</td>
                        <th>Tax Type :</th>
                        <td>N/A</td>
                    </tr>
                    <tr>
                        <th>Date Create :</th>
                        <td>N/A <span className='mx-2'>|</span></td>
                        <th>Alternate Number :</th>
                        <td>{Alternate}</td>
                    </tr>
                    <tr>
                        <th>Bank Name :</th>
                        <td>{bank}</td>
                        <th>IFSC Code :</th>
                        <td>{IFSC}</td>
                    </tr>
                    <tr>
                        <th>Account Number :</th>
                        <td>{AccNo}</td>
                        <th>Current balance :</th>
                        <td>N/A</td>
                    </tr>
                </tbody>
            </Table>

            {/* Phone Modal */}
            <Modal show={phoneShow} onHide={() => setPhoneShow(false)} centered>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body className='text-center'>
                    <div className='avatar avatar-xxxl bg-soft-success mx-auto text-success mx-auto rounded-circle mx-auto mt-4'>
                        <Icon path={mdiPhoneInTalk} className='avatar-icon' />
                    </div>
                    <h5 className='mt-4'>{mobileNo}</h5>
                </Modal.Body>
                <Modal.Footer className='mb-4'>
                    <Button variant="success mx-auto">
                        Call Now
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Phone Modal */}
            <Modal show={msgShow} onHide={() => setMsgShow(false)} centered>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body className='text-center'>
                    <div className='avatar avatar-xxxl bg-soft-primary mx-auto text-primary mx-auto rounded-circle mx-auto mt-4'>
                        <Icon path={mdiMessageProcessing} className='avatar-icon' />
                    </div>
                    <h5 className='mt-4'>{mobileNo}</h5>
                </Modal.Body>
                <Modal.Footer className='mb-4'>
                    <Button variant="primary mx-auto">
                        Message Send
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Email Modal */}
            <Modal show={emailShow} onHide={() => setEmailShow(false)} centered>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body className='text-center'>
                    <div className='avatar avatar-xxxl bg-soft-info mx-auto text-info mx-auto rounded-circle mx-auto mt-4'>
                        <Icon path={mdiEmail} className='avatar-icon' />
                    </div>
                    <h5 className='mt-4'>{email}</h5>
                </Modal.Body>
                <Modal.Footer className='mb-4'>
                    <Button variant="info mx-auto">
                        Email Send
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Vendor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='companyName'
                                        id='companyName'
                                        placeholder="Enter company name..."
                                        value={inputData.company_name}
                                        onChange={e => setInputData({ ...inputData, company_name: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Contact Person</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter contact person..."
                                        name='contactPerson'
                                        id='contactPerson'
                                        value={inputData.name}
                                        onChange={e => setInputData({ ...inputData, name: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Please Enter Mobile Number..."
                                        name='phone_number1'
                                        id='phone_number1'
                                        value={inputData.phone_number1}
                                        onChange={handleMobileChange}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Email ID</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email..."
                                        name='contactPerson'
                                        id='contactPerson'
                                        value={inputData.email}
                                        onChange={e => setInputData({ ...inputData, email: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your address..."
                                        name='primary_address'
                                        id='primary_address'
                                        value={inputData.primary_address}
                                        onChange={e => setInputData({ ...inputData, primary_address: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>GST</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter GST"
                                        name='gst_number'
                                        id='gst_number'
                                        value={inputData.gst_number}
                                        onChange={e => setInputData({ ...inputData, gst_number: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select
                                        
                                        name='country'
                                        id='country'
                                        value={inputData.country}
                                        onChange={handleCountryChange}
                                    >
                                        <option value="">Select Country</option>
                                        {country.map(item => (
                                            <option key={item.id} value={item.calling_code}>{item.short_name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select
                                        
                                        name='state'
                                        id='state'
                                        value={inputData.state}
                                        onChange={handleStateChange}
                                    >
                                        <option value="">Select State</option>
                                        {State.map(item => (
                                            <option key={item.id} value={item.state_id}>{item.state_name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>City</Form.Label>
                                    <Form.Select
                                        
                                        name='city'
                                        id='city'
                                        value={inputData.city}
                                        onChange={e => setInputData({ ...inputData, city: e.target.value })}

                                    >
                                        <option value="">Select City</option>
                                        {city.map(item => (
                                            <option key={item.id} value={item.id}>{item.city_name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Select
                                        
                                        name='tax'
                                        id='tax'
                                        value={inputData.tax}
                                        onChange={e => setInputData({ ...inputData, tax: e.target.value })}

                                    >
                                        <option value="">Select Tax</option>
                                        {Tax.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} >
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>PAN</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className='text-up'
                                        placeholder="Enter PAN number..."
                                        name='panNumber'
                                        id='panNumber'
                                        value={inputData.pan_card_number}
                                        onChange={e => setInputData({ ...inputData, pan_card_number: e.target.value })}
                                    />
                                </div>
                            </Col>
                             <Col md={6}>
                            
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Opening Balance</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter balance..."
                                        name='openingBalance'
                                        id='openingBalance'
                                        value={inputData.opening_balance}
                                        onChange={e => setInputData({ ...inputData, opening_balance: e.target.value })}

                                    />
                                </div>
                            </Col>
                            <Col md = {6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Website</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your website..."
                                        name='website'
                                        id='website'
                                        value={inputData.website}
                                        onChange={e => setInputData({ ...inputData, website: e.target.value })}

                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alternate Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter alternate number..."
                                        name='alternateNumber'
                                        id='alternateNumber'
                                        value={inputData.phone_number2}
                                        onChange={e => setInputData({ ...inputData, phone_number2: e.target.value })}

                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Bank Name</Form.Label>
                                    <Form.Select
                                        
                                        name='bankName'
                                        id='bankName'
                                        value={inputData.bank_branch_name}
                                        onChange={e => setInputData({ ...inputData, bank_branch_name: e.target.value })}

                                    >
                                        <option value="">Select Bank Name</option>
                                        {Bank.map(item => (
                                            <option key={item.id} value={item.bank_id}>{item.bank_name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>IFSC Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter IFSC code..."
                                        name='ifscCode'
                                        id='ifscCode'
                                        value={inputData.bank_branch_ifsc_code}
                                        onChange={e => setInputData({ ...inputData, bank_branch_ifsc_code: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Account Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter account number..."
                                        name='accountNumber'
                                        id='accountNumber'
                                        value={inputData.bank_account_number}
                                        onChange={e => setInputData({ ...inputData, bank_account_number: e.target.value })}

                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSaveChanges}>Save Changes</Button>
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />

        </>
    )
}
