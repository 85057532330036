import React from 'react';
import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import Cash from './Cash';
import ICICIBank from './ICICIBank';
import YESBank from './YESBank';
import { Container, Row, Col, Card } from 'react-bootstrap';

export default function AccountSummary() {
  return (
    <>
      <div className="page-wrapper">
        <div className='page-content'>
          <PageTitle breadcrumb1="Accounting" breadcrumb2="Reports" breadcrumbLast="Account Summary" />

          <Container>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <div className='custom-top-nav-tab'>
                      <ul className="nav nav-pills nav-fill btn-list mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button className="nav-link active" id="pills-cash-tab" data-bs-toggle="pill" data-bs-target="#pills-cash" type="button" role="tab" aria-controls="pills-cash" aria-selected="true">Cash</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="pills-icici-bank-tab" data-bs-toggle="pill" data-bs-target="#pills-icici-bank" type="button" role="tab" aria-controls="pills-icici-bank" aria-selected="false">ICICI Bank</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="pills-yes-bank-tab" data-bs-toggle="pill" data-bs-target="#pills-yes-bank" type="button" role="tab" aria-controls="pills-yes-bank" aria-selected="false">YES Bank</button>
                        </li>
                      </ul>

                      <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-cash" role="tabpanel" aria-labelledby="pills-cash-tab" tabIndex="0">
                          <Cash />
                        </div>
                        <div className="tab-pane fade" id="pills-icici-bank" role="tabpanel" aria-labelledby="pills-icici-bank-tab" tabIndex="0">
                          <ICICIBank />
                        </div>
                        <div className="tab-pane fade" id="pills-yes-bank" role="tabpanel" aria-labelledby="pills-yes-bank-tab" tabIndex="0">
                          <YESBank />
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  )
}
