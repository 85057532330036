import React, { useState, useEffect } from 'react';
import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Form, Table } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// react-select
import Select from 'react-select';

export default function GSTTaxReport() {
    // multi-select
    const [value, setValue] = useState(null)
    const options = [
        { value: "All Branch", label: "All Branch" },
        { value: "NSF Adajan", label: "NSF Adajan" },
        { value: "NFS Parle Point", label: "NFS Parle Point" },
        { value: "NFS Uniform", label: "NFS Uniform" },
        { value: "NFS Warehouse", label: "NFS Warehouse" },
    ]

    // datatable
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableACGSTTaxtReport')) {
            $('#mainDatatableACGSTTaxtReport').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, []);
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Accounting" breadcrumb2="Reports" breadcrumbLast="GST Tax Report" />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-sm-flex justify-content-between align-items-center mb-3">
                                            <Card.Title className='mb-sm-0'>GST Tax Report</Card.Title>
                                            <Form>
                                                <div className='min-sm-w'></div>
                                                <Form.Select  size="sm">
                                                    <option>All Time</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                </Form.Select>
                                            </Form>
                                        </div>

                                        <Form>
                                            <Row>
                                                <Col>
                                                    <div className="custom-form-input">
                                                        <Form.Label className='z-1'>Branch</Form.Label>
                                                        <Select options={options} defaultValue={value} placeholder="Select Branch..." name='branch' id='branch' onChange={setValue} isMulti></Select>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-lg-flex justify-content-between align-items-center mb-3">
                                            <ul className='list-inline text-dark fw-medium mb-0'>
                                                <li className='list-inline-item border py-3 px-4 rounded mb-3 mb-lg-0'>Credit (Cr.): 0</li>
                                                <li className='list-inline-item border py-3 px-4 rounded mb-3 mb-lg-0'>Debit (Dr.): 0</li>
                                                <li className='list-inline-item border py-3 px-4 rounded mb-3 mb-lg-0'>Closing Balance: 0</li>
                                            </ul>
                                        </div>

                                        <Table responsive bordered className='table-nowrap mb-0' id='mainDatatableACGSTTaxtReport'>
                                            <thead className='table-light'>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Date</th>
                                                    <th>Transaction Type</th>
                                                    <th>Reff. No.</th>
                                                    <th>Debits(Dr.)</th>
                                                    <th>Credits(Cr.)</th>
                                                    <th>Closing Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>28/12/2022</td>
                                                    <td>Opening balance</td>
                                                    <td>0</td>
                                                    <td>0</td>
                                                    <td>0</td>
                                                    <td>0</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
