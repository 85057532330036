import React from 'react';
import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import ViewSelfBranchReport from './ViewSelfBranchReport';
import { Container, Row, Col, Card } from 'react-bootstrap';
import BulkAdjustment from './BulkAdjustment';

export default function SelfBranchStockReport() {
    // Change card title
    const handleClick = event => {
        var buttonID = event.currentTarget.id;
        var button = document.getElementById(buttonID);
        var cardtitle = document.getElementById('cardTitle');
        var buttonText = button.textContent;
        cardtitle.innerHTML = buttonText;
      };
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Reports" breadcrumbLast="Self Branch Stock Report" />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-md-flex justify-content-between align-items-center mb-3">
                                            <Card.Title className='mb-md-0' id="cardTitle">Self Branch Stock Report</Card.Title>
                                            <div className="custom-top-nav-tab mt-3 mt-md-0">
                                                <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" onClick={handleClick} data-text="Self Branch Stock Report" id="pills-view-self-branch-report-tab" data-bs-toggle="pill" data-bs-target="#pills-view-self-branch-report" type="button" role="tab" aria-controls="pills-view-self-branch-report" aria-selected="true">
                                                            Self Branch Stock Report
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" onClick={handleClick} data-text="Bulk Adjustment" id="pills-bulk-adjustment-tab" data-bs-toggle="pill" data-bs-target="#pills-bulk-adjustment" type="button" role="tab" aria-controls="pills-bulk-adjustment" aria-selected="false">
                                                            Bulk Adjustment
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-view-self-branch-report" role="tabpanel" aria-labelledby="pills-view-self-branch-report-tab" tabIndex="0">
                                                <ViewSelfBranchReport />
                                            </div>
                                            <div className="tab-pane fade" id="pills-bulk-adjustment" role="tabpanel" aria-labelledby="pills-bulk-adjustment-tab" tabIndex="0">
                                                <BulkAdjustment />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
