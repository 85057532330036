import React from 'react';
import { Card, Form, Row, Col, Button } from 'react-bootstrap';
export default function NewBranchAdmin() {
    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <Card.Title>New Branch Admin</Card.Title>
                    <Form>
                        <Row>
                            <Col md={6} lg={12} xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder='Enter Name...' name='branchName' id='branchName' />
                                </div>
                            </Col>
                            <Col md={6} lg={12} xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Branch</Form.Label>
                                    <Form.Control type="text" placeholder='Enter Branch...' name='branch' id='branch' />
                                </div>
                            </Col>
                            <Col md={6} lg={12} xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Email ID</Form.Label>
                                    <Form.Control type="email" placeholder='Enter Email...' name='emailId' id='emailId' />
                                </div>
                            </Col>
                            <Col md={6} lg={12} xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder='Enter password...' name='password' id='password' />
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" placeholder='Enter Address...' name='address' id='address' />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control type="number" placeholder='Enter mobile number ...' name='mobileNumber' id='mobileNumber' />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Gender</Form.Label>
                                    <Form.Select  name='gender' id='gender'>
                                        <option>Male</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Birthday</Form.Label>
                                    <Form.Control type="date" placeholder='DD/MM/YYYY' name='birthday' id='birthday' />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Date of Join</Form.Label>
                                    <Form.Control type="date" placeholder='DD/MM/YYYY' name='dateofJoin' id='dateofJoin' />
                                </div>
                            </Col>
                            <Col md={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alternate Name</Form.Label>
                                    <Form.Control type="text" placeholder='Enter alternate name...' name='alternateName' id='alternateName' />
                                </div>
                            </Col>
                            <Col md={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alternate Phone Number</Form.Label>
                                    <Form.Control type="text" placeholder='Enter alternate phone number...' name='alternatePhoneNumber' id='alternatePhoneNumber' />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alternate Address</Form.Label>
                                    <Form.Control type="text" placeholder='Enter alternate address...' name='alternateAddress' id='alternateAddress' />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Id Proof</Form.Label>
                                    <Form.Control type="text" placeholder='Enter id proof...' name='idProof' id='idProof' />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
                <div className='custom-card-footer'>
                    <div className='text-end btn-list'>
                        <Button variant="primary">Add</Button>{' '}
                        <Button variant="secondary">Cancel</Button>{' '}
                    </div>
                </div>
            </Card>
        </>
    )
}
