import React, { useState, useEffect } from 'react';
import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Table } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// table data
import PurchaseInvoiceTaxTableData from "./purchase-invoice-tax-report-table-data.json";

export default function MonthWisePurchaseInvoice() {
    // datatable
    const [purchaseInvoiceTaxData] = useState(PurchaseInvoiceTaxTableData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableMWPITax')) {
            $('#mainDatatableMWPITax').DataTable({
                dom: "Bflrtip",
                buttons: [
                    {
                        extend: 'print',
                        className: 'btn btn-outline-secondary',
                        text: 'Print'
                    }
                ],
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }

        if (document.getElementsByClassName("dt-buttons").length) {
            document.getElementById("mainDatatableMWPITax_wrapper").classList.add("dtl-right");
        } else {
            document.getElementById("mainDatatableMWPITax_wrapper").classList.remove("dtl-right");
        }
    }, []);

    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableACPITReports')) {
            $('#mainDatatableACPITReports').DataTable({
                dom: "Bflrtip",
                buttons: [
                    {
                        extend: 'pdf',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf'
                    },
                    {
                        extend: 'print',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M19 8C20.66 8 22 9.34 22 11V17H18V21H6V17H2V11C2 9.34 3.34 8 5 8H6V3H18V8H19M8 5V8H16V5H8M16 19V15H8V19H16M18 15H20V11C20 10.45 19.55 10 19 10H5C4.45 10 4 10.45 4 11V15H6V13H18V15M19 11.5C19 12.05 18.55 12.5 18 12.5C17.45 12.5 17 12.05 17 11.5C17 10.95 17.45 10.5 18 10.5C18.55 10.5 19 10.95 19 11.5Z" style="fill: currentcolor;"></path></svg> Export to Print'
                    }
                ],
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }

        if (document.getElementsByClassName("dt-buttons").length) {
            document.getElementById("mainDatatableACPITReports_wrapper").classList.add("dtl-right");
        } else {
            document.getElementById("mainDatatableACPITReports_wrapper").classList.remove("dtl-right");
        }
    }, []);

    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Accounting" breadcrumb2="Reports" breadcrumbLast="Month Wise Purchase Invoice Tax" />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Month Wise Purchase Invoice Tax</Card.Title>
                                        <Table responsive bordered className='table-nowrap' id='mainDatatableMWPITax'>
                                            <thead className='table-light'>
                                                <tr>
                                                    <th>GST %</th>
                                                    <th>Taxable Amount</th>
                                                    <th>SGST</th>
                                                    <th>CGST</th>
                                                    <th>IGST</th>
                                                    <th>CESS</th>
                                                    <th>Total Tax</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Total:</td>
                                                    <td>0.00</td>
                                                    <td>0.00</td>
                                                    <td>0.00</td>
                                                    <td>0.00</td>
                                                    <td>0.00</td>
                                                    <td>0.00</td>
                                                    <td>0.00</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <Card className='border shadow-none mt-3 mb-0'>
                                            <Card.Body>
                                                <Card.Title>Purchase Invoice Tax Report</Card.Title>

                                                <Table responsive bordered className='table-nowrap' id='mainDatatableACPITReports'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Month/Year</th>
                                                            <th>Invoice Amount</th>
                                                            <th>Total Taxable Amount</th>
                                                            <th>Total Tax Amount</th>
                                                            <th>S-GST Amount</th>
                                                            <th>C-GST Amount</th>
                                                            <th>I-GST Amount</th>
                                                            <th>CESS Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {purchaseInvoiceTaxData.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.monthYear}</td>
                                                                <td>{data.invoiceAmount}</td>
                                                                <td>{data.totalTaxableAmount}</td>
                                                                <td>{data.totalTaxAmount}</td>
                                                                <td>{data.sGstAmount}</td>
                                                                <td>{data.cGstAmount}</td>
                                                                <td>{data.iGstAmount}</td>
                                                                <td>{data.cessAmount}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
