import React, { useEffect } from 'react';
import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Form, Table } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// icon
import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';

export default function VendorLedger() {
    // datatable
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableACVendorLedger')) {
            $('#mainDatatableACVendorLedger').DataTable({
                dom: "Bflrtip",
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel'
                    },
                    {
                        extend: 'pdf',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf'
                    }
                ],
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }

        if (document.getElementsByClassName("dt-buttons").length) {
            document.getElementById("mainDatatableACVendorLedger_wrapper").classList.add("dtl-right");
        } else {
            document.getElementById("mainDatatableACVendorLedger_wrapper").classList.remove("dtl-right");
        }
    }, []);

    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Accounting" breadcrumb2="Reports" breadcrumbLast="Vendor Ledger" />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-md-flex justify-content-between align-items-center">
                                            <Card.Title className='mb-md-0'>Vendor Ledger</Card.Title>
                                            <Form>
                                                <div className='min-sm-w'></div>
                                                <Form.Select  size="sm">
                                                    <option>All Time</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                </Form.Select>
                                            </Form>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-lg-flex justify-content-between align-items-center mb-3">
                                            <ul className='list-inline text-dark fw-medium mb-0'>
                                                <li className='list-inline-item border py-3 px-4 rounded mb-3 mb-lg-0'>Credit (Cr.): 0</li>
                                                <li className='list-inline-item border py-3 px-4 rounded mb-3 mb-lg-0'>Debit (Dr.): 0</li>
                                                <li className='list-inline-item border py-3 px-4 rounded mb-3 mb-lg-0'>Closing Balance: 0</li>
                                            </ul>
                                            <Form className='custom-form-width'>
                                                <div className='custom-search-bar'>
                                                    <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                    <Form.Control className='' type="text" placeholder="Search Quick Name..." name='quickNameSearch' id='quickNameSearch' />
                                                </div>
                                            </Form>
                                        </div>

                                        <Card className='border shadow-none mb-0'>
                                            <Card.Body>
                                                <Card.Title>Quick Name</Card.Title>

                                                <Table responsive bordered className='table-nowrap' id='mainDatatableACVendorLedger'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Sr.No</th>
                                                            <th>Date</th>
                                                            <th>Particular</th>
                                                            <th>Debits(Dr.)</th>
                                                            <th>Debits(Dr.)</th>
                                                            <th>Credits(Cr.)</th>
                                                            <th>Closing Balance</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>28/12/2022</td>
                                                            <td>Opening balance</td>
                                                            <td>0</td>
                                                            <td>0</td>
                                                            <td>0</td>
                                                            <td>0</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
