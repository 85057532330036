import React, { useState } from 'react';
import { Col, Row, Card, Form, Table, Button, Modal } from 'react-bootstrap';

// react-select
import Select from 'react-select';

// Table data
import MembershipTableData from "./membership-details-data.json";

// icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import FreeService from './FreeService';

export default function MembershipDetails() {
    // Membership Table Data
    const [membershipData] = useState(MembershipTableData);

    // multi-select
    const [value, setValue] = useState(null)
    const options = [
        { value: "All Branch", label: "All Branch" },
        { value: "NSF Adajan", label: "NSF Adajan" },
        { value: "NFS Parle Point", label: "NFS Parle Point" },
        { value: "NFS Uniform", label: "NFS Uniform" },
        { value: "NFS Warehouse", label: "NFS Warehouse" },
    ]

    // Default Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <Card.Title className='mb-0'>Details</Card.Title>
                <div className='d-flex'>
                    <div className="btn-list">
                        <Button variant="soft-info p-0" onClick={handleShow}>
                            <div className='avatar avatar-sm'>
                                <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                            </div>
                        </Button>{' '}
                        <Button variant="soft-danger p-0">
                            <div className='avatar avatar-sm'>
                                <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                            </div>
                        </Button>{' '}
                    </div>
                </div>
            </div>

            {/* view membership */}
            <div className='view-membership'>
                <Table responsive className='table-lg border table-nowrap'>
                    <tbody>
                        {membershipData.membershipTableData.map((data, i) =>
                            <tr key={i}>
                                <th>{data.productheading1}</th>
                                <td>{data.productsubtext1}</td>
                                <th>{data.productheading2}</th>
                                <td>{data.productsubtext2}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>

            <Modal centered show={show} size="xl" onHide={handleClose}>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-3'>Edit Membership</h5>
                    <div className="edit-membership">
                        <Form>
                            <Row>
                                <Col xxl={4}>
                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter member name..." name='memberName' id='memberName' />
                                    </div>
                                </Col>
                                <Col sm={6} xxl={4}>
                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                        <Form.Label>Price</Form.Label>
                                        <Form.Control type="text" placeholder="Enter price..." name='price' id='price' />
                                    </div>
                                </Col>
                                <Col sm={6} xxl={4}>
                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                        <Form.Label>Min Bill Amt</Form.Label>
                                        <Form.Control type="text" placeholder="Enter bill amount..." name='minBillAmt' id='minBillAmt' />
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                        <Form.Label>Apply Benefits</Form.Label>
                                        <Form.Select  name='applyBenefits' id='applyBenefits'>
                                            <option>Same Invoice</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                        </Form.Select>
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                        <Form.Label>Sales Date</Form.Label>
                                        <Form.Control type="date" placeholder="Enter bill amount..." name='salesDate' id='salesDate' />
                                    </div>
                                </Col>
                                <Col sm={6} xxl={4}>
                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                        <Form.Label>Start Date</Form.Label>
                                        <Form.Control type="date" placeholder="Enter bill amount..." name='startDate' id='startDate' />
                                    </div>
                                </Col>
                                <Col sm={6} xxl={4}>
                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                        <Form.Label>End Date</Form.Label>
                                        <Form.Control type="date" placeholder="Enter bill amount..." name='endDate' id='endDate' />
                                    </div>
                                </Col>
                                <Col sm={6} xxl={4}>
                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                        <Form.Label>Validity</Form.Label>
                                        <Form.Select  name='validity' id='validity'>
                                            <option>Year</option>
                                            <option value="1">Month</option>
                                            <option value="2">6 Month</option>
                                        </Form.Select>
                                    </div>
                                </Col>
                                <Col sm={6} xxl={4}>
                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                        <Form.Label>Validity Period</Form.Label>
                                        <Form.Control type="text" placeholder="Enter validity period..." name='validityPeriod' id='validityPeriod' />
                                    </div>
                                </Col>
                                <Col sm={6} xxl={4}>
                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                        <Form.Label>Credits Applicable?</Form.Label>
                                        <Form.Select  name='creditsApplicable' id='creditsApplicable'>
                                            <option>No</option>
                                            <option value="1">Yes</option>
                                            <option value="2">Null</option>
                                        </Form.Select>
                                    </div>
                                </Col>
                                <Col sm={6} xxl={4}>
                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                        <Form.Label>Credit</Form.Label>
                                        <Form.Control type="text" placeholder="Enter credit..." name='credit' id='credit' />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='align-items-end'>
                                <Col xl={6}>
                                    <div className="mb-3 mb-xxl-0 custom-form-input custom-form-input-sm">
                                        <Form.Label className='z-1'>Branch</Form.Label>
                                        <Select options={options} defaultValue={value} placeholder="Select Branch..." name='productBranch' id='productBranch' onChange={setValue} isMulti></Select>
                                    </div>
                                </Col>
                                <Col xl={6} className="text-end">
                                    <div className="btn-list text-end">
                                        <Button variant="primary" type="submit">Save</Button>{' '}
                                        <Button variant="secondary" type="button">Cancel</Button>{' '}
                                    </div>
                                </Col>
                            </Row>
                        </Form>

                        <div className='d-md-flex justify-content-between align-items-center mt-4 mb-3'>
                            <Card.Title className='mb-0'>Membership Benefits</Card.Title>
                            <div className="custom-top-nav-tab d-flex mt-3 mt-md-0">
                                <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-discount-plan-tab" data-bs-toggle="pill" data-bs-target="#pills-discount-plan" type="button" role="tab" aria-controls="pills-discount-plan" aria-selected="true">Discount plan</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-free-service-tab" data-bs-toggle="pill" data-bs-target="#pills-free-service" type="button" role="tab" aria-controls="pills-free-service" aria-selected="false">Free service</button>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-discount-plan" role="tabpanel" aria-labelledby="pills-discount-plan-tab" tabIndex="0">
                                <Card className='border shadow-none'>
                                    <Card.Body>
                                        <Form>
                                            <Row>
                                                <Col sm={6} xl={4} xxl={3}>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Service Category</Form.Label>
                                                        <Form.Select  name='serviceCategory' id='serviceCategory'>
                                                            <option>Service Category</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col sm={6} xl={4} xxl={3}>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Service</Form.Label>
                                                        <Form.Select  name='service' id='service'>
                                                            <option>Select Service</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col sm={6} xl={4} xxl={3}>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Service Price</Form.Label>
                                                        <Form.Select  name='servicePrice' id='servicePrice'>
                                                            <option>No</option>
                                                            <option value="1">No</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col sm={6} xl={4} xxl={3}>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Timing</Form.Label>
                                                        <Form.Select  name='timing' id='timing'>
                                                            <option>Full Day</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col sm={6} xl={4} xxl={3}>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Gender</Form.Label>
                                                        <Form.Select  name='gender' id='gender'>
                                                            <option>Select gender</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col sm={6} xl={4} xxl={3}>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Benefits for Specific Days</Form.Label>
                                                        <Form.Select  name='benefitsforSpecificDays' id='benefitsforSpecificDays'>
                                                            <option>No</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col sm={6} xl={4} xxl={3}>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Discount Type</Form.Label>
                                                        <Form.Select  name='discountType' id='discountType'>
                                                            <option>%Off</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col sm={6} xl={4} xxl={3}>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Discount Value</Form.Label>
                                                        <Form.Select  name='discountValue' id='discountValue'>
                                                            <option>0</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>

                                                <Col md={12}>
                                                    <div className="btn-list text-end">
                                                        <Button variant="primary" type="submit">Save</Button>{' '}
                                                        <Button variant="secondary" type="button">Cancel</Button>{' '}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                                <FreeService />
                            </div>
                            <div className="tab-pane fade" id="pills-free-service" role="tabpanel" aria-labelledby="pills-free-service-tab" tabIndex="0">
                                <FreeService />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
