import React, { useState, useEffect, useContext, useRef } from 'react'
import { Card, Button, Table, Modal, Form, Row, Col, Dropdown, DropdownButton, InputGroup } from 'react-bootstrap';
import { productContext } from './productContext';
import { getFromAPI, deleteFromAPI, showAlert, putToAPI, postToAPI } from '../../Utils/utils';
import { toast, ToastContainer } from "react-toastify";
import Icon from '@mdi/react';
import { mdiStore, mdiBarcodeScan, mdiTrashCanOutline, mdiSquareEditOutline, mdiPlus, mdiChevronDown } from '@mdi/js';
import Barcode from 'react-barcode'; // Import the Barcode component
import JsBarcode from 'jsbarcode';
import { fetchTaxData, fetchProductCategoryData, fetchUnitData, fetchBrandData, fetchBranchData, fetchVendorData } from '../../Utils/function_list';
import { useProduct, CustomerProvider } from './getProductData'; // Import the useCustomer hook

// table-data page
import ProductTableData from "./product-data.json";

export default function ViewProduct() {
    // product details data
    const [tableData, setTableData] = useState([]); // Initialize state with an empty array
    const [tableData1, setTableData1] = useState([]); // Initialize state with an empty array
    const selectedProductId = useContext(productContext);
    const { fetchproductData1 } = useProduct();
    const [showEditModal, setShowEditModal] = useState(false);
    const [barcodeModal, setBarcodeModal] = useState(false);
    const [Tax, setTax] = useState([]);
    const [productData, setProductData] = useState([]);
    const [Brand, setBrand] = useState([]);
    const [Unit, setUnit] = useState([]);
    const [productCategory, setProductCategory] = useState([]);
    const [Branch, setBranch] = useState([]);
    const [vendor, setVendorData] = useState([]);
    const barcodeRef = useRef(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Todo: set branch product data
    const [branchProductInputdata, setBranchProductInputdata] = useState();

    // Todo: set warehous product data 
    const [warehouseBranchProductInputdata, setWarehouseBranchProductInputdata] = useState();

    const [inputData, setInputData] = useState({
        description: '',
        type: '',
        product_code: '',
        hsn_code: '',
        brand_id: '',
        product_category_id: '',
        unit_id: '',
        vendor_id: '',
        maximum_retail_price: '',
        product_purchase_price: '',
        product_mu_percentage: '',
        long_description: '',
        isonline: '',
        created_branch_id: 0,
        created_user_id: 0,
        last_modified_user_id: 0,
        last_modified_date_time: new Date().toISOString(),
        isdeleted: 0,
    });
    const initialUnitData = {
        name: '',
    };
    const [unitInputData, setUnitInputData] = useState(initialUnitData);


    const fetchProductData = async () => {
        try {
            const response = await getFromAPI(`FtsItems/?id=${selectedProductId}`);
            // Fetch customer data
            // Map API response to match the table data structure
            const mappedData = [
                { productheading1: "Product code :", productsubtext1: response[0].product_code, productheading2: "Category :", productsubtext2: getProductCategoryNameById(response[0].product_category_id) },
                { productheading1: "Type :", productsubtext1: response[0].type, productheading2: "Unit :", productsubtext2: getUnitNameById(response[0].unit_id) },
                { productheading1: "Cost Price :", productsubtext1: response[0].product_purchase_price, productheading2: "Sell Price :", productsubtext2: response[0].selling_price },
                { productheading1: "Brand :", productsubtext1: response[0].brand_id, productheading2: "MU :", productsubtext2: response[0].product_mu_percentage },
                { productheading1: "vendor :", productsubtext1: getVendorNameById(response[0].vendor_id), productheading2: "Discount :", productsubtext2: response[0].discount },
                { productheading1: "HSN :", productsubtext1: response[0].hsn_code, productheading2: "Product MRP :", productsubtext2: response[0].maximum_retail_price },
                { productheading1: "Product Sold :", productsubtext1: '0', productheading2: "Product Used :", productsubtext2: '0' },

            ];

            const mappedData1 = [
                { retailQuantity: "Opening Qty (R) : ", retailQuantityData: response[0].opening_qty, consumableQuantity: "Opening Qty (C) :", consumableQuantityData: response[0].consumable },
                { retailQuantity: "On Floor (R) : ", retailQuantityData: "NA", consumableQuantity: "On Floor (C) :	", consumableQuantityData: "NA" },
                { retailQuantity: "Alert Qty (R) : ", retailQuantityData: response[0].retail_alert_quantity, consumableQuantity: "In Store (C) :", consumableQuantityData: "NA" },
                { retailQuantity: "Damage Qty (R) : ", retailQuantityData: "NA", consumableQuantity: "Alert Qty (C) :", consumableQuantityData: response[0].consumable_alert_quantity },
                { retailQuantity: "Transferred Qty (R) : ", retailQuantityData: "NA", consumableQuantity: "Damage Qty (C) :", consumableQuantityData: "NA" },
                { retailQuantity: "Total Gift Card Spending :", retailQuantityData: "NA", consumableQuantity: "Transferred Qty (C) :	", consumableQuantityData: "NA" },
                { retailQuantity: "Return Qty (R) :	", retailQuantityData: "NA", consumableQuantity: "Return Qty (C) :", consumableQuantityData: "NA" },

            ];

            setTableData(mappedData); // Update state with the mapped data
            setTableData1(mappedData1)

            setInputData(response[0]); // Assuming response[0] contains all necessary data

        } catch (error) {
            console.error('Error fetching customer data:', error);
        }
    };

    useEffect(() => {
        if (barcodeModal && barcodeRef.current && inputData.product_code) {
            // Generate Barcode when the modal is open
            JsBarcode(barcodeRef.current, inputData.product_code, {
                format: "CODE128",
                lineColor: "#000",
                width: 2,
                height: 50,
                displayValue: true
            });
        }
    }, [barcodeModal, inputData.product_code]);

    useEffect(() => {
        fetchProductData();
    }, [selectedProductId]);

    useEffect(() => {
        fetchTaxData(setTax);
        fetchBranchData(setBranch);
        fetchBrandData(setBrand);
        fetchProductCategoryData(setProductCategory);
        fetchUnitData(setUnit);
        fetchVendorData(setVendorData)
    }, []);

    const handleBarcodeClick = () => {
        setBarcodeModal(true);
    };

    const handleCloseModal = () => {
        setBarcodeModal(false);
        setShowEditModal(false);
    };

    const showMyStore = event => {
        document.getElementById("my-store").classList.remove('d-none');
        document.getElementById("view-product").classList.add('d-none');
    }

    // Handle Delete Product Entry
    const handleDelete = async (Id) => {
        try {
            showAlert('Are you sure you want to delete this Product?', 'confirm', (result) => {
                if (result) {
                    // User confirmed the deletion
                    handleDeleteProduct(Id);
                } else {
                    // User canceled the deletion
                    // You can handle this case if needed
                }
            });
        } catch (error) {
            console.error('Error deleting Product:', error);
            toast.error('Error deleting Product', 'error');
        }
    };

    const handleDeleteProduct = async (itemId) => {
        try {
            const response = await deleteFromAPI(`FtsItems/${itemId}/`);
            if (response.status) {
                toast.success('Product deleted successfully', 'success');
                // Optionally, redirect or update state to reflect deletion
                setTableData([]); // Clear table data or handle as needed
            }
        } catch (error) {
            console.error('Error deleting customer:', error);
            toast.error('Product Not Deleted', 'error');
        }

        fetchproductData1();
    };

    const getVendorNameById = (VendorID) => {
        let ven = vendor.find(v => v.id === VendorID);
        return ven ? ven.company_name : 'Unknown Vendor';
    };

    const getProductCategoryNameById = (prodCatID) => {
        let prodCat = productCategory.find(pc => pc.id === prodCatID);
        return prodCat ? prodCat.name : 'Unknown category';
    };

    const getUnitNameById = (UnitID) => {
        let unit = Unit.find(u => u.id === UnitID);
        return unit ? unit.name : 'Unknown Unit';
    };

    const handleEdit = () => {
        setShowEditModal(true);
    };

    const handleSaveChanges = async () => {

        try {
            await putToAPI(`FtsItems/${selectedProductId}/`, inputData);
            console.log('selected product id:',selectedProductId);
            toast.success('Product details updated successfully', 'success');
            setShowEditModal(false);
            fetchProductData();
            fetchproductData1();

        } catch (error) {
            console.error('Error updating Product details:', error);
            toast.error('Failed to update Product details', 'error');
        }

        // TODO: update branch related product data

        // TODO: update Warehouse data

    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setInputData({
            ...inputData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleAddUnit = async () => {
        try {
            //Post the data to the Api
            const response = await postToAPI("FtsUnit/", unitInputData)
            if (response.status) {
                toast.success('Unit Added Successfully')
                handleClose()
                fetchUnitData(setUnit);
                setUnitInputData(initialUnitData)
            }
            else {
                toast.error('Failed to add customer');
            }
        } catch (error) {
            console.error('Error adding customer:', error);
            console.log("Error Message:", error.message);
            toast.error('An unexpected error occurred');
        }
    };
    const handleCheckboxChange = (e) => {
        setInputData({ ...inputData, isonline: e.target.checked });
    };


    return (
        <>
            <Card className='view-product-content right-content-details' id='view-product'>
                <Card.Body>
                    <Card.Title>Product Details</Card.Title>
                    <div className='d-flex justify-content-between aling-items-center mb-3'>
                        <div className="btn-list">
                            <Button variant="light" onClick={showMyStore}><Icon path={mdiStore} className="btn-icon me-1" /> My Store</Button>{' '}
                            <Button variant="light" onClick={handleBarcodeClick}><Icon path={mdiBarcodeScan} className="btn-icon me-1" /> Barcode</Button>{' '}
                        </div>
                        <div className='d-flex'>
                            <button
                                className='btn btn-link'
                                style={{ marginRight: '0', padding: '0' }}
                                onClick={handleEdit}
                            >
                                <div className='avatar avatar-sm bg-soft-info text-info'>
                                    <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                </div>
                            </button>
                            <button
                                className='btn btn-link'
                                onClick={() => handleDelete(selectedProductId)} // Use a function reference
                            >
                                <div className='avatar avatar-sm bg-soft-danger text-danger'>
                                    <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                </div>
                            </button>
                        </div>
                    </div>

                    <Table responsive className='table-lg border table-nowrap'>
                        <tbody>
                            {tableData.map((data, i) =>
                                <tr key={i}>
                                    <th>{data.productheading1}</th>
                                    <td>{data.productsubtext1}</td>
                                    <th>{data.productheading2}</th>
                                    <td>{data.productsubtext2}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                    <Table responsive className='table-lg border table-nowrap'>
                        <thead className='table-light'>
                            <tr>
                                <th>Retail (R) Quantity</th>
                                <th></th>
                                <th>Consumable (C) Quantity</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData1.map((data, i) =>
                                <tr key={i} >
                                    <th>{data.retailQuantity}</th>
                                    <td>{data.retailQuantityData}</td>
                                    <th>{data.consumableQuantity}</th>
                                    <td>{data.consumableQuantityData}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <Modal show={barcodeModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Product Barcode</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <svg ref={barcodeRef}></svg>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Product Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='productName'
                                        id='productName'
                                        placeholder="Enter Product Name..."
                                        value={inputData.description}
                                        onChange={e => setInputData({ ...inputData, description: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Product Type</Form.Label>
                                    <Form.Select
                                        
                                        name='productType'
                                        id='productType'
                                        value={inputData.type}
                                        onChange={e => setInputData({ ...inputData, type: e.target.value })}
                                    >
                                        <option>Retail</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Product Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter code..."
                                        name='productCode'
                                        id='productCode'
                                        value={inputData.product_code}
                                        onChange={e => setInputData({ ...inputData, product_code: e.target.value })}

                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>HSN</Form.Label>
                                    <Form.Control type="text"
                                        placeholder="Enter HSN..."
                                        name='productHsn'
                                        id='productHsn'
                                        value={inputData.hsn_code}
                                        onChange={e => setInputData({ ...inputData, hsn_code: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Brand</Form.Label>
                                    <Form.Select
                                        
                                        name='productBrand'
                                        id='productBrand'
                                        value={inputData.brand_id}
                                        onChange={e => setInputData({ ...inputData, brand_id: e.target.value })}>
                                        <option value="">Select Brand</option>
                                        {Brand.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}

                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mb-3 d-flex">
                                    <div className='custom-form-input w-100'>
                                        <Form.Label>Product Category</Form.Label>
                                        <Form.Select
                                            
                                            name='productCategory'
                                            id='productCategory'
                                            value={inputData.product_category_id}
                                            onChange={e => setInputData({ ...inputData, product_category_id: e.target.value })}>
                                            <option value="">Select Product Category</option>
                                            {productCategory.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                    </div>

                                    <Button variant="light custom-form-input-btn ms-2 mt-2" onClick={handleShow}>
                                        <Icon path={mdiPlus} size={1} />
                                    </Button>
                                    <Modal centered show={show} onHide={handleClose}>
                                        <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                                        <Modal.Body>
                                            <div className="mb-3 custom-form-input">
                                                <Form.Label>Add Product Category</Form.Label>
                                                <Form.Control type="text" placeholder="Enter product category..." name='addProductCategory' id='addProductCategory' />
                                            </div>
                                            <div className='text-end'>
                                                <Button variant="primary" onClick={handleClose}>Add Category</Button>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mb-3 d-flex">
                                    <div className='custom-form-input w-100'>
                                        <Form.Label>Unit</Form.Label>
                                        <Form.Select
                                            
                                            name='productUnit'
                                            id='productUnit'
                                            value={inputData.unit_id}
                                            onChange={e => setInputData({ ...inputData, unit_id: e.target.value })}>
                                            <option value="">Select Unit</option>
                                            {Unit.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                    </div>

                                    <Button variant="light custom-form-input-btn ms-2 mt-2" onClick={handleShow}>
                                        <Icon path={mdiPlus} size={1} />
                                    </Button>
                                    <Modal centered show={show} onHide={handleClose}>
                                        <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                                        <Modal.Body>
                                            <div className="mb-3 custom-form-input">
                                                <Form.Label>Add Unit</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter  Unit..."
                                                    name='addUnit'
                                                    id='addUnit'
                                                    onChange={e => setUnitInputData({ ...unitInputData, name: e.target.value })}
                                                    value={unitInputData.name}
                                                />
                                            </div>
                                            <div className='text-end'>
                                                <Button variant="primary" onClick={handleAddUnit}>Add Unit</Button>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alert Qty (Retail)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Qty..."
                                        name='alertRetail'
                                        id='alertRetail'
                                        value=""
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alert Qty (Consumable)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Qty..."
                                        name='alertConsumable'
                                        id='alertConsumable'
                                        value=""
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Selling Price</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter price..."
                                        name='sellingPrice'
                                        id='sellingPrice'
                                        value={inputData.selling_price}
                                        onChange={e => setInputData({ ...inputData, selling_price: e.target.value })}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Card.Title style={{ margin: '10px' }}>Opening Quantity</Card.Title>
                        <Row>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Consumable</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter consumable..."
                                        name='productConsumable'
                                        id='productConsumable'
                                        value=""
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label> Opening Qty (Retail)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter qty..."
                                        name='openingRetail'
                                        id='openingRetail'
                                        value=""

                                    />
                                </div>
                            </Col>
                        </Row>

                        <Card.Title style={{ margin: '10px' }}>Other Details</Card.Title>
                        <Row>
                            <Col md={12}>
                                <div className="mb-3 d-flex">
                                    <div className='custom-form-input w-100'>
                                        <Form.Label>Vendors</Form.Label>
                                        <Form.Select
                                            
                                            name='productVendors'
                                            id='productVendors'
                                            value={inputData.vendor_id}
                                            onChange={e => setInputData({ ...inputData, vendor_id: e.target.value })}
                                        >
                                            <option value="">Select Vendor</option>
                                            {vendor.map(item => (
                                                <option key={item.id} value={item.id}>{item.company_name}</option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                    <Button variant="light custom-form-input-btn ms-2 mt-2">
                                        <Icon path={mdiPlus} size={1} />
                                    </Button>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>MRP</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter MRP..."
                                        name='productMrp'
                                        id='productMrp'
                                        value={inputData.maximum_retail_price}
                                        onChange={e => setInputData({ ...inputData, maximum_retail_price: e.target.value })}

                                    />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Cost</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter cost..."
                                        name='procuctCost' id='procuctCost'
                                        value={inputData.product_purchase_price}
                                        onChange={e => setInputData({ ...inputData, product_purchase_price: e.target.value })}

                                    />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>MU</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter MU..."
                                        name='productMu'
                                        id='productMu'
                                        value={inputData.product_mu_percentage}
                                        onChange={e => setInputData({ ...inputData, product_mu_percentage: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <InputGroup className="mb-3 custom-form-input">
                                    <Form.Control
                                        
                                        placeholder='Enter discount...'
                                        name='productdiscount'
                                        id='productdiscount'
                                        value={inputData.discount}
                                        onChange={e => setInputData({ ...inputData, discount: e.target.value })}
                                    />
                                    <Form.Label>Discount</Form.Label>
                                    <DropdownButton variant="light" title={
                                        <>
                                            % Off <Icon path={mdiChevronDown} className="drop-arrow ms-1 icon-size-15" />
                                        </>
                                    } id="input-group-dropdown-2" align="end">
                                        <Dropdown.Item href="#">10% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">20% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">30% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">40% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">50% Off</Dropdown.Item>
                                    </DropdownButton>
                                </InputGroup>
                            </Col>
                            <Col sm={6} md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Select
                                        
                                        name='productTax'
                                        id='productTax'
                                        value={inputData.tax}
                                        onChange={e => setInputData({ ...inputData, tax: e.target.value })}
                                    >
                                        <option value="">None</option>
                                        {Tax.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}

                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax type</Form.Label>
                                    <Form.Select
                                        
                                        name='otherDetails'
                                        id='otherDetails'
                                        value={inputData.tax_type}
                                        onChange={e => setInputData({ ...inputData, tax_type: e.target.value })}>
                                        <option>Inclusive</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label className='z-1'>Branch</Form.Label>
                                    <Form.Select
                                        name='productBranch'
                                        id='productBranch'
                                        value={inputData.branch}
                                        onChange={e => setInputData({ ...inputData, branch: e.target.value })}
                                        isMulti={true}>  // Assuming single selection; change to true if you want multiple selection
                                        <option value="">Select Branch</option>
                                        {Branch.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}

                                    </Form.Select>
                                </div>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Enter description..."
                                        name='productDescription'
                                        id='productDescription'
                                        value={inputData.long_description}
                                        onChange={e => setInputData({ ...inputData, long_description: e.target.value })}

                                    />
                                </div>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check
                                        type="checkbox"
                                        label="Is Online"
                                        name='productOnline'
                                        id='productOnline'
                                        checked={inputData.isonline}
                                        onChange={handleCheckboxChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSaveChanges}>Save Changes</Button>
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
