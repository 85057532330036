import React, { useState } from 'react';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
// react-select
import Select from 'react-select'
// icon
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import CreateInvoiceTableData from './CreateInvoiceTableData';
import AddCustomer from '../../../Utils/AddCustomer';

export default function CreateInvoice() {
    const [value, setValue] = useState(null)
    const options = [
        { value: "Test", label: "Test" },
        { value: "Umesh bhai", label: "Umesh bhai" },
        { value: "NSF Adajan", label: "NSF Adajan" },
        { value: "NFS Parle Point", label: "NFS Parle Point" },
        { value: "NFS Uniform", label: "NFS Uniform" },
    ];

    // Add Customer modal
    const [customerModalShow, setCustomerModalShow] = useState(false);
    const handleShowCustomer = () => setCustomerModalShow(true);
    const handleCloseCustomer = () => setCustomerModalShow(false);

    return (
        <>
            <Card>
                <Card.Body className='pb-0'>
                    <Form>
                        <Row>
                            <Col xl={4}>
                                <div className="mb-3 d-flex">
                                    <div className='custom-form-input w-100'>
                                        <Form.Label>Customer Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter customer name" name='customerName' id='customerName' />
                                    </div>
                                    <Button variant="light custom-form-input-btn ms-2 mt-2" onClick={handleShowCustomer}>
                                        <Icon path={mdiPlus} size={1} />
                                    </Button>
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control type="number" placeholder="(+001) 1234 5678 90" name='mobileNumber' id='mobileNumber' />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Invoice Date</Form.Label>
                                    <Form.Control type="date" placeholder="24 November, 2022" name='challanDate' id='challanDate' />
                                </div>
                            </Col>
                        </Row>
                    </Form>

                    <Row>
                        <Col md={6} xl={4}>
                            <h5 className='fs-16 mb-3'>GST No :</h5>
                        </Col>
                        <Col md={6} xl={4}>
                            <h5 className='fs-16 mb-3'>Advance Payment : <span className='fw-normal'>0₹</span></h5>
                        </Col>
                        <Col md={6} xl={4}>
                            <h5 className='fs-16 mb-3'>Membership : <span className='fw-normal'>0</span></h5>
                        </Col>
                        <Col md={6} xl={4}>
                            <h5 className='fs-16 mb-3'>Notes :</h5>
                        </Col>
                        <Col md={6} xl={4}>
                            <h5 className='fs-16 mb-3'>Wallet Balance : <span className='fw-normal'>0₹</span></h5>
                        </Col>
                        <Col md={6} xl={4}>
                            <h5 className='fs-16 mb-3'>Membership Credit Balance : <span className='fw-normal'>0₹</span></h5>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-view-vendor" role="tabpanel" aria-labelledby="pills-view-vendor-tab" tabIndex="0">
                            <ul className="nav nav-pills nav-fill btn-list mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="pills-service-tab" data-bs-toggle="pill" data-bs-target="#pills-service" type="button" role="tab" aria-controls="pills-service" aria-selected="true">Service</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-product-tab" data-bs-toggle="pill" data-bs-target="#pills-product" type="button" role="tab" aria-controls="pills-product" aria-selected="false">Product</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-membership-tab" data-bs-toggle="pill" data-bs-target="#pills-membership" type="button" role="tab" aria-controls="pills-membership" aria-selected="false">Membership</button>
                                </li>
                            </ul>

                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-service" role="tabpanel" aria-labelledby="pills-service-tab" tabIndex="0">
                                    <Form>
                                        <Row className='align-items-end'>
                                            <Col xl={11}>
                                                <Row>
                                                    <Col sm={6} xl={3}>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Service Category</Form.Label>
                                                            <Form.Select name='serviceCategory' id='serviceCategory'>
                                                                <option>All</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                            </Form.Select>
                                                        </div>
                                                    </Col>
                                                    <Col sm={6} xl={3}>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Services</Form.Label>
                                                            <Form.Select name='services' id='services'>
                                                                <option>Select Service</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                            </Form.Select>
                                                        </div>
                                                    </Col>
                                                    <Col md={6} xl={3}>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label className='z-1'>Provider</Form.Label>
                                                            <Select options={options} defaultValue={value} placeholder="Select Branch..." name='provider' id='provider' onChange={setValue} isMulti></Select>
                                                        </div>
                                                    </Col>
                                                    <Col md={6} xl={3}>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Campaign</Form.Label>
                                                            <Form.Select name='campaign' id='campaign'>
                                                                <option>Nothing selected</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                            </Form.Select>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xl={1} className='text-end'>
                                                <Button variant="primary btn-w-lg mb-3"><Icon path={mdiPlus} className="btn-icon me-1" /> Add</Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                    <CreateInvoiceTableData />
                                </div>
                                <div className="tab-pane fade" id="pills-product" role="tabpanel" aria-labelledby="pills-product-tab" tabIndex="0">
                                    <Form>
                                        <Row className='align-items-end'>
                                            <Col lg={11}>
                                                <Row sm={2} md={3} xxl={5} className='row-cols-1'>
                                                    <Col>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Product Category</Form.Label>
                                                            <Form.Select name='productCategory' id='productCategory'>
                                                                <option>All</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                            </Form.Select>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Product</Form.Label>
                                                            <Form.Control type="text" placeholder='Enter product...' name='product' id='product' />
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label className='z-1'>Provider</Form.Label>
                                                            <Select options={options} defaultValue={value} placeholder="Select Branch..." name='provider' id='provider' onChange={setValue} isMulti></Select>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Current Stock</Form.Label>
                                                            <Form.Control type="text" placeholder='0' name='currentStock' id='currentStock' />
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Quantity</Form.Label>
                                                            <Form.Control type="text" placeholder='1' name='quantity' id='quantity' />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={1} className='text-end'>
                                                <Button variant="primary btn-w-lg mb-3"><Icon path={mdiPlus} className="btn-icon me-1" /> Add</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <CreateInvoiceTableData />
                                </div>
                                <div className="tab-pane fade" id="pills-membership" role="tabpanel" aria-labelledby="pills-membership-tab" tabIndex="0">
                                    <Form>
                                        <Row className='align-items-end'>
                                            <Col lg={10} xl={11}>
                                                <Row>
                                                    <Col sm={6} xl={4}>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Memberships</Form.Label>
                                                            <Form.Select name='memberships' id='memberships'>
                                                                <option>Nothing Selected</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                            </Form.Select>
                                                        </div>
                                                    </Col>
                                                    <Col sm={6} xl={4}>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Start Date</Form.Label>
                                                            <Form.Control type="date" placeholder="24 November, 2022" name='startDate' id='startDate' />
                                                        </div>
                                                    </Col>
                                                    <Col xl={4}>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label className='z-1'>Provider</Form.Label>
                                                            <Select options={options} defaultValue={value} placeholder="Select Branch..." name='provider' id='provider' onChange={setValue} isMulti></Select>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={2} xl={1} className='text-end'>
                                                <Button variant="primary btn-w-lg mb-3"><Icon path={mdiPlus} className="btn-icon me-1" /> Add</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <CreateInvoiceTableData />
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>

            <Card>
                <Card.Body>
                    <Row>
                        <Col md={6}>
                            <Form.Group as={Row} className="mb-3 align-items-center">
                                <Form.Label className='py-0 mb-2 mb-sm-0' column sm={3} md={5} lg={4} xxl={3}>Sub Total</Form.Label>
                                <Col sm={9} md={7} lg={8} xxl={9}>
                                    <Form.Control type="text" name='subTotal' id='subTotal' size='sm' readOnly />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 align-items-center">
                                <Form.Label className='py-0 mb-2 mb-sm-0' column sm={3} md={5} lg={4} xxl={3}>Total Item Discount</Form.Label>
                                <Col sm={9} md={7} lg={8} xxl={9}>
                                    <Form.Control type="text" name='totalItemDiscount' id='totalItemDiscount' size='sm' readOnly />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 align-items-center">
                                <Form.Label className='py-0 mb-2 mb-sm-0' column sm={3} md={5} lg={4} xxl={3}>Discount</Form.Label>
                                <Col xs={8} sm={6} md={4} lg={5} xxl={7}>
                                    <Form.Control type="text" name='discount' id='discount' size='sm' readOnly />
                                </Col>
                                <Col xs={4} sm={3} md={3} lg={3} xxl={2}>
                                    <Form.Select size='sm' id='discountSelect'>
                                        <option>% Off</option>
                                        <option value="1">Rs. Off</option>
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 align-items-center">
                                <Form.Label className='py-0 mb-2 mb-sm-0' column sm={3} md={5} lg={4} xxl={3}>Campaign</Form.Label>
                                <Col sm={9} md={7} lg={8} xxl={9}>
                                    <Form.Select size='sm' id='campaignSelect'>
                                        <option>Nothing selected</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 align-items-center">
                                <Form.Label className='py-0 mb-2 mb-sm-0' column sm={3} md={5} lg={4} xxl={3}>Loyalty</Form.Label>
                                <Col sm={9} md={7} lg={8} xxl={9}>
                                    <Form.Select size='sm' id='LoyaltySelect'>
                                        <option>Nothing selected</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 align-items-center">
                                <Form.Label className='py-0 mb-2 mb-sm-0' column sm={3} md={5} lg={4} xxl={3}>Loyalty Amount</Form.Label>
                                <Col sm={9} md={7} lg={8} xxl={9}>
                                    <Form.Control type="text" name='loyaltyAmount' id='loyaltyAmount' size='sm' readOnly />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 align-items-center">
                                <Form.Label className='py-0 mb-2 mb-sm-0' column sm={3} md={5} lg={4} xxl={3}>Taxable Amount</Form.Label>
                                <Col sm={9} md={7} lg={8} xxl={9}>
                                    <Form.Control type="text" name='taxableAmount' id='taxableAmount' size='sm' readOnly />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 align-items-center">
                                <Form.Label className='py-0 mb-2 mb-sm-0' column sm={3} md={5} lg={4} xxl={3}>CGST</Form.Label>
                                <Col sm={9} md={7} lg={8} xxl={9}>
                                    <Form.Control type="text" name='cGst' id='cGst' size='sm' readOnly />
                                </Col>
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group as={Row} className="mb-3 align-items-center">
                                <Form.Label className='py-0 mb-2 mb-sm-0' column sm={3} md={5} lg={4} xxl={3}>SGST</Form.Label>
                                <Col sm={9} md={7} lg={8} xxl={9}>
                                    <Form.Control type="text" name='sGst' id='sGst' size='sm' readOnly />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 align-items-center">
                                <Form.Label className='py-0 mb-2 mb-sm-0' column sm={3} md={5} lg={4} xxl={3}>Total Tax Amount</Form.Label>
                                <Col sm={9} md={7} lg={8} xxl={9}>
                                    <Form.Control type="text" name='totalTaxAmount' id='totalTaxAmount' size='sm' readOnly />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 align-items-center">
                                <Form.Label className='py-0 mb-2 mb-sm-0' column sm={3} md={5} lg={4} xxl={3}>Advance Payment</Form.Label>
                                <Col sm={9} md={7} lg={8} xxl={9}>
                                    <Form.Control type="text" name='totalTaxAmount' id='totalTaxAmount' size='sm' readOnly />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 align-items-center">
                                <Form.Label className='py-0 mb-2 mb-sm-0' column sm={3} md={5} lg={4} xxl={3}>Advance Payment Amount</Form.Label>
                                <Col sm={9} md={7} lg={8} xxl={9}>
                                    <Form.Control type="text" name='totalTaxAmount' id='totalTaxAmount' size='sm' readOnly />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 align-items-center">
                                <Form.Label className='py-0 mb-2 mb-sm-0' column sm={3} md={5} lg={4} xxl={3}>Grand Total</Form.Label>
                                <Col sm={9} md={7} lg={8} xxl={9}>
                                    <Form.Control type="text" name='totalTaxAmount' id='totalTaxAmount' size='sm' readOnly />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 align-items-center">
                                <Form.Label className='py-0 mb-2 mb-sm-0' column sm={3} md={5} lg={4} xxl={3}>Payment Type</Form.Label>
                                <Col sm={9} md={7} lg={8} xxl={9}>
                                    <Form.Select size='sm' id='campaignSelect'>
                                        <option>Cash</option>
                                        <option value="1">Bank</option>
                                        <option value="2">Online</option>
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 align-items-center">
                                <Form.Label className='py-0 mb-2 mb-sm-0' column sm={3} md={5} lg={4} xxl={3}>Amount Received</Form.Label>
                                <Col sm={9} md={7} lg={8} xxl={9}>
                                    <Form.Control type="text" name='grandTotal' id='grandTotal' size='sm' readOnly />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 align-items-center">
                                <Form.Label className='py-0 mb-2 mb-sm-0' column sm={3} md={5} lg={4} xxl={3}>Amount Due</Form.Label>
                                <Col sm={9} md={7} lg={8} xxl={9}>
                                    <Form.Control type="text" name='grandTotal' id='grandTotal' size='sm' readOnly />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 align-items-center">
                                <Form.Label className='py-0 mb-2 mb-sm-0' column sm={3} md={5} lg={4} xxl={3}>Amount Repaid</Form.Label>
                                <Col sm={9} md={7} lg={8} xxl={9}>
                                    <Form.Control type="text" name='grandTotal' id='grandTotal' size='sm' readOnly />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='align-items-end'>
                        <Col xl={5} xxl={6}>
                            <div className="mb-3 mb-xl-0 custom-form-input custom-form-input-sm">
                                <Form.Label>Narration</Form.Label>
                                <Form.Control type="text" name='narration' id='narration' placeholder='Enter Narration' />
                            </div>
                        </Col>
                        <Col xl={7} xxl={6} className='text-end'>
                            <div className="btn-list">
                                <Button variant="primary">Generate and Print</Button>{' '}
                                <Button variant="success">Generate and New</Button>{' '}
                                <Button variant="info">Generate Invoice</Button>{' '}
                                <Button variant="warning">Print Option</Button>{' '}
                                <Button variant="secondary">Cancel</Button>{' '}
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {/* Add Customer modal */}
            <AddCustomer addCustomerShow={customerModalShow} addCustomerClose={handleCloseCustomer} />
        </>
    )
}
