import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row, Button, Table } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// Customers table data
import CustomersTableData from "./marketing-customer-table-data.json";

export default function SendSms() {
    // datatable
    const [customersData] = useState(CustomersTableData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableMSend')) {
            $('#mainDatatableMSend').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, []);
    return (
        <>
            <Card.Title>Send SMS</Card.Title>
            <Card className='border shadow-none'>
                <Card.Body>
                    <Form>
                        <div className='text-end'>
                            <div className="mb-3 min-w custom-form-input custom-form-input-sm">
                                <Form.Label>Select Template</Form.Label>
                                <Form.Select >
                                    <option>Select</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </Form.Select>
                            </div>
                        </div>
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Compose SMS</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Enter compose sms..." name='composeSms' id='composeSms' />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Preview SMS</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Enter preview sms..." name='previewSms' id='previewSms' />
                                </div>
                            </Col>
                        </Row>
                        <div className="btn-list text-end">
                            <Button variant="light">Send All</Button>
                            <Button variant="light">Send Selected</Button>
                            <Button variant="light">Reset</Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
            <Card className='border shadow-none'>
                <Card.Body>
                    <div className="d-sm-flex justify-content-between align-items-center mb-3">
                        <Card.Title>Customers</Card.Title>
                        <div>
                            <Form className='d-sm-flex align-items-center '>
                                <Form.Select  size="sm" className='me-sm-3 mb-sm-0 mb-3'>
                                    <option>Year to date(YTD)</option>
                                    <option value="1">All Time</option>
                                    <option value="2">Two</option>
                                </Form.Select>
                                <Form.Select  size="sm">
                                    <option>All</option>
                                    <option value="1">Select One</option>
                                    <option value="2">Two</option>
                                </Form.Select>
                            </Form>
                        </div>
                    </div>

                    <Table responsive bordered className='table-nowrap' id='mainDatatableMSend'>
                        <thead className='table-light'>
                            <tr>
                                <th>
                                    <Form>
                                        {['checkbox'].map((type) => (
                                            <div key={`default-${type}`}>
                                                <Form.Check
                                                    type={type}
                                                    id={`default-Unchecked-${type}`}
                                                />
                                            </div>
                                        ))}
                                    </Form>
                                </th>
                                <th>S.no.</th>
                                <th>Contact</th>
                                <th>Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customersData.map((data, i) =>
                                <tr key={i}>
                                    <td>
                                        <Form>
                                            {['checkbox'].map((type) => (
                                                <div key={`default-${type}`}>
                                                    <Form.Check
                                                        type={type}
                                                        id={`default-Unchecked-${type}`}
                                                    />
                                                </div>
                                            ))}
                                        </Form>
                                    </td>
                                    <td>{data.sNo}</td>
                                    <td>{data.contact}</td>
                                    <td>{data.name}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    )
}
